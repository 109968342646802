import { Box, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import YourProfile from "./YourProfile";

const text = `Your Esteemed profile is an important part.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation`;

const CreateProfileTab = () => {
  const { isShowYourProfile, setIsShowYourProfile } = useCreateAccount();
  return (
    <>
      <Stack gap={"14px"} px={"10px"}>
        {isShowYourProfile ? (
          <YourProfile />
        ) : (
          <>
            <AtsTitleText
              text={"It's time to create your profile"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <Stack gap={"18px"}>
              <AtsTitleText
                text={text}
                fs={14}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
              <AtsRoundedButton
                text="Create profile"
                buttonVariant="outline"
                buttonWidth={127}
                buttonHeight={30}
                onClick={() => setIsShowYourProfile(true)}
              />
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default CreateProfileTab;
