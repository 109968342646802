import React, { useEffect, useMemo, useState } from "react";

import { Skeleton, Stack } from "@mui/material";

import { Box } from "@mui/material";
import AtsCheckbox from "../../../../../../../components/AtsCheckbox";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsIcon from "../../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import { getContractorDetailsState } from "../../../../../../../redux/slices/contractorSlice";
import { getContractorTimesheetState } from "../../../../../../../redux/slices/contractorTimesheetSlice";
import { useAppSelector } from "../../../../../../../redux/store";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import { TimelogEntriesResponse } from "../../../../../../../types/timesheetTypes";
import { formattedAmount } from "../../../../../../../utils/common";
import { useParams } from "react-router-dom";
import { ArrowDown2 } from "iconsax-react";
import { convertTimestampToDate } from "../../../../../../../utils/dateUtils";
import TimelogEntriesList from "./TimelogEntriesList";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "id",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
  {
    key: "date",
    header: "Date",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "title",
    header: "Task",
    width: "auto",
    minWidth: "150px",
  },

  {
    key: "startEndTime",
    header: "Start time - end time",
    width: "auto",
    minWidth: "240px",
  },
  {
    key: "working_hours",
    header: "Time",
    width: "90px",
    minWidth: "90px",
  },
];

const cellStyles = {
  padding: "9px 15px",
};
const firstCellStyles = {
  padding: "9px 0px 9px 10px",
};

const ContractorTimesheetList = () => {
  const { id } = useParams();
  const { isApprovedAll } = useAppSelector(getContractorTimesheetState);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [collapsedRows, setCollapsedRows] = useState<string>("");

  const {
    getTimeEntriesOfContractor,
    contractorTimeEntries,
    selectedTimelogOfContractor,
    selectedContractorTimesheet,
    setSelectedTimelogOfContractor,
    totalWorkingHours,
    selectedTimelogOfContractorDetails,
    setSelectedTimelogOfContractorDetails,
    timeEntryLoading,
    date,
  } = useTimesheet();

  useEffect(() => {
    selectedContractorTimesheet &&
      selectedContractorTimesheet.email &&
      getTimeEntriesOfContractor(selectedContractorTimesheet.email, id);
  }, [selectedContractorTimesheet, date]);

  const filteredTimelogData = useMemo(() => {
    return contractorTimeEntries
      .filter((log) => !log.approved_time.length)
      .filter((timesheet) => !timesheet.disputed_time.length)
      .map((log) => log.nid);
  }, [contractorTimeEntries]);

  useEffect(() => {
    setSelectedTimelogOfContractor([]);
  }, []);

  useEffect(() => {
    if (selectedTimelogOfContractor.length && filteredTimelogData.length) {
      setSelectAll(
        selectedTimelogOfContractor.length === filteredTimelogData.length
      );
    }
  }, [selectedTimelogOfContractor, filteredTimelogData]);

  const handleUserSelect = (
    nid: string,
    timesheetLog: TimelogEntriesResponse
  ) => {
    if (selectedTimelogOfContractor.includes(nid)) {
      const filterContractors = selectedTimelogOfContractor.filter(
        (nidId) => nidId !== nid
      );
      setSelectedTimelogOfContractor(filterContractors);
      const filterContractorsDetails =
        selectedTimelogOfContractorDetails.filter((data) => data.nid !== nid);
      setSelectedTimelogOfContractorDetails(filterContractorsDetails);
    } else {
      setSelectedTimelogOfContractor([...selectedTimelogOfContractor, nid]);
      setSelectedTimelogOfContractorDetails([
        ...selectedTimelogOfContractorDetails,
        timesheetLog,
      ]);
    }
  };

  const handleSelectAllUser = () => {
    const filterData = contractorTimeEntries
      .filter((log) => !log.approved_time.length)
      .filter((timesheet) => !timesheet.disputed_time.length);
    const filterNotDisputedData = filterData.map((log) => log.nid);
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    if (newSelectAll) {
      setSelectedTimelogOfContractorDetails(filterData);
      setSelectedTimelogOfContractor(filterNotDisputedData);
    } else {
      setSelectedTimelogOfContractor([]);
      setSelectedTimelogOfContractorDetails([]);
    }
  };
  const getTimeFormat = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows === rowId) {
      setCollapsedRows("");
    } else {
      setCollapsedRows(rowId);
    }
  };

  const customizeRow = (
    column: IColumnProps,
    timelog: TimelogEntriesResponse
  ) => {
    switch (column.key) {
      case "id":
        return (
          <td style={firstCellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {timelog.approved_time?.length &&
              Number(timelog.approved_time) > 0 ? (
                ""
              ) : timelog.disputed_time?.length &&
                Number(timelog.disputed_time) > 0 ? (
                <AtsIcon
                  icon="icon-info-circle"
                  height="22px"
                  fs={22}
                  color="#E63030"
                />
              ) : (
                <AtsCheckbox
                  checked={selectedTimelogOfContractor.includes(timelog.nid)}
                  onChange={() => handleUserSelect(timelog.nid, timelog)}
                  p={0}
                />
              )}
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggleCollapse(timelog.nid);
                }}
              >
                <ArrowDown2 />
              </Box>
            </Stack>
          </td>
        );
      case "date":
        const date = new Date(Number(timelog.start_time) * 1000);
        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={formattedDate}
              />
            </Stack>
          </td>
        );
      case "title":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={timelog[column.key]}
              />
            </Stack>
          </td>
        );
      case "startEndTime":
        const start = getTimeFormat(
          new Date(Number(timelog.start_time) * 1000)
        );
        const end = getTimeFormat(new Date(Number(timelog.end_time) * 1000));
        const startDate = convertTimestampToDate(timelog.start_time);
        const endDate = convertTimestampToDate(timelog.end_time);
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={`${startDate} ${start} to ${endDate} ${end}`}
              />
            </Stack>
          </td>
        );

      case "working_hours":
        const hours = `${timelog?.working_hours} hrs`;
        const disputedHours =
          // !timelog?.approved_time?.length &&
          // Number(timelog.approved_time) > 0 &&
          timelog.disputed_time?.length && Number(timelog.disputed_time) > 0
            ? `Disputed ${timelog?.disputed_time} hrs`
            : "";
        const ApprovedHours =
          timelog?.approved_time?.length && Number(timelog.approved_time) > 0
            ? `Approved ${timelog?.approved_time} hrs`
            : "";

        return (
          <td
            style={{
              ...cellStyles,
              backgroundColor:
                timelog?.approved_time?.length &&
                Number(timelog.approved_time) > 0
                  ? "#e1ffe8"
                  : timelog.disputed_time?.length &&
                    Number(timelog.disputed_time) > 0
                  ? "#fff3f3"
                  : selectedTimelogOfContractor.includes(timelog.nid)
                  ? BG_COLORS.PRIMARY
                  : "#f0f5ff",
            }}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={600}
                textColor={COLORS.BLACK}
                text={hours}
              />
            </Stack>
            {!ApprovedHours.length && disputedHours?.length > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  right: 10,
                }}
              >
                <AtsTitleText
                  fs={11}
                  fw={600}
                  textColor={"#e63030"}
                  text={disputedHours}
                />
              </Box>
            )}
            {ApprovedHours?.length > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  right: 10,
                }}
              >
                <AtsTitleText
                  fs={11}
                  fw={600}
                  textColor={COLORS.GREEN}
                  text={ApprovedHours}
                />
              </Box>
            )}
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = contractorTimeEntries.map(
      (value: TimelogEntriesResponse, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                minHeight: "44px",
                height: 67,
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
                cursor:
                  value.disputed_time?.length || value?.approved_time?.length
                    ? "default"
                    : "pointer",
                position: "relative",
                backgroundColor: value?.approved_time?.length
                  ? "transparent"
                  : value.disputed_time?.length
                  ? "#fff3f3"
                  : selectedTimelogOfContractor.includes(value.nid)
                  ? BG_COLORS.PRIMARY
                  : "transparent",
              }}
              onClick={
                value.disputed_time?.length || value?.approved_time?.length
                  ? () => {}
                  : () => handleUserSelect(value.nid, value)
              }
            >
              {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
            </tr>
            <tr>
              <td
                colSpan={12}
                style={{
                  marginBottom: 0,
                  padding: 0,
                }}
              >
                {collapsedRows === value.nid && (
                  <TimelogEntriesList timelogs={value.time_logs} />
                )}
              </td>
            </tr>
          </React.Fragment>
        );
      }
    );
    return (
      <>
        {leadsData}
        <tr>
          <td
            colSpan={9}
            style={{
              height: "53px",
              backgroundColor: "#f0f5ff",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Stack
                sx={{
                  textAlign: "end",
                  flexGrow: 1,
                  pr: "10px",
                }}
              >
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor={COLORS.BLACK}
                  text={"Total"}
                />
              </Stack>
              <Box
                sx={{
                  ...cellStyles,
                  width: "90px",
                }}
              >
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor={COLORS.BLACK}
                  text={`${formattedAmount(totalWorkingHours)} hrs`}
                />
              </Box>
            </Stack>
          </td>
        </tr>
      </>
    );
  };

  const headerComponent = () => {
    const disputedTimesheet = contractorTimeEntries
      .filter((log) => !log.approved_time.length)
      .filter((timesheet) => !timesheet.disputed_time.length).length;

    const isIndeterminate =
      selectedTimelogOfContractor.length > 0 &&
      selectedTimelogOfContractor.length < disputedTimesheet;

    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: index === 0 ? "60px" : column.width,
            minWidth: index === 0 ? "60px" : column.minWidth,
            padding: index === 0 ? "15px 0px 15px 10px" : "18px 15px",
            textAlign: "start",
            backgroundColor:
              column.header === "Time"
                ? isApprovedAll
                  ? "#e1ffe8"
                  : "#f0f5ff"
                : "transparent",
          }}
        >
          {index === 0 && !isApprovedAll ? (
            <AtsCheckbox
              checked={selectAll}
              indeterminate={isIndeterminate}
              onChange={handleSelectAllUser}
              p={0}
              disabled={
                !selectedTimelogOfContractor.length &&
                !filteredTimelogData.length
              }
            />
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText
                text={column.header}
                fs={12}
                fw={600}
                textColor="#000"
                isCursor
              />
            </Stack>
          )}
        </th>
      </>
    ));
    return headers;
  };

  return (
    <Box
      sx={{
        width: "100%",
        // overflowX: "auto",
      }}
    >
      {timeEntryLoading ? (
        <Stack gap={"5px"}>
          {Array.from(new Array(5)).map((_, index) => (
            <Skeleton variant="rounded" height={40} width={"100%"} />
          ))}
        </Stack>
      ) : (
        <AtsDataTable
          isNotStickyHeader
          data={contractorTimeEntries}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={timeEntryLoading}
        />
      )}
    </Box>
  );
};

export default ContractorTimesheetList;
