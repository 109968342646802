import { createContext, useContext, useEffect, useState } from "react";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
} from "../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Views } from "react-big-calendar";
import moment from "moment";
import { CalendarEventTypes, DayAvailability } from "../types/calendarTypes";
import { CalendarServices } from "../Api/calendarServices";

export type Keys = keyof typeof Views;

export const initialAvailability = [
  {
    day: "Monday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Tuesday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Wednesday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Thursday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Friday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Saturday",
    is_unavailable: 1,
    slots: [],
  },
  {
    day: "Sunday",
    is_unavailable: 1,
    slots: [],
  },
];

type CalendarAvailabilityModuleContextType = {
  isAvailability: boolean;
  loading: boolean;
  selectedTimezone: string;
  availability: DayAvailability[];
  calendarView: (typeof Views)[Keys];
  date: Date;
  eventType: keyof typeof CalendarEventTypes;
  setCalendarView: React.Dispatch<React.SetStateAction<(typeof Views)[Keys]>>;
  setAvailability: React.Dispatch<React.SetStateAction<DayAvailability[]>>;
  setIsAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  setEventType: React.Dispatch<
    React.SetStateAction<keyof typeof CalendarEventTypes>
  >;
  openCloseDisputedResolutionDrawer: () => void;
};

const CalendarAvailabilityModule =
  createContext<CalendarAvailabilityModuleContextType | null>(null);

export const useCalendarAvailability = () =>
  useContext(
    CalendarAvailabilityModule
  ) as CalendarAvailabilityModuleContextType;

export const CalendarAvailabilityProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  const [isAvailability, setIsAvailability] = useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(moment(new Date()).toDate());
  const [eventType, setEventType] = useState<keyof typeof CalendarEventTypes>(
    CalendarEventTypes.All
  );
  const [calendarView, setCalendarView] = useState<(typeof Views)[Keys]>(
    Views.WEEK
  );
  const [availability, setAvailability] = useState<DayAvailability[]>([]);

  const openCloseDisputedResolutionDrawer = () => {
    dispatch(setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen));
    setIsAvailability(!isAvailability);
  };

  return (
    <CalendarAvailabilityModule.Provider
      value={{
        isAvailability,
        loading,
        calendarView,
        date,
        eventType,
        availability,
        selectedTimezone,
        setAvailability,
        setSelectedTimezone,
        setIsAvailability,
        setCalendarView,
        setDate,
        setEventType,
        openCloseDisputedResolutionDrawer,
      }}
    >
      {children}
    </CalendarAvailabilityModule.Provider>
  );
};
