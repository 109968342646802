import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectService } from "../../../../../../../../../Api/projectServices";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { ProjectListProps } from "../../../../../../../../../types/projectTypes";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import { useParams } from "react-router-dom";

const JobDetailsForMakeAnOffer = () => {
  const { id } = useParams();
  const { jobDetailsForSubmission, candidateDetails } =
    useAppSelector(getSubmissionState);
  const { formattedCandidateDetails } = useMakeAnOffer();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<ProjectListProps | null>(
    null
  );

  const job_title = isNotNullOrUndefined(jobDetailsForSubmission)
    ? jobDetailsForSubmission.title
    : "";
  const job_category = "Technology";
  const education = isNotNullOrUndefined(candidateDetails.education)
    ? candidateDetails.education
    : [];
  const required_skills = isNotNullOrUndefined(
    jobDetailsForSubmission.required_skills
  )
    ? jobDetailsForSubmission.required_skills
    : [];
  const soft_skills = isNotNullOrUndefined(jobDetailsForSubmission.soft_skills)
    ? jobDetailsForSubmission.soft_skills
    : [];
  const published_description = isNotNullOrUndefined(
    jobDetailsForSubmission.published_description
  )
    ? jobDetailsForSubmission.published_description
    : "";
  const years_experience = isNotNullOrUndefined(
    jobDetailsForSubmission.years_experience
  )
    ? jobDetailsForSubmission.years_experience
    : "";
  const work_mode = isNotNullOrUndefined(jobDetailsForSubmission.work_mode)
    ? jobDetailsForSubmission.work_mode
    : "";

  const getProjectByProjectId = async (project_id: string) => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectDetails({
        nid: project_id,
      });
      setProjectDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProjectByProjectId(id);
    } else {
      if (jobDetailsForSubmission) {
        if (isNotNullOrUndefined(jobDetailsForSubmission.project_id)) {
          jobDetailsForSubmission.project_id.length &&
            getProjectByProjectId(jobDetailsForSubmission.project_id);
        }
      }
    }
  }, [jobDetailsForSubmission, id]);

  const projectTitle = isNotNullOrUndefined(projectDetails)
    ? projectDetails?.title
    : "--";
  const projectDescription = isNotNullOrUndefined(projectDetails)
    ? projectDetails?.description
    : "--";

  return (
    <Box>
      <Stack
        sx={{
          pb: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>{textWrapper("Job title", job_title)}</>
      </Stack>
      <Stack
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>{textWrapper("Job category", job_category)}</>
      </Stack>
      <Stack
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack gap={"4px"}>
          <AtsTitleText
            text={"Description"}
            fs={14}
            fw={700}
            textColor="#000"
          />
          <div
            className="jobDetails"
            dangerouslySetInnerHTML={{ __html: published_description }}
          ></div>
        </Stack>
      </Stack>
      <Stack
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack gap={"2px"}>
          <AtsTitleText
            text={"Education and Experience required"}
            fs={14}
            fw={700}
            textColor="#000"
          />
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: "#000",
            }}
          >
            {education.map((item, index) => (
              <span key={index}>
                {isNotNullOrUndefined(item.field_of_study)
                  ? item.field_of_study
                  : ""}
              </span>
            ))}{" "}
            ,{years_experience}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>{chipWrapper("Skills required", required_skills)}</>
      </Stack>
      <Stack
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>{chipWrapper("Additional skills", soft_skills)}</>
      </Stack>
      <Stack
        sx={{
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <>
          {textWrapper(
            "Work mode / Location",
            `${work_mode}, ${formattedCandidateDetails.address}`
          )}
        </>
      </Stack>
      <Stack
        sx={{
          py: "20px",
        }}
        gap={"15px"}
      >
        <AtsTitleText text={"Project"} fs={18} fw={600} textColor="#000" />
        {loading ? (
          <Skeleton height={20} width={100} variant="rectangular" />
        ) : (
          textWrapper("Project title", projectTitle)
        )}
        {loading ? (
          <Skeleton height={20} width={100} variant="rectangular" />
        ) : (
          textWrapper("Project description", projectDescription)
        )}
      </Stack>
    </Box>
  );
};

export default JobDetailsForMakeAnOffer;

const textWrapper = (title: string, value: string) => {
  return (
    <Stack gap={"2px"}>
      <AtsTitleText text={title} fs={14} fw={700} textColor="#000" />
      <AtsTitleText text={value} fs={14} fw={500} textColor="#000" />
    </Stack>
  );
};
const chipWrapper = (title: string, value: string[]) => {
  return (
    <Stack gap={"5px"}>
      <AtsTitleText text={title} fs={14} fw={700} textColor="#000" />
      <AtsRow gap={"5px"} flexWrap={"wrap"}>
        <>
          {value.map((item) => (
            <AtsRow
              key={item}
              sx={{
                border: BORDERS.GRAY,
                borderRadius: "20px",
                px: "10px",
                py: "4px",
              }}
            >
              <AtsTitleText
                text={item}
                fs={10}
                fw={600}
                textColor={COLORS.LIGHT_GRAY}
              />
            </AtsRow>
          ))}
        </>
      </AtsRow>
    </Stack>
  );
};
