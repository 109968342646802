import { ReactNode } from "react";
import {
  AdminCenterIcon,
  AvailabilityIcon,
  BillingIcon,
  BookmarkIcon,
  CalenderIcon,
  CommunityIcon,
  HelpIcon,
  HomeIcon,
  InsightsIcon,
  JobsIcon,
  MessagingIcon,
  MyTeamIcon,
  ProjectsIcon,
  UserIcon,
  UserManagementIcon,
} from "../assets/icons/Icons";
import Dashboard from "../pages/Dashboard/Dashboard";
import { ATS_ROUTES } from "./routes";
import Jobs from "../pages/Jobs/Jobs";
import Projects from "../pages/Projects/Projects";
import Calendar from "../pages/Calendar/Calendar";
import Messaging from "../pages/Messaging/Messaging";
import Insights from "../pages/Insights/Insights";
import Availability from "../pages/Availability/Availability";
import Billing from "../pages/Billing/Billing";
import AdminCenter from "../pages/AdminCenter/AdminCenter";
import Help from "../pages/Help/Help";
import Submissions from "../pages/Jobs/Submissions";
import PostOpportunity from "../pages/PostOpportunity/PostOpportunity";
import UserManagement from "../pages/UserManagement/UserManagement";
import UserRoles from "../pages/UserRoles/UserRoles";
import AtsIcon from "../components/AtsIcon";
import { Outlet } from "react-router-dom";
import NoAccess from "../pages/NoAccess";
import CreateNewProject from "../pages/Projects/CreateNewProject";
import ProjectDetails from "../pages/Projects/ProjectDetails";
import { CalendarAvailabilityProvider } from "../providers/CalendarAvailabilityProvider";
import { MessagesProvider } from "../providers/MessagesProvider";
import { CallingProvider } from "../providers/CallingProvider";
import SetAvailability from "../pages/Calendar/modules/SetAvailability";
import { ProjectFilterProvider } from "../providers/ProjectFilterProvider";
import { DocumentText } from "iconsax-react";
import Contracts from "../pages/Contracts/Contracts";
import { BillingProvider } from "../providers/BillingProvider";
import { ContractorProvider } from "../providers/ContractorProvider";
import { ContractsProvider } from "../providers/ContractsProvider";
import EsignContractDetails from "../pages/Contracts/EsignContractDetails";
import { MyProfileProvider } from "../providers/MyProfileProvider";
import MyProfile from "../pages/MyProfile/MyProfile";
import { NotificationsProvider } from "../providers/NotificationsProvider";
import Notifications from "../pages/Notifications/Notifications";
import { InvoicesProvider } from "../providers/InvoicesProvider";
import { InsightsProvider } from "../providers/InsightsProvider";

export interface RoutesProps {
  path: string;
  label: string;
  component: JSX.Element;
  icon: ReactNode;
  activIcon: ReactNode;
  isHide?: boolean;
  isMatch?: string[];
  access?: string[];
  children?: RoutesProps[];
}

export const ROUTES: RoutesProps[] = [
  {
    path: ATS_ROUTES.DASHBOARD,
    label: "Dashboard",
    component: <Dashboard />,
    icon: <HomeIcon />,
    activIcon: <HomeIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.JOBS,
    label: "Jobs",
    component: <Jobs />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.SUBMISSIONS,
    label: "Jobs",
    component: <Submissions />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.PROJECT_DETAILS,
    label: "",
    component: <ProjectDetails />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.POST_OPPORTUNITY,
    label: "",
    component: <PostOpportunity />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.CREATE_PROJECT,
    label: "",
    component: <CreateNewProject />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.NO_ACCESS,
    label: "",
    component: <NoAccess />,
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
  },
  {
    path: ATS_ROUTES.PROJECTS,
    label: "Projects",
    component: (
      <ProjectFilterProvider>
        <Projects />
      </ProjectFilterProvider>
    ),
    icon: <ProjectsIcon />,
    activIcon: <ProjectsIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.CALENDAR,
    label: "Calendar",
    component: (
      <CalendarAvailabilityProvider>
        <Calendar />
      </CalendarAvailabilityProvider>
    ),
    icon: <CalenderIcon />,
    activIcon: <CalenderIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.MESSAGING,
    label: "Messaging",
    component: (
      <CallingProvider>
        <MessagesProvider>
          <Messaging />
        </MessagesProvider>
      </CallingProvider>
    ),
    icon: <MessagingIcon />,
    activIcon: <MessagingIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.INSIGHTS,
    label: "Insights",
    component: (
      <InsightsProvider>
        <Insights />
      </InsightsProvider>
    ),
    icon: <InsightsIcon />,
    activIcon: <InsightsIcon strokeColor="#36C" />,
  },
];
export const SUB_ROUTES: RoutesProps[] = [
  {
    path: ATS_ROUTES.AVAILABILITY,
    label: "Availability",
    component: (
      <CalendarAvailabilityProvider>
        <SetAvailability />
      </CalendarAvailabilityProvider>
    ),
    icon: <AvailabilityIcon />,
    activIcon: <AvailabilityIcon strokeColor="#36C" />,
    access: ["Admin", "Team Member"],
  },

  {
    path: "",
    label: "Admin Center",
    component: <Outlet />,
    icon: <AdminCenterIcon />,
    access: ["Admin"],
    activIcon: <AdminCenterIcon strokeColor="#36C" />,
    isMatch: [
      ATS_ROUTES.USER_MANAGEMENT,
      ATS_ROUTES.USER_ROLES,
      ATS_ROUTES.CONTRACTS,
      ATS_ROUTES.BILLING,
    ],
    children: [
      {
        path: ATS_ROUTES.USER_MANAGEMENT,
        label: "User Management",
        component: <UserManagement />,
        icon: <AtsIcon icon="icon-people" fw={500} fs={18} color="#000" />,
        activIcon: <AtsIcon icon="icon-people" color="#36C" fw={500} fs={18} />,
      },
      {
        path: ATS_ROUTES.USER_ROLES,
        label: "User Roles",
        component: <UserRoles />,
        icon: <AtsIcon icon="icon-data" fw={500} fs={18} color="#000" />,
        activIcon: <AtsIcon icon="icon-data" color="#36C" fw={500} fs={18} />,
      },
      {
        path: ATS_ROUTES.CONTRACTS,
        label: "Contracts",
        component: (
          <ContractsProvider>
            <Contracts />
          </ContractsProvider>
        ),
        icon: <DocumentText color="#000" size={18} />,
        activIcon: <DocumentText color="#36C" size={18} />,
      },
      {
        path: ATS_ROUTES.BILLING,
        label: "Billing",
        component: (
          <InvoicesProvider>
            <Billing />
          </InvoicesProvider>
        ),
        icon: <BillingIcon />,
        activIcon: <BillingIcon strokeColor="#36C" />,
      },
    ],
  },
  {
    path: ATS_ROUTES.CONTRACTS_DETAILS,
    label: "",
    component: (
      <ContractsProvider>
        <EsignContractDetails />
      </ContractsProvider>
    ),
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
    access: ["Admin"],
  },
  {
    path: ATS_ROUTES.NOTIFICATIONS,
    label: "",
    component: (
      <NotificationsProvider>
        <Notifications />
      </NotificationsProvider>
    ),
    icon: <JobsIcon />,
    activIcon: <JobsIcon strokeColor="#36C" />,
    isHide: true,
    access: ["Admin"],
  },
  {
    path: ATS_ROUTES.HELP,
    label: "Help",
    component: <Help />,
    icon: <HelpIcon />,
    activIcon: <HelpIcon strokeColor="#36C" />,
    access: ["Admin", "Team Member"],
  },
];
export const USER_ROUTES: RoutesProps[] = [
  {
    path: ATS_ROUTES.MY_PROFILE,
    label: "My Profile",
    component: (
      <MyProfileProvider>
        <MyProfile />
      </MyProfileProvider>
    ),
    icon: <UserIcon />,
    activIcon: <UserIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.MY_TEAM,
    label: "My Team",
    component: <></>,
    icon: <MyTeamIcon />,
    activIcon: <MyTeamIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.GETTING_STARTED,
    label: "Getting Started",
    component: <></>,
    icon: <BookmarkIcon />,
    activIcon: <BookmarkIcon strokeColor="#36C" />,
  },
  {
    path: ATS_ROUTES.COMMUNITY,
    label: "Community",
    component: <></>,
    icon: <CommunityIcon />,
    activIcon: <CommunityIcon strokeColor="#36C" />,
  },
];
