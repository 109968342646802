import { FiberManualRecord } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../../../providers/TimesheetProvider";
import { COLORS } from "../../../../../../../../../theme";
import { formattedAmount } from "../../../../../../../../../utils/common";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { ProjectTimesheetTabsEnum } from "../../../../../../../../../enums/projectEnums";
import { onCloseContractorDetailsDrawer } from "../../../ContractorDetailsDrawer";
import { useAppDispatch } from "../../../../../../../../../redux/store";

const text = `After disputing time entries, your Account Manager and our Finance team will conduct a thorough investigation in accordance with our Dispute Policy.`;
const text2 = `If the dispute is deemed valid, the corresponding hours will be waived from payment.`;
const text3 = `In cases where the dispute is found to be invalid, the outstanding hours will be billed in a separate invoice and included in the subsequent pay period.`;
const text4 = `If the dispute is partially valid, we will issue an adjusted invoice reflecting the difference, which will also be added to the next pay period.`;

interface IProps {
  isAllDisputed: boolean;
}

const TimesheetBottomActionSection = ({ isAllDisputed }: IProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    contractorTimeEntries,
    totalDisputedHours,
    totalWorkingHours,
    totalApprovedHours,
    timeEntryLoading,
    timelogApproveLoading,
    onApproveTimesheet,
    setSelectedStatus,
  } = useTimesheet();

  const isNotApprovedAndDipute = contractorTimeEntries.some((log) => {
    return (
      Number(log.approved_time.length ? log.approved_time : "0") === 0 &&
      Number(log.disputed_time.length ? log.disputed_time : "0") === 0
    );
  });

  const onApproveAll = () => {
    const nids = contractorTimeEntries.map((timesheet) =>
      Number(timesheet.nid)
    );
    onApproveTimesheet(nids, id);
  };
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        height={180}
        // sx={{
        //   overflow: "auto",
        // }}
        mt={"30px"}
        gap={"92px"}
      >
        <>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            gap={"6px"}
            sx={{
              width: 484,
            }}
          >
            {timeEntryLoading ? (
              <Skeleton variant="rounded" width={"100%"} height={180} />
            ) : (
              <>
                <AtsIcon
                  icon="icon-info-circle"
                  height="18px"
                  fs={18}
                  color="#E63030"
                />
                <Stack gap={"5px"}>
                  <AtsTitleText
                    text={"Dispute resolution"}
                    fs={14}
                    fw={700}
                    textColor="#e63030"
                  />
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <FiberManualRecord
                      sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                    />
                    <AtsTitleText
                      text={text}
                      fs={12}
                      fw={400}
                      textColor={COLORS.DARK_GRAY}
                      lineHeight={1.5}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <FiberManualRecord
                      sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                    />
                    <AtsTitleText
                      text={text2}
                      fs={12}
                      fw={400}
                      textColor={COLORS.DARK_GRAY}
                      lineHeight={1.5}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <FiberManualRecord
                      sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                    />
                    <AtsTitleText
                      text={text3}
                      fs={12}
                      fw={400}
                      textColor={COLORS.DARK_GRAY}
                      lineHeight={1.5}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <FiberManualRecord
                      sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                    />
                    <AtsTitleText
                      text={text4}
                      fs={12}
                      fw={400}
                      textColor={COLORS.DARK_GRAY}
                      lineHeight={1.5}
                    />
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <Box
              sx={{
                pb: "20px",
                borderBottom: "1px solid",
              }}
            >
              {timeEntryLoading ? (
                <Skeleton variant="rounded" width={"100%"} height={38} />
              ) : (
                <AtsRowBetween>
                  <>
                    <AtsTitleText
                      text={"Disputed"}
                      fs={14}
                      fw={400}
                      textColor="#e63030"
                    />
                    <AtsTitleText
                      text={`- ${formattedAmount(totalDisputedHours)} hrs`}
                      fs={14}
                      fw={400}
                      textColor="#e63030"
                    />
                  </>
                </AtsRowBetween>
              )}
            </Box>
            <Box
              sx={{
                py: "20px",
                borderBottom: "1px solid",
              }}
            >
              {timeEntryLoading ? (
                <Skeleton variant="rounded" width={"100%"} height={38} />
              ) : (
                <AtsRowBetween>
                  <>
                    <AtsTitleText
                      text={"Billable time"}
                      fs={16}
                      fw={600}
                      textColor="#000"
                    />
                    <AtsTitleText
                      text={`${formattedAmount(
                        totalWorkingHours -
                          (totalDisputedHours + totalApprovedHours)
                      )} hrs`}
                      fs={16}
                      fw={600}
                      textColor="#000"
                    />
                  </>
                </AtsRowBetween>
              )}
            </Box>
            <Stack
              sx={{
                pt: "20px",
              }}
            >
              {timeEntryLoading ? (
                <Skeleton variant="rounded" width={"100%"} height={38} />
              ) : (
                <>
                  {isNotApprovedAndDipute ? (
                    <AtsRoundedButton
                      text="Approve all"
                      isInitial
                      fw={400}
                      buttonHeight={38}
                      onClick={onApproveAll}
                      disabled={timelogApproveLoading}
                      loading={timelogApproveLoading}
                    />
                  ) : (
                    <AtsRoundedButton
                      text="Track Dispute Resolution"
                      buttonVariant="outline"
                      isInitial
                      fw={400}
                      buttonHeight={38}
                      onClick={() => {
                        setSelectedStatus(ProjectTimesheetTabsEnum.Disputed);
                        onCloseContractorDetailsDrawer(dispatch);
                      }}
                      disabled={timelogApproveLoading}
                      loading={timelogApproveLoading}
                    />
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </>
      </Stack>
    </>
  );
};

export default TimesheetBottomActionSection;
