import { Container, Stack } from "@mui/material";
import {
  CalenderActiveIcon,
  TimerIcon,
} from "../../../../../../../../assets/icons/Icons";
import CandidateImg from "../../../../../../../../assets/images/candidate.png";
import AtsText from "../../../../../../../../components/AtsText";
import {
  getInterviewState,
  setInterviewIntialState,
  setIsOpenInterviewDetailsDialog,
  setNextTab,
  TabSelect,
} from "../../../../../../../../redux/slices/interviewSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../theme";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";
import AtsRow from "../../../../../../../../components/AtsRow";
import { useMakeAnOffer } from "../../../../../../../../providers/MakeAnOfferProvider";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { HomeIcon, StarIcon } from "../../../../../../assets/Icons";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import { InterviewData } from "../../../../../../../../types/interviewTypes";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";
import AtsRowBetween from "../../../../../../../../components/AtsRowBetween";
import AtsRoundedButton from "../../../../../../../../components/AtsRoundedButton";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../../../../../../router/routes";
import { setCondidateTabValue } from "../../../../../../../../redux/slices/candidateSlice";

interface IProps {
  createInterviewData: InterviewData | null;
}

const InterviewScheduled = ({ createInterviewData }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { count, selectedDate, selectedSlot } =
    useAppSelector(getInterviewState);
  const { formattedCandidateDetails } = useMakeAnOffer();
  return (
    <>
      <Stack
        sx={{
          height: "440px",
        }}
      >
        <Container maxWidth="md">
          <Stack mt={"43px"}>
            <AtsRow justifyContent={"center"}>
              <img
                width={45}
                src={require("../../../../../../../../assets/images/check.gif")}
                alt="check"
              />
            </AtsRow>
            <AtsText
              text={"Success! Your interview is scheduled."}
              sx={{ fontSize: 16, fontWeight: 500, textAlign: "center" }}
            />
            <Stack pt={2} gap={1}>
              <Stack
                sx={{
                  borderRadius: 2,
                  bgcolor: BG_COLORS.GREEN,
                  px: 3,
                  py: 1,
                }}
              >
                <AtsText
                  text={
                    "A Calendar invitation has been sent to your email address"
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: COLORS.GREEN,
                    textAlign: "center",
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  border: BORDERS.GRAY,
                  borderRadius: 2,
                  px: 3,
                  py: 4,
                }}
              >
                <Stack gap={2}>
                  <Stack direction={"row"}>
                    <Stack gap={2} sx={{ flex: 1 }}>
                      <AtsText
                        text={"Meeting Details"}
                        sx={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: COLORS.LIGHT_GRAY,
                        }}
                      />
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsText
                          text={`${count} Mins`}
                          sx={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <AtsIcon
                          icon="icon-calendar-1"
                          color={COLORS.PRIMARY}
                          height="14px"
                          fs={14}
                        />
                        <AtsText
                          text={dateFormatDDDDMMMMD(selectedDate)}
                          sx={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <AtsIcon
                          icon="icon-clock"
                          color={COLORS.PRIMARY}
                          height="14px"
                          fs={14}
                        />
                        <AtsText
                          text={selectedSlot}
                          sx={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      {isNotNullOrUndefined(createInterviewData) && (
                        <Stack
                          direction={"row"}
                          sx={{ alignItems: "center" }}
                          gap={1.5}
                        >
                          <AtsIcon
                            icon="icon-profile-2user"
                            color={COLORS.PRIMARY}
                            height="14px"
                            fs={14}
                          />
                          <AtsText
                            text={createInterviewData?.interviewer
                              ?.map((item) => {
                                const name = `${item?.first_name} ${item?.last_name}`;
                                return name;
                              })
                              .join(",")}
                            sx={{
                              fontSize: 14,
                              color: "#000",
                              fontWeight: 500,
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      gap={2}
                      justifyContent={"center"}
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: BORDERS.GRAY,
                      }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "center" }}
                      >
                        <img
                          src={formattedCandidateDetails?.image}
                          alt="Nvidia Logo"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "100%",
                          }}
                        />
                      </Stack>
                      <AtsText
                        text={formattedCandidateDetails?.name}
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: COLORS.BLACK,
                        }}
                      />
                      <AtsTitleText
                        text={formattedCandidateDetails?.designation}
                        fs={14}
                        fw={400}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        gap={1}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={1}
                          pr={1}
                          sx={{ borderRight: BORDERS.GRAY }}
                        >
                          <AtsTitleText
                            text={formattedCandidateDetails?.rating}
                            fs={12}
                            fw={400}
                            textColor={COLORS.BLACK}
                          />
                          <StarIcon />
                        </Stack>

                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={1}
                          pl={formattedCandidateDetails?.rating ? 1 : 0}
                        >
                          <HomeIcon />
                          <AtsTitleText
                            text={formattedCandidateDetails?.address}
                            fs={12}
                            fw={600}
                            textColor={COLORS.LIGHT_GRAY}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack gap={1}>
                    <AtsText
                      text={"Web Conferencing Details"}
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: COLORS.LIGHT_GRAY,
                      }}
                    />
                    <Stack direction={"row"} gap={1}>
                      <AtsText
                        text={
                          "A meeting link is created on the email shared by you"
                        }
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: COLORS.BLACK,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <AtsRowBetween
                sx={{
                  mt: "30px",
                  px: "20px",
                  py: "10px",
                  borderRadius: "10px",
                  bgcolor: BG_COLORS.GRAY,
                }}
              >
                <>
                  <AtsRoundedButton
                    endIcon={<KeyboardArrowRight />}
                    text="View Event on Calendar"
                    isInitial
                    buttonVariant="outline"
                    buttonWidth={216}
                    buttonHeight={34}
                    onClick={() => {
                      navigate(ATS_ROUTES.CALENDAR);
                      dispatch(setNextTab(TabSelect.ATENDEE_DETAILS));
                    }}
                  />
                  <AtsRoundedButton
                    endIcon={<KeyboardArrowRight />}
                    text="Interview details"
                    isInitial
                    buttonWidth={165}
                    buttonHeight={34}
                    onClick={() => {
                      dispatch(setInterviewIntialState());
                      dispatch(setCondidateTabValue(4));
                    }}
                  />
                </>
              </AtsRowBetween>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default InterviewScheduled;
