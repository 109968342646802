import {
  ArrowCircleUp2,
  ArrowRotateLeft,
  ArrowRotateRight,
  Gallery,
  SearchZoomIn,
  SearchZoomOut1,
} from "iconsax-react";
import React, { useRef, useState } from "react";
import { COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import { Box, Divider, IconButton, Stack } from "@mui/material";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRow from "../../../components/AtsRow";
import { Slider } from "@mui/material";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { useDebounceEffect } from "../../../hooks/useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import "react-image-crop/dist/ReactCrop.css";

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const SignatureUploadBox = () => {
  const fileInputRef = useRef(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 0));

    // if (aspect) {
    //   const { width, height } = e.currentTarget;
    //   setCrop(centerAspectCrop(width, height, aspect));
    // }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleFiles = async (selectedFiles) => {
    const file = selectedFiles[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      console.log("File URL:", fileUrl);
      // You can set this URL in the state if you want to use it in your component
      setFileUrl(fileUrl);
    }

    setFileLoading(false);
  };

  const handleFileChange = (e) => {
    setFileLoading(true);
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    setFileLoading(true);
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {fileUrl ? (
        <Stack
          gap={"10px"}
          sx={{
            border: "2px dashed #3366cc",
            minHeight: 421,
            px: "40px",
            py: "25px",
          }}
        >
          <AtsRow gap={"10px"}>
            <>
              <AtsRow sx={{ flex: 1 }}>
                <>
                  <IconButton
                    sx={{
                      p: "5px",
                    }}
                    onClick={() =>
                      setRotate(Math.min(180, Math.max(-180, rotate - 1)))
                    }
                  >
                    <ArrowRotateLeft size="20" color={COLORS.PRIMARY} />
                  </IconButton>
                  <Slider
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    value={rotate}
                  />
                  <IconButton
                    sx={{
                      p: "5px",
                    }}
                    onClick={() =>
                      setRotate(Math.min(180, Math.max(-180, rotate + 1)))
                    }
                  >
                    <ArrowRotateRight size="20" color={COLORS.PRIMARY} />
                  </IconButton>
                </>
              </AtsRow>
              <Divider variant="fullWidth" orientation="vertical" flexItem />
              <AtsRow sx={{ flex: 1 }}>
                <>
                  <IconButton
                    sx={{
                      p: "5px",
                    }}
                    onClick={() =>
                      setScale((prevScale) => Math.max(prevScale - 0.2, 0.5))
                    }
                  >
                    <SearchZoomOut1 size="20" color={COLORS.PRIMARY} />
                  </IconButton>
                  <Slider
                    value={scale}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                  <IconButton
                    sx={{
                      p: "5px",
                    }}
                    onClick={() =>
                      setScale((prevScale) => Math.min(prevScale + 0.2, 3))
                    }
                  >
                    <SearchZoomIn size="20" color={COLORS.PRIMARY} />
                  </IconButton>
                </>
              </AtsRow>
            </>
          </AtsRow>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            // aspect={aspect}
            // minWidth={400}
            minHeight={100}
            // circularCrop
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={fileUrl}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
              height={"80%"}
              width={"100%"}
            />
          </ReactCrop>
        </Stack>
      ) : (
        <label
          // htmlFor="chooseVideo"
          style={{
            // width: "105px",
            padding: "20px 0px",
            borderRadius: "10px",
            border: "2px dashed #3366cc",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "22px",
            justifyContent: "center",
            cursor: "pointer",
            marginBottom: 0,
            minHeight: 421,
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <Gallery size={56} color={COLORS.LIGHT_GRAY} />
          <Stack gap={"10px"} textAlign={"center"}>
            <AtsTitleText
              text={"Upload or Drag & Drop your signature"}
              fs={18}
              fw={600}
            />
            <Stack>
              <AtsTitleText
                text={`Upload an image of your handwritten signature in JPG, GIF or PNG formats. `}
                fs={14}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
              <AtsTitleText
                text={`The image you upload must be less than 4 Mb.`}
                fs={14}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
            </Stack>
          </Stack>
          <Box>
            <AtsRoundedButton
              text="Upload Image"
              startIcon={<ArrowCircleUp2 size="16" color={COLORS.PRIMARY} />}
              buttonVariant="outline"
              onClick={() => fileInputRef.current.click()}
            />
          </Box>
          <input
            type="file"
            name=""
            accept=".jpg, .png"
            // id="chooseVideo"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </label>
      )}
    </>
  );
};

export default SignatureUploadBox;
