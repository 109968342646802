import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { ProjectTimesheetTabsEnum } from "../../../../../../enums/projectEnums";
import { DisputeResolutionProvider } from "../../../../../../providers/DisputeResolutionProvider";
import { useTimesheet } from "../../../../../../providers/TimesheetProvider";
import {
  BG_COLORS,
  BORDERS,
  BOX_SHADOW,
  COLORS,
} from "../../../../../../theme";
import JobsSwitch from "../../../../../Dashboard/PostedJobs/JobsSwitch";
import ContractorDetailsDrawer from "../ContractorDetails/ContractorDetailsDrawer";
import ApprovedTimesheet from "./modules/ApprovedTimesheet";
import DisputedTimesheet from "./modules/DisputedTimesheet";
import PendingTimeSheet from "./modules/PendingTimeSheet";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import { useParams } from "react-router-dom";

const Timesheets = () => {
  const { id } = useParams();
  const {
    getContractorsByProjectId,
    selectedStatus,
    setSelectedStatus,
    onNextClick,
    onPrevClick,
    dateText,
    date,
    setInputValue,
    inputValue,
    candidateTimelog,
    setCandidateTimelog,
  } = useTimesheet();
  const debounceValue = useDebounce(inputValue);

  useEffect(() => {
    getContractorsByProjectId(id);
  }, [selectedStatus, date, debounceValue]);

  const taskStatuses = Object.keys(ProjectTimesheetTabsEnum).map((status) => ({
    title: status,
    type: ProjectTimesheetTabsEnum[status],
  }));
  const contractorDetailsDrawerMemo = useMemo(
    () => <ContractorDetailsDrawer />,
    []
  );
  return (
    <>
      <Stack mb={"24px"}>
        <AtsTitleText fs={18} fw={600} text={`10 timesheets`} />
      </Stack>
      <Stack direction={"row"} sx={{ pb: "25px" }}>
        <JobsSwitch
          width="62px"
          jobs={taskStatuses}
          active={selectedStatus}
          onChange={(value) => {
            setCandidateTimelog([]);
            setSelectedStatus(value as ProjectTimesheetTabsEnum);
          }}
        />
      </Stack>
      {selectedStatus === ProjectTimesheetTabsEnum.Pending && (
        <Stack direction={"row"} sx={{ pb: "25px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"9px"}
            sx={{
              width: "100%",
              padding: "9px 16px 10px 16px",
              borderRadius: "6px",
              bgcolor: "#ffefea",
            }}
          >
            <AtsIcon
              icon="icon-danger"
              color="#EC7249"
              height="16px"
              fw={600}
            />
            <AtsTitleText
              fs={14}
              fw={400}
              textColor="#000"
              text={
                "All timesheets must be approved within 48 hours; after which they will be auto-approved."
              }
            />
          </Stack>
        </Stack>
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{ pb: "24px" }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"20px"} height={30}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            minHeight={30}
            width={232}
            sx={{
              px: "16px",
              border: BORDERS.GRAY,
              borderRadius: "38px",
            }}
          >
            <input
              type="text"
              className="customInput"
              placeholder="Search contractor name"
              style={{
                width: "100%",
              }}
              value={inputValue}
              onChange={(e) => {
                const value = e.target.value;
                setInputValue(value);
              }}
            />
            <AtsIcon icon="icon-search-normal-1" height="16px" />
          </Stack>
          {/* <Stack
            direction={"row"}
            alignItems={"center"}
            px={"14px"}
            py={"6px"}
            gap={"6px"}
            sx={{
              border: BORDERS.GRAY,
              borderRadius: "48px",
            }}
          >
            <AtsTitleText
              text={"Team"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsIcon icon="icon-arrow-down-1" height="16px" />
          </Stack> */}
        </Stack>
        {selectedStatus === ProjectTimesheetTabsEnum.Pending && (
          <Stack direction={"row"} alignItems={"center"} gap={"24px"}>
            <AtsRoundedButton
              text="Remind to approve"
              buttonHeight={29}
              buttonWidth={166}
              buttonVariant="blackOutline"
              isInitial
            />
            <AtsRoundedButton
              text="Approve all"
              buttonHeight={29}
              buttonWidth={132}
              disabled={candidateTimelog.length === 0}
            />
          </Stack>
        )}
      </Stack>
      <Stack
        sx={{
          height: "calc(100% - 235px)",
          border: BORDERS.GRAY,
          borderRadius: "6px",
          boxShadow: BOX_SHADOW.GRAY,
        }}
      >
        <AtsRowBetween
          sx={{
            px: "22px",
            py: "19px",
            bgcolor: BG_COLORS.GRAY,
            borderBottom: BORDERS.GRAY,
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
          }}
        >
          <>
            <AtsTitleText text={dateText} fs={16} fw={500} textColor="#000" />
            <Stack direction={"row"} gap={"10px"}>
              <IconButton
                sx={{
                  border: BORDERS.GRAY,
                  p: 1,
                }}
                onClick={onPrevClick}
              >
                <ArrowBackIosNew sx={{ fontSize: 12, color: COLORS.PRIMARY }} />
              </IconButton>
              <IconButton
                sx={{
                  border: BORDERS.GRAY,
                  p: 1,
                }}
                onClick={onNextClick}
              >
                <ArrowForwardIos sx={{ fontSize: 12, color: COLORS.PRIMARY }} />
              </IconButton>
            </Stack>
          </>
        </AtsRowBetween>
        <Box
          sx={{
            height: "calc(100% - 80px)",
            overflow: "auto",
          }}
        >
          {/* <PendingTimesheetList /> */}
          <Box px={"30px"} pb={"27px"}>
            {selectedStatus === ProjectTimesheetTabsEnum.Approved ? (
              <ApprovedTimesheet />
            ) : selectedStatus === ProjectTimesheetTabsEnum.Disputed ? (
              <DisputeResolutionProvider>
                <DisputedTimesheet />
              </DisputeResolutionProvider>
            ) : (
              <PendingTimeSheet />
            )}
          </Box>
        </Box>
      </Stack>
      {contractorDetailsDrawerMemo}
    </>
  );
};

export default Timesheets;
