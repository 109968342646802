import { Close, MoreVert } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import { ScheduleIcon } from "../../../../../../../assets/icons/Icons";
import Xfhghggh from "../../../../../../../assets/images/candidate.png";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import {
  getContractorDetailsState,
  setSelectedContractorDrawerTab,
} from "../../../../../../../redux/slices/contractorSlice";
import { getSubmissionState } from "../../../../../../../redux/slices/submissionSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import {
  formattedAmount,
  useClickOutSide,
} from "../../../../../../../utils/common";
import { isNotNullOrUndefined } from "../../../../../../../utils/stringUtils";
import { onCloseContractorDetailsDrawer } from "../ContractorDetailsDrawer";
import { useMemo, useRef, useState } from "react";
import { Briefcase } from "iconsax-react";
import AtsCollapse from "../../../../../../../components/AtsCollapse";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import RequestToConvertDialog from "./RequestToConvertDialog";
import { useRequestToConvert } from "../../../../../../../providers/RequestToConvert";
import DetailsDrawer from "../../../../../../Jobs/Submissions/pipeline/Candidate/CandidateDetails/DetailsDrawer";
import { convertTimestampToDate } from "../../../../../../../utils/dateUtils";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PdfIcon from "../../../../../../../assets/images/pdfIcon.png";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";

const ContractorDetailsTopSection = () => {
  const dispatch = useAppDispatch();

  const { isCandidateDetailsLoading, candidateDetails } =
    useAppSelector(getSubmissionState);
  const { contractorPlacmentDetails } = useAppSelector(
    getContractorDetailsState
  );
  const { setOpenRequestConvertDialog } = useRequestToConvert();
  const { pdfRef } = useTimesheet();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  const onSelectTask = () => {
    setShow(!show);
  };

  const requestToConvertMemo = useMemo(() => <RequestToConvertDialog />, []);
  const candidatDrawerMemo = useMemo(() => <DetailsDrawer />, []);

  const renderRateOrSalaryComponent = () => {
    if (contractorPlacmentDetails.employment_type_id.length) {
      const cost =
        contractorPlacmentDetails.employment_type_id === "36"
          ? `$${formattedAmount(contractorPlacmentDetails.bill_salary)}/yr`
          : `$${formattedAmount(contractorPlacmentDetails.bill_rate)}/hr`;

      return (
        <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
          <AtsTitleText
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
            text={
              contractorPlacmentDetails.employment_type_id === "36"
                ? "Salary:"
                : "Rate:"
            }
          />
          {isCandidateDetailsLoading ? (
            <Skeleton width={45} height={20} />
          ) : (
            <AtsTitleText
              fs={14}
              fw={400}
              textColor={COLORS.BLACK}
              text={cost}
            />
          )}
        </Stack>
      );
    } else {
      return "--";
    }
  };

  const handleDownloadPdf = async () => {
    setLoading(true);
    try {
      const element = pdfRef.current;
      const canvas = await html2canvas(element);

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const imgHeight = (pdfWidth * canvasHeight) / canvasWidth;

      let position = 0;

      while (position < canvasHeight) {
        const canvasSlice = document.createElement("canvas");
        canvasSlice.width = canvasWidth;
        canvasSlice.height = Math.min(
          canvasHeight - position,
          canvasWidth * (pdfHeight / pdfWidth)
        );

        const context = canvasSlice.getContext("2d");
        context.drawImage(canvas, 0, -position);

        const sliceData = canvasSlice.toDataURL("image/png");
        pdf.addImage(
          sliceData,
          "PNG",
          0,
          0,
          pdfWidth,
          (canvasSlice.height * pdfWidth) / canvasWidth
        );

        position += canvasSlice.height;

        if (position < canvasHeight) {
          pdf.addPage();
        }
      }

      pdf.save("profile.pdf");
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
      >
        <AtsTitleText
          text={"Contractor Detail"}
          fs={22}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
          {/* <Stack
            direction={"row"}
            alignItems={"center"}
            gap={0.3}
            sx={{ cursor: "pointer" }}
          >
            <AtsTitleText fs={12} fw={600} textColor={"#000"} text="1" />
            <AtsTitleText fs={12} fw={400} textColor={"#000"} text="of" />
            <AtsTitleText fs={12} fw={600} textColor={"#000"} text="59" />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text="Contractors"
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <ChevronLeft
              sx={{ width: "17px", height: "39px", color: "#6d6d6d" }}
            />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Prev"
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Next"
            />
            <ChevronRight
              sx={{ width: "17px", height: "39px", color: "#000" }}
            />
          </Stack> */}
          <IconButton
            sx={{
              p: "5px",
            }}
            color="inherit"
            onClick={() => onCloseContractorDetailsDrawer(dispatch)}
          >
            <Close
              sx={{
                fontSize: "25px",
              }}
            />
          </IconButton>
        </Stack>
      </Stack>

      <Stack
        py={2}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        sx={{ borderBottom: 1, borderColor: "#f5f5f5", pl: 4, pr: 4 }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
          <Stack>
            {isCandidateDetailsLoading ? (
              <Skeleton variant="circular" width={110} height={110} />
            ) : isNotNullOrUndefined(candidateDetails?.image) ? (
              <img
                src={candidateDetails?.image}
                alt="Nvidia Logo"
                style={{
                  height: "auto",

                  width: "97px",
                  borderRadius: "100%",
                }}
              />
            ) : (
              <img
                src={Xfhghggh}
                alt="Nvidia Logo"
                style={{
                  height: "auto",

                  width: "97px",
                  borderRadius: "100%",
                }}
              />
            )}
          </Stack>
          <Stack gap={"6px"}>
            {isCandidateDetailsLoading ? (
              <Skeleton width={150} height={20} />
            ) : (
              <AtsTitleText
                fs={18}
                fw={700}
                textColor={"#000"}
                text={`${candidateDetails?.first_name ?? ""} ${
                  candidateDetails?.last_name ?? ""
                }`}
              />
            )}
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text={candidateDetails?.job_title ?? ""}
            />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text={"Java Team"}
            />
            <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
              {/* <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={"#000"}
                  text={"4.8"}
                />
                <img
                  src={StarIconRating}
                  alt="Nvidia Logo"
                  style={{ width: 15 }}
                />
              </Stack> */}
              {/* <Divider variant="fullWidth" orientation="vertical" flexItem /> */}
              <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                  text="Onboarded:"
                />
                {isCandidateDetailsLoading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  <AtsTitleText
                    fs={12}
                    fw={400}
                    textColor={"#000"}
                    text={
                      contractorPlacmentDetails?.onboarding_date.length
                        ? convertTimestampToDate(
                            contractorPlacmentDetails?.onboarding_date
                          )
                        : "--"
                    }
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={"44px"} alignItems={"flex-end"}>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsRoundedButton
              buttonWidth={"173px"}
              text="Review timesheet"
              isInitial
              startIcon={<ScheduleIcon />}
              onClick={() => dispatch(setSelectedContractorDrawerTab(1))}
            />
            <Box ref={domNode} sx={{ position: "relative" }}>
              <IconButton
                sx={{ p: 0, marginRight: "-10px" }}
                onClick={() => onSelectTask()}
              >
                <MoreVert />
              </IconButton>
              <Box
                sx={{
                  position: "absolute",
                  bgcolor: "white",
                  right: "0px",
                  top: "30px",
                  zIndex: 9999,
                  width: "180px",
                  borderRadius: "6px",
                }}
              >
                <AtsCollapse in={show}>
                  <Box
                    sx={{
                      border: BORDERS.GRAY,
                      borderRadius: "6px",
                      boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      bgcolor: "white",
                    }}
                  >
                    <Stack gap={1}>
                      <AtsCustomButton
                        width={"auto"}
                        hoverColor={BG_COLORS.PRIMARY}
                        onClick={() => {
                          if (
                            contractorPlacmentDetails.employment_type_id ===
                            "38"
                          ) {
                            setOpenRequestConvertDialog(true);
                          } else {
                            toast.error(
                              `This contractor employment type is ${contractorPlacmentDetails.employment_type}`
                            );
                          }
                        }}
                      >
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <Briefcase color={COLORS.BLACK} size={12} />
                          <AtsTitleText
                            text="Request to convert"
                            fs={12}
                            fw={600}
                          />
                        </Stack>
                      </AtsCustomButton>
                      {loading ? (
                        <AtsCustomButton width={"auto"}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <CircularProgress size={16} />
                            <AtsTitleText
                              text="Downloading PDF.."
                              fs={12}
                              fw={600}
                            />
                          </Stack>
                        </AtsCustomButton>
                      ) : (
                        <AtsCustomButton
                          width={"auto"}
                          hoverColor={BG_COLORS.PRIMARY}
                          onClick={handleDownloadPdf}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <img src={PdfIcon} alt="pdf" height={16} />
                            <AtsTitleText
                              text="Export as PDF"
                              fs={12}
                              fw={600}
                            />
                          </Stack>
                        </AtsCustomButton>
                      )}
                    </Stack>
                  </Box>
                </AtsCollapse>
              </Box>
            </Box>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"40px"}>
            <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
                text="Work Mode:"
              />
              {isCandidateDetailsLoading ? (
                <Skeleton width={60} height={20} />
              ) : (
                <AtsTitleText
                  fs={14}
                  fw={400}
                  textColor={COLORS.BLACK}
                  text={
                    isNotNullOrUndefined(
                      contractorPlacmentDetails?.hours_per_week
                    )
                      ? parseFloat(contractorPlacmentDetails?.hours_per_week) >=
                        30
                        ? "Full-time"
                        : "Part-time"
                      : ""
                  }
                />
              )}
            </Stack>
            {isCandidateDetailsLoading ? (
              <Skeleton width={90} height={20} />
            ) : (
              renderRateOrSalaryComponent()
            )}
          </Stack>
        </Stack>
      </Stack>
      {requestToConvertMemo}
      {candidatDrawerMemo}
    </>
  );
};

export default ContractorDetailsTopSection;
