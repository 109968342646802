import { Box, Stack } from "@mui/material";
import SignaturePad from "react-signature-canvas";
import AtsTitleText from "../../../components/AtsTitleText";
import { useContracts } from "../../../providers/ContractsProvider";
import { BORDERS, COLORS } from "../../../theme";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsCustomButton from "../../../components/AtsCustomButton";

const SignatureDrawBox = () => {
  const { sigCanvas, clear } = useContracts();

  return (
    <>
      <Stack gap={"11px"}>
        <AtsRowBetween>
          <>
            <AtsTitleText
              text={"Draw your signature here using your mouse or trackpad"}
              textColor="#000"
              fs={16}
              fw={600}
            />
            <AtsCustomButton px={0} py={0} onClick={clear}>
              <AtsTitleText
                text={"Clear all"}
                textColor={COLORS.PRIMARY}
                fs={14}
                fw={400}
              />
            </AtsCustomButton>
          </>
        </AtsRowBetween>
        <Box
          sx={{
            border: BORDERS.PRIMARY,
            borderRadius: "10px",
            height: 392,
          }}
        >
          <SignaturePad
            canvasProps={{
              style: {
                width: "100%",
                height: "100%",
              },
            }}
            ref={sigCanvas}
          />
        </Box>
      </Stack>
    </>
  );
};

export default SignatureDrawBox;
