import React from "react";
import Chart from "react-apexcharts";

interface DonutChartProps {
  title: string;
  series: (number | null | undefined)[];
  labels: (string | null | undefined)[];
  colors?: string[];
}

const DonutChart: React.FC<DonutChartProps> = ({
  title,
  series,
  labels,
  colors = ["#3366cc", "#63abfd", "#2ba899"],
}) => {
  const sanitizedSeries =
    series?.filter((value) => typeof value === "number" && value !== null) ||
    [];
  const sanitizedLabels =
    labels?.filter(
      (label) => typeof label === "string" && label.trim() !== ""
    ) || [];

  if (
    sanitizedSeries.length === 0 ||
    sanitizedLabels.length === 0 ||
    sanitizedSeries.length !== sanitizedLabels.length
  ) {
    console.error("Invalid or mismatched series and labels provided:", {
      series,
      labels,
    });
    return <div>Error: Invalid data</div>;
  }
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "donut",
      height: 200,
      fontFamily: "Inter",
    },
    labels: sanitizedLabels,
    legend: {
      show: false,
      // position: "right",
      // offsetX: 20,
      // offsetY: 0,
      // markers: {
      //   shape: "circle",
      //   size: 5,
      //   offsetX: -5,
      //   offsetY: 0,
      // },
      // itemMargin: {
      //   vertical: 6,
      // },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0, // Remove any stroke around the slices
    },
    colors: colors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={sanitizedSeries}
        type="donut"
        height={200}
      />
    </div>
  );
};

export default DonutChart;
