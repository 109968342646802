/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoginMutation } from "../Api/authService";
import AtsRoundedButton from "../components/AtsRoundedButton";
import AtsTitleText from "../components/AtsTitleText";
import AtsTextInput from "../components/AtsTextInput";
import { setIsAuth, setUserInfo } from "../redux/slices/authSlice";
import { useAppDispatch } from "../redux/store";
import AtsIcon from "../components/AtsIcon";
import { BG_COLORS, COLORS } from "../theme";
import { UserManagementServices } from "../Api/userManagementServices";
import { InputEvent } from "../types/commonType";
import { AcceptInviteRequest } from "../types/userManagementTypes";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../router/routes";

export interface IAuthProps {
  mail: string;
  password: string;
  confirmPassword: string;
}

const RegistrationForm = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hashKey = searchParams.get("hash");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<IAuthProps>({
    mail: "",
    password: "",
    confirmPassword: "",
  });

  const getEmailFromHashKey = async () => {
    setLoading(true);
    try {
      const response = await UserManagementServices.getEmailFromHash({
        hash: hashKey,
      });
      setFormData({ ...formData, mail: response.data.email });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    hashKey && getEmailFromHashKey();
  }, [hashKey]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const getUserInfoFromToken = async (access_token: string) => {
    try {
      const response = await UserManagementServices.getUserInfo(access_token);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleResetPassword = async () => {
    if (formData.password === formData.confirmPassword) {
      setError("");
      try {
        const acceptInvite = {
          hash: hashKey,
          password: formData.password,
        } as AcceptInviteRequest;

        await UserManagementServices.acceptInvite(acceptInvite);
        const loginRequest = {
          mail: formData.mail,
          pass: formData.password,
        };
        const loginResponse = await login(loginRequest).unwrap();
        const userInfo = await getUserInfoFromToken(loginResponse.access_token);

        if (userInfo) {
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          dispatch(setUserInfo(userInfo));
        }
        localStorage.setItem("accessToken", loginResponse.access_token);
        navigate(ATS_ROUTES.CREATE_ACCOUNT);
      } catch (error) {
        toast.error(error?.data?.message);
      }
    } else {
      setError("Password does not match");
    }
  };

  const isDisabled = () => {
    if (!formData.password.length) {
      return true;
    } else if (!formData.confirmPassword.length) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box sx={{ width: "100%" }}>
          <Stack
            sx={{
              width: "100%",
              textAlign: "center",
              mt: 2,
              pb: 2,
            }}
          >
            <Box
              sx={{
                height: "50px",
                mb: 2,
              }}
            >
              <img
                height={"100%"}
                src={require("../assets/images/logo.png")}
                alt="wsteemed"
              />
            </Box>
            <AtsTitleText text="Hello!" fs={36} fw={300} />
            <AtsTitleText text="Set up your password." fs={36} fw={300} />
          </Stack>
          <Box
            my={3}
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleResetPassword();
            }}
          >
            {error.length > 0 && (
              <Box
                mb={2}
                sx={{
                  px: "10px",
                  py: "8px",
                  borderRadius: "5px",
                  bgcolor: BG_COLORS.RED,
                  textAlign: "center",
                }}
              >
                <AtsTitleText
                  text={error}
                  fs={12}
                  fw={600}
                  textColor={COLORS.RED}
                />
              </Box>
            )}
            <Box mb={2}>
              <Stack gap={0.5}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  position={"relative"}
                >
                  <AtsTitleText
                    text={"Email"}
                    fs={12}
                    fw={600}
                    textColor={COLORS.BLACK}
                  />
                </Stack>
                <Box
                  sx={{
                    bgcolor: BG_COLORS.GRAY,
                    borderRadius: "8px",
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    pl: "21px",
                  }}
                >
                  <AtsTitleText
                    text={formData.mail}
                    fs={14}
                    fw={400}
                    textColor="#1c1c1c"
                  />
                </Box>
              </Stack>
            </Box>
            <Box mb={3}>
              <AtsTextInput
                height="45px"
                header="New password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter New Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <AtsIcon
                          icon="icon-eye"
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      ) : (
                        <AtsIcon
                          icon="icon-eye-slash"
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                value={formData.password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setFormData({ ...formData, password: inputValue });
                }}
              />
            </Box>
            <Box mb={3}>
              <AtsTextInput
                height="45px"
                header="Confirm password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Confirm Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showConfirmPassword ? (
                        <AtsIcon
                          icon="icon-eye"
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      ) : (
                        <AtsIcon
                          icon="icon-eye-slash"
                          color={COLORS.PRIMARY}
                          height="16px"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                value={formData.confirmPassword}
                onChange={(event: InputEvent): void => {
                  const inputValue = event.target.value;
                  setFormData({ ...formData, confirmPassword: inputValue });
                }}
              />
            </Box>
            <Stack spacing={2}>
              <AtsRoundedButton
                type="submit"
                text="Submit"
                isUppercase={false}
                buttonHeight={40}
                disabled={isLoading || isDisabled()}
                loading={isLoading}
              />
            </Stack>
            <Box sx={{ width: "fit-content", mt: 3 }}>
              <AtsTitleText
                text={"Forgot Password ?"}
                fs={12}
                fw={400}
                isCursor
                isUnderline
              />
            </Box>
          </Box>
          <Stack
            sx={{
              width: "100%",
              textAlign: "center",
              mt: "100px",
              pb: 2,
            }}
          >
            <AtsTitleText text="Powered by Esteemed Inc." fs={10} fw={400} />
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default RegistrationForm;
