import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRowBetween from "../../../components/AtsRowBetween";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import { useAppSelector } from "../../../redux/store";
import { getAuthState } from "../../../redux/slices/authSlice";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { Lock1 } from "iconsax-react";
import AtsSelect from "../../../components/AtsSelect";
import { OptionProps } from "../../../types/commonType";

const dateFormat = [
  {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY",
  },
] as OptionProps[];
const timeFormat = [
  {
    label: "12h (am/pm)",
    value: "12h (am/pm)",
  },
] as OptionProps[];
const country = [
  {
    label: "US",
    value: "US",
  },
] as OptionProps[];
const timeZone = [
  {
    label: "Eastern Time - US & Canada",
    value: "Eastern Time - US & Canada",
  },
] as OptionProps[];

const MyAccountInfo = () => {
  const { userInfo } = useAppSelector(getAuthState);

  const RenderDropdown = ({
    header,
    value,
    options,
    onSelect,
    isMendetory = false,
  }) => {
    return (
      <Stack gap={"5px"}>
        {isMendetory ? (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: COLORS.BLACK,
            }}
          >
            {header}
            <span
              style={{
                color: "red",
              }}
            >
              *
            </span>
          </Typography>
        ) : (
          <AtsTitleText text={header} fs={14} fw={600} />
        )}
        <AtsSelect
          height="41px"
          options={options}
          selected={value}
          onSelect={onSelect}
        />
      </Stack>
    );
  };
  return (
    <>
      <Stack gap={"30px"}>
        <Box px={"10px"}>
          <AtsTitleText
            text={"Account Information"}
            fs={18}
            fw={600}
            textColor="#000"
          />
        </Box>
        <Stack gap={"30px"}>
          <AtsRowBetween flexWrap={"wrap"} px={"10px"}>
            <>
              <Stack
                gap={"5px"}
                sx={{
                  flexGrow: 0.2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: COLORS.BLACK,
                  }}
                >
                  {"Email address"}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Box
                  sx={{
                    bgcolor: BG_COLORS.GRAY,
                    borderRadius: "8px",
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    pl: "21px",
                  }}
                >
                  <AtsTitleText
                    text={userInfo?.email ?? ""}
                    fs={14}
                    fw={400}
                    textColor="#1c1c1c"
                  />
                </Box>
                <AtsTitleText
                  text={"Contact your account manager to change your email."}
                  fs={12}
                  fw={400}
                  textColor={"#e63030"}
                />
              </Stack>
              <AtsRoundedButton
                lineHeight="0px"
                text="Reset password"
                isInitial
                buttonWidth={180}
                buttonHeight={34}
                startIcon={<Lock1 color="white" size={16} fontWeight={600} />}
              />
            </>
          </AtsRowBetween>
          <Grid
            container
            columnSpacing={"40px"}
            rowSpacing={"20px"}
            px={"10px"}
          >
            <Grid item xs={12} sm={6}>
              <RenderDropdown
                header={"Date Format"}
                options={dateFormat}
                value={"DD/MM/YYYY"}
                onSelect={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderDropdown
                header={"Time Format"}
                options={timeFormat}
                value={"12h (am/pm)"}
                onSelect={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderDropdown
                header={"Country"}
                options={country}
                value={"US"}
                onSelect={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RenderDropdown
                header={"Time Zone"}
                options={timeZone}
                value={"Eastern Time - US & Canada"}
                onSelect={() => {}}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default MyAccountInfo;
