import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import ReactApexChart from "react-apexcharts";
import DonutChart from "../../Projects/modules/ProjectDetailsModules/Overview/components/DonutChart";
import AtsRow from "../../../components/AtsRow";
import { COLORS } from "../../../theme";

const GenderDiversity = () => {
  const [series, setSeries] = useState([44, 55, 13, 33]);
  const [options] = useState({
    chart: {
      width: 380,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
  });
  return (
    <>
      <Stack gap={"20px"} py={"30px"}>
        <AtsTitleText text={"Gender diversity"} fs={18} fw={600} />
        <AtsRow>
          <>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <DonutChart
                title={""}
                series={[39, 61]}
                labels={["Female", "Male"]}
                colors={["#BFDBFE", "#2563EB"]}
              />
            </Box>
            <Stack
              gap={"10px"}
              sx={{
                width: "50%",
              }}
            >
              <AtsRow gap={"10px"}>
                <>
                  <Box
                    sx={{
                      bgcolor: "#BFDBFE",
                      width: 13,
                      height: 13,
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <AtsRow gap={"5px"}>
                    <>
                      <AtsTitleText
                        text={"39%"}
                        fs={18}
                        fw={600}
                        textColor="#000"
                      />
                      <AtsTitleText
                        text={"Female"}
                        fs={12}
                        fw={500}
                        textColor={COLORS.DARK_GRAY}
                      />
                    </>
                  </AtsRow>
                </>
              </AtsRow>
              <AtsRow gap={"10px"}>
                <>
                  <Box
                    sx={{
                      bgcolor: "#2563EB",
                      width: 13,
                      height: 13,
                      borderRadius: "50%",
                    }}
                  ></Box>
                  <AtsRow gap={"5px"}>
                    <>
                      <AtsTitleText
                        text={"61%"}
                        fs={18}
                        fw={600}
                        textColor="#000"
                      />
                      <AtsTitleText
                        text={"Male"}
                        fs={12}
                        fw={500}
                        textColor={COLORS.DARK_GRAY}
                      />
                    </>
                  </AtsRow>
                </>
              </AtsRow>
            </Stack>
          </>
        </AtsRow>
      </Stack>
    </>
  );
};

export default GenderDiversity;
