import { Box, Drawer } from "@mui/material";
import {
  getSubmissionState,
  setIsCandidateDetailsDrawerOpen,
} from "../../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import CandidateDetailsSection from "./CandidateDetailsSection";
import CandidateFullDetails from "./CandidateFullDetails";
import MakeAnOfferDialog from "./CandidateDetailsTabs/MakeAnOfferTab/module/MakeAnOfferDialog";
import { MakeAnOfferProvider } from "../../../../../../providers/MakeAnOfferProvider";
import ScheduleInterviewDialog from "./CandidateDetailsTabs/InterViewTab/modules/ScheduleInterviewDialog";
import { setCondidateTabValue } from "../../../../../../redux/slices/candidateSlice";

const DetailsDrawer = () => {
  const dispatch = useAppDispatch();
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  return (
    <>
      <MakeAnOfferProvider>
        <>
          <Drawer
            sx={{}}
            PaperProps={{
              sx: {
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                width: "1130px",
                overflow: "hidden",
              },
            }}
            anchor="right"
            open={isCandidateDetailsDrawerOpen}
            onClose={() => {
              dispatch(
                setIsCandidateDetailsDrawerOpen(!isCandidateDetailsDrawerOpen)
              );
              dispatch(setCondidateTabValue(0));
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                border: "0px solid #000",
                boxShadow: 24,
                outline: "0",
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                pb: 2,
                height: "100%",
              }}
            >
              <CandidateDetailsSection />
              <CandidateFullDetails />
            </Box>
          </Drawer>
          <MakeAnOfferDialog />
          <ScheduleInterviewDialog />
        </>
      </MakeAnOfferProvider>
    </>
  );
};

export default DetailsDrawer;
