import { Stack } from "@mui/material";
import AtsTitleText from "../../components/AtsTitleText";
import ActiveContracts from "./modules/ActiveContracts";
import ContractFilterSection from "./modules/ContractFilterSection";
import InActiveContractCard from "./modules/InActiveContractCard";

const MasterContracts = () => {
  return (
    <>
      <Stack px={"9px"}>
        <ContractFilterSection />
      </Stack>
      <Stack gap={"15px"} mt={"20px"}>
        <Stack px={"9px"}>
          <AtsTitleText text={"1 Active"} textColor="#000" fs={18} fw={700} />
        </Stack>
        <ActiveContracts />
      </Stack>
      <Stack gap={"15px"} mt={"70px"}>
        <Stack px={"9px"}>
          <AtsTitleText
            text={"12 Inactive"}
            textColor="#000"
            fs={18}
            fw={700}
          />
        </Stack>

        <InActiveContractCard />
        <InActiveContractCard />
        <InActiveContractCard />
        <InActiveContractCard />
        <InActiveContractCard />
      </Stack>
    </>
  );
};

export default MasterContracts;
