import React, { createContext, useContext, useEffect, useState } from "react";
import { UserUpdateRequest } from "../types/userManagementTypes";
import {
  companyUpdateValidation,
  updateUserValidation,
} from "../utils/validationUtils";
import { CompanyServices } from "../Api/companyServices";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { getAuthState, setUserInfo } from "../redux/slices/authSlice";
import {
  CompanyAddressProps,
  UpdateCompanyInfoRequest,
} from "../types/companyTypes";
import { OptionProps } from "../types/commonType";
import { UserManagementServices } from "../Api/userManagementServices";
import { toast } from "react-toastify";
import { Briefcase } from "iconsax-react";
import {
  AdrenalineSportsIcon,
  AnimalsIcon,
  ArchitectureIcon,
  BasketballIcon,
  FilmsIcon,
  FootballIcon,
  LiveSportIcon,
  ReadingIcon,
  TrackIcon,
  TravelIcon,
  TVIcon,
  UltimateFrisbeeIcon,
  VideoGamesIcon,
  VollyballIcon,
  VolunteeringIcon,
  WalkingIcon,
  WaterPoloIcon,
  WaterSportIcon,
  WeigthLiftingIcon,
  WineIcon,
  WrestlingIcon,
  WritingIcon,
  YogaIcon,
} from "../pages/MyProfile/assets/ProfileIcon";
import { COLORS } from "../theme";

export enum UsedPlatformEnums {
  school = "school",
  work = "work",
  live = "live",
  languagesSpeak = "languagesSpeak",
  born = "born",
  favoriteSong = "favoriteSong",
  obsessedWith = "obsessedWith",
  sfunFactchool = "funFact",
  unlessSkill = "unlessSkill",
  funFact = "funFact",
  biographyTitle = "biographyTitle",
  spendTime = "spendTime",
  pets = "pets",
}

export interface UsedPlatformProps {
  icon: any;
  text: string;
  subText: string;
  state: string;
}

export const interestes = [
  {
    title: "Adrenaline sports",
    icon: <AdrenalineSportsIcon />,
    activeIcon: <AdrenalineSportsIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Animals",
    icon: <AnimalsIcon />,
    activeIcon: <AnimalsIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Architecture",
    icon: <ArchitectureIcon />,
    activeIcon: <ArchitectureIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Basket ball",
    icon: <BasketballIcon />,
    activeIcon: <BasketballIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Films",
    icon: <FilmsIcon />,
    activeIcon: <FilmsIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Football",
    icon: <FootballIcon />,
    activeIcon: <FootballIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Live sport",
    icon: <LiveSportIcon />,
    activeIcon: <LiveSportIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Readinng",
    icon: <ReadingIcon />,
    activeIcon: <ReadingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Track & field",
    icon: <TrackIcon />,
    activeIcon: <TrackIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Travel",
    icon: <TravelIcon />,
    activeIcon: <TravelIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "TV",
    icon: <TVIcon />,
    activeIcon: <TVIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Ultimate frisbee",
    icon: <UltimateFrisbeeIcon />,
    activeIcon: <UltimateFrisbeeIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Video games",
    icon: <VideoGamesIcon />,
    activeIcon: <VideoGamesIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Volleyball",
    icon: <VollyballIcon />,
    activeIcon: <VollyballIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Volunteering",
    icon: <VolunteeringIcon />,
    activeIcon: <VolunteeringIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Walking",
    icon: <WalkingIcon />,
    activeIcon: <WalkingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Water polo",
    icon: <WaterPoloIcon />,
    activeIcon: <WaterPoloIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Water sport",
    icon: <WaterSportIcon />,
    activeIcon: <WaterSportIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Weight lifting",
    icon: <WeigthLiftingIcon />,
    activeIcon: <WeigthLiftingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Wine",
    icon: <WineIcon />,
    activeIcon: <WineIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Wrestling",
    icon: <WrestlingIcon />,
    activeIcon: <WrestlingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Writing",
    icon: <WritingIcon />,
    activeIcon: <WritingIcon strokeColor={COLORS.PRIMARY} />,
  },
  {
    title: "Yoga",
    icon: <YogaIcon />,
    activeIcon: <YogaIcon strokeColor={COLORS.PRIMARY} />,
  },
];

export const usedPlatforms: UsedPlatformProps[] = [
  {
    icon: Briefcase,
    text: "Where I went to school?",

    subText:
      "Whether it's home school, secondary school or trade school, name the school that made you who you are.",
    state: UsedPlatformEnums.school,
  },
  {
    icon: Briefcase,
    text: "My work",
    subText: "",
    state: UsedPlatformEnums.work,
  },
  {
    icon: Briefcase,
    text: "Where I live?",
    subText:
      "Tell us a little bit about yourself so your future Hosts or guests can get to know you.",
    state: UsedPlatformEnums.live,
  },
  {
    icon: Briefcase,
    text: "Languages I speak",
    subText: "",
    state: UsedPlatformEnums.languagesSpeak,
  },
  {
    icon: Briefcase,
    text: "Decade I was born",
    subText: "",
    state: UsedPlatformEnums.born,
  },
  {
    icon: Briefcase,
    text: "My favourite song in secondary school",
    subText: "",
    state: UsedPlatformEnums.favoriteSong,
  },
  {
    icon: Briefcase,
    text: `I'm obsessed with`,
    subText: "",
    state: UsedPlatformEnums.obsessedWith,
  },
  {
    icon: Briefcase,
    text: "My fun fact",
    subText: "",
    state: UsedPlatformEnums.funFact,
  },
  {
    icon: Briefcase,
    text: "My most useless skill",
    subText: "",
    state: UsedPlatformEnums.unlessSkill,
  },
  {
    icon: Briefcase,
    text: "My biography title would be",
    subText: "",
    state: UsedPlatformEnums.biographyTitle,
  },
  {
    icon: Briefcase,
    text: "I spend too much time",
    subText: "",
    state: UsedPlatformEnums.spendTime,
  },
  {
    icon: Briefcase,
    text: "Pets",
    subText: "",
    state: UsedPlatformEnums.pets,
  },
];

const createAccountStepperForClient = [
  {
    text: "Account details",
    value: "ACCOUNT",
  },
  {
    text: "Company details",
    value: "COMPANY",
  },
  {
    text: "Submit",
    value: "SUBMIT",
  },
];
const createAccountStepper = [
  {
    text: "Account details",
    value: "ACCOUNT",
  },
  {
    text: "Submit",
    value: "SUBMIT",
  },
];

type CreateAccountModuleContextType = {
  selectedStep: string;
  searchQuery: string;
  stepperData: any;
  activeStep: number;
  loading: boolean;
  updateUserLoading: boolean;
  companyLogo: {
    fid: string;
    image: string;
  };
  setCompanyLogo: React.Dispatch<
    React.SetStateAction<{
      fid: string;
      image: string;
    }>
  >;
  companyInfoLoading: boolean;
  companyUpdateLoading: boolean;
  isUpdateSuccessFully: boolean;
  isShowYourProfile: boolean;
  selectedPlatform: UsedPlatformProps | null;
  accountDetailsState: UserUpdateRequest;
  companySize: OptionProps[];
  selectedInterestes: string[];
  companyInfoState: UpdateCompanyInfoRequest | null;
  accountDetailsError: any;
  companyDetailsError: any;
  companySizeText: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSelectedInterestes: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPlatform: React.Dispatch<
    React.SetStateAction<UsedPlatformProps | null>
  >;
  setIsShowYourProfile: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccountDetailsChange: (
    key: keyof UserUpdateRequest,
    value: string
  ) => void;
  handleCompanyDetailsChange: (
    key: keyof UpdateCompanyInfoRequest,
    value: string | CompanyAddressProps
  ) => void;
  updateUserDetails: () => void;
  updateCompanyDetails: () => void;
  getCompanyInfo: () => void;
  getCompanySizeInfo: () => void;
};

const CreateAccountModule =
  createContext<CreateAccountModuleContextType | null>(null);

export const useCreateAccount = () =>
  useContext(CreateAccountModule) as CreateAccountModuleContextType;

export const CreateAccountProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const isClient = userInfo?.roles?.some((role) => role === "client");
  const stepperData =
    isClient && userInfo?.sub_role?.role_name === "Admin"
      ? createAccountStepperForClient
      : createAccountStepper;

  const isAdminAndClient =
    isClient && userInfo?.sub_role?.role_name === "Admin";
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedStep, setSelectedStep] = useState<string>("ACCOUNT");
  const [companyLogo, setCompanyLogo] = useState<{
    fid: string;
    image: string;
  }>({
    fid: null,
    image: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [updateUserLoading, setUpdateUserLoading] = useState<boolean>(false);
  const [companyInfoLoading, setCompanyInfoLoading] = useState<boolean>(false);
  const [isShowYourProfile, setIsShowYourProfile] = useState<boolean>(false);
  const [companyUpdateLoading, setCompanyUpdateLoading] =
    useState<boolean>(false);
  const [isUpdateSuccessFully, setIsUpdateSuccessFully] =
    useState<boolean>(false);
  const [accountDetailsState, setAccountDetailsState] =
    useState<UserUpdateRequest>({
      last_name: userInfo?.last_name ?? "",
      phone_number: "",
      profile_pic_fid: "",
      first_name: userInfo?.first_name ?? "",
      job_title: userInfo?.title ?? "",
      linkedin_profile_url: userInfo?.linkedin_profile_url ?? "",
    });
  const [accountDetailsError, setAccountDetailsError] = useState<any>({});
  const [companyDetailsError, setCompanyDetailsError] = useState<any>({});
  const [companySizeText, setCompanySizeText] = useState<string>("");
  const [selectedInterestes, setSelectedInterestes] = useState<string[]>([]);
  const [selectedPlatform, setSelectedPlatform] =
    useState<UsedPlatformProps | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyInfoState, setCompanyInfoState] =
    useState<UpdateCompanyInfoRequest>(null);
  const [companySize, setCompanySize] = useState<OptionProps[]>([]);

  const handleAccountDetailsChange = (
    key: keyof UserUpdateRequest,
    value: string
  ) => {
    setAccountDetailsState({ ...accountDetailsState, [key]: value });
  };

  const handleCompanyDetailsChange = (key: keyof any, value: string) => {
    setCompanyInfoState({ ...companyInfoState, [key]: value });
  };

  const getUserInfoFromToken = async (access_token: string) => {
    try {
      const response = await UserManagementServices.getUserInfo(access_token);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const updateUserDetails = async () => {
    const errorObj = updateUserValidation(accountDetailsState);
    setAccountDetailsError(errorObj.error);
    if (errorObj.isValid) {
      setUpdateUserLoading(true);
      try {
        await UserManagementServices.updateUser(accountDetailsState);
        const access_token = localStorage.getItem("accessToken");
        const userInfo = await getUserInfoFromToken(access_token);

        if (userInfo) {
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          dispatch(setUserInfo(userInfo));
        }
        setIsUpdateSuccessFully(true);
        isAdminAndClient ? setActiveStep(1) : setActiveStep(2);
      } catch (error) {
        console.error(error);
        setIsUpdateSuccessFully(false);
      } finally {
        console.log("false");
        setUpdateUserLoading(false);
      }
    }
  };

  const updateCompanyDetails = async () => {
    const errorObj = companyUpdateValidation(companyInfoState, searchQuery);
    setCompanyDetailsError(errorObj.error);
    if (errorObj.isValid) {
      setCompanyUpdateLoading(true);
      try {
        const updateCompanyObj = {
          ...companyInfoState,
          ...(companyLogo.fid && { logo_fid: companyLogo.fid }),
        } as UpdateCompanyInfoRequest;
        await CompanyServices.updateCompanyInfo(updateCompanyObj);
        const access_token = localStorage.getItem("accessToken");
        const userInfo = await getUserInfoFromToken(access_token);

        if (userInfo) {
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          dispatch(setUserInfo(userInfo));
        }
        await getCompanyInfo();
        setActiveStep(2);
        setIsUpdateSuccessFully(true);
        toast.success("Company updated");
      } catch (error) {
        console.error(error);
        setIsUpdateSuccessFully(false);
      } finally {
        setCompanyUpdateLoading(false);
      }
    }
  };

  const getCompanySizeInfo = async () => {
    try {
      const response = await CompanyServices.getCompanySize();
      const companyOptions = response.results.map(
        (info) => ({ label: info.title, value: info.id } as OptionProps)
      );
      setCompanySize(companyOptions);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getCompanyInfo = async () => {
    setCompanyInfoLoading(true);
    try {
      const response = await CompanyServices.getCompanyInfoById({
        nid: userInfo?.company?.id,
      });

      if (response.results.length) {
        const comapnyDetails = response.results[0];
        setCompanyLogo({
          fid: null,
          image: comapnyDetails.logo_image_url,
        });
        setCompanySizeText(comapnyDetails.size);
        const companyInfo = {
          nid: comapnyDetails.nid,
          company_size_id: comapnyDetails.size_id,
          title: comapnyDetails.title,
          description: comapnyDetails.description,
          linkedin: comapnyDetails.linkedin,
          website: comapnyDetails.website,
          founded: comapnyDetails?.founded ?? "",
          phone: comapnyDetails.phone,
          company_address: {
            country: comapnyDetails.country,
            administrative_area: comapnyDetails.administrative_area,
            locality: comapnyDetails.locality,
            postal_code: comapnyDetails.postal_code,
            address_line1: comapnyDetails.address_line1,
            country_code: comapnyDetails?.country_code ?? "",
          },
        } as UpdateCompanyInfoRequest;
        setCompanyInfoState(companyInfo);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCompanyInfoLoading(false);
    }
  };

  useEffect(() => {
    getCompanyInfo();
    getCompanySizeInfo();
  }, []);

  return (
    <CreateAccountModule.Provider
      value={{
        activeStep,
        selectedInterestes,
        selectedStep,
        companySizeText,
        searchQuery,
        setSelectedStep,
        setCompanyLogo,
        stepperData,
        companyLogo,
        loading,
        isShowYourProfile,
        companyUpdateLoading,
        updateUserLoading,
        companyInfoLoading,
        isUpdateSuccessFully,
        accountDetailsState,
        companyInfoState,
        accountDetailsError,
        companyDetailsError,
        companySize,
        selectedPlatform,
        updateUserDetails,
        updateCompanyDetails,
        getCompanyInfo,
        getCompanySizeInfo,
        setActiveStep,
        setSelectedInterestes,
        setSearchQuery,
        setSelectedPlatform,
        setIsShowYourProfile,
        handleAccountDetailsChange,
        handleCompanyDetailsChange,
      }}
    >
      {children}
    </CreateAccountModule.Provider>
  );
};
