import { IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTextArea from "../../../components/AtsTextArea";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getProjectsState,
  initailCreateProjectState,
  setCreateProjectState,
} from "../../../redux/slices/projectsSlice";
import { createProjectParams } from "../../../types/projectTypes";
import { Close } from "@mui/icons-material";
import AtsDateRangePicker from "../../../components/AtsDateRangePicker";
import { Button } from "@mui/material";
import AtsIcon from "../../../components/AtsIcon";
import moment from "moment";
import LocationComponent from "../../Projects/components/LocationComponent";
import AtsRow from "../../../components/AtsRow";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { ProjectService } from "../../../Api/projectServices";
import { getAuthState } from "../../../redux/slices/authSlice";
import toast from "react-hot-toast";

interface IProps {
  toggleDrawer: () => void;
  fetchMyProject: () => void;
}

const CreateProjectDrawer = ({ toggleDrawer, fetchMyProject }: IProps) => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);
  const { userInfo } = useAppSelector(getAuthState);

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(
    "Start Date - End Date"
  );

  const handleEvent = (event, picker) => {
    const startDate = moment(picker.startDate).format("YYYY-MM-DD");
    const endDate = moment(picker.endDate).format("YYYY-MM-DD");
    setSelectedDate(`${startDate} - ${endDate}`);
    const updateProjectState = {
      ...createProjectState,
      start_date: startDate,
      end_date: endDate,
    } as createProjectParams;
    dispatch(setCreateProjectState(updateProjectState));
  };
  const handleClear = () => {
    setSelectedDate(`Start Date - End Date`);
  };

  const handleChange = (key: keyof createProjectParams, value: string) => {
    const updateProjectData = {
      ...createProjectState,
      [key]: value,
    };
    dispatch(setCreateProjectState(updateProjectData));
  };

  const disabled = () => {
    const conditions = [
      !createProjectState.title.length,
      !createProjectState.description.length,
      !createProjectState.start_date.length,
      !createProjectState.end_date.length,
      !createProjectState.location.country.length,
    ];
    return conditions.some(Boolean);
  };

  const onCreateProject = async () => {
    setLoading(true);

    try {
      const updateProjectState = {
        title: createProjectState?.title,
        description: createProjectState?.description,
        company_id: userInfo?.company?.id,
        client_email: userInfo?.email,
        start_date: createProjectState.start_date,
        end_date: createProjectState?.end_date,
        status: createProjectState?.status,
        location: createProjectState?.location,
      } as createProjectParams;
      const response = await ProjectService.createProject(updateProjectState);
      if (response?.data) {
        await fetchMyProject();
        toggleDrawer();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error during file uploads:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
      >
        <AtsTitleText
          text={"Add Project Details"}
          fs={22}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
          <IconButton
            sx={{
              p: "5px",
            }}
            color="inherit"
            onClick={toggleDrawer}
          >
            <Close
              sx={{
                fontSize: "25px",
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
      <Stack gap={"30px"} p={"25px"}>
        <Stack gap={1}>
          <AtsTitleText
            isMandatory
            text={"Project Name"}
            fs={"0.875rem"}
            fw={500}
          />
          <AtsTextInput
            placeholder="Name (required)"
            value={createProjectState.title}
            onChange={(event) => handleChange("title", event.target.value)}
          />
        </Stack>
        <AtsTextArea
          isMandatory
          header="Project Description"
          placeholder="Description (required)"
          isBlackText
          loading={false}
          cols={12}
          rows={7}
          className="textArea"
          value={createProjectState.description}
          onChange={(event) => handleChange("description", event.target.value)}
        />
        <Stack gap={1}>
          <AtsTitleText
            isMandatory
            text={"Start date"}
            fs={"0.875rem"}
            fw={500}
          />
          <AtsDateRangePicker
            handleEvent={handleEvent}
            handleClear={handleClear}
            minDate={new Date()}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "initial",
                height: 42,
                borderRadius: "6px",
                border: BORDERS.GRAY,
                color: COLORS.BLACK,
                fontSize: 14,
                fontWeight: 400,
                justifyContent: "flex-start",
                ":hover": {
                  border: BORDERS.GRAY,
                  color: COLORS.BLACK,
                },
              }}
              startIcon={
                <AtsIcon
                  icon="icon-calendar-1"
                  color={COLORS.BLACK}
                  height="30px"
                />
              }
            >
              {selectedDate}
            </Button>
          </AtsDateRangePicker>
        </Stack>
        <Stack gap={1}>
          <AtsTitleText
            isMandatory
            text={"Project location"}
            fs={"0.875rem"}
            fw={500}
          />
          <LocationComponent mapHeight={200} />
        </Stack>
        <AtsRow justifyContent={"center"}>
          <AtsRoundedButton
            text="Create"
            buttonWidth={130}
            buttonHeight={32}
            disabled={disabled() || loading}
            loading={loading}
            onClick={onCreateProject}
          />
        </AtsRow>
      </Stack>
    </>
  );
};

export default CreateProjectDrawer;
