import {
  ArrowForwardIos,
  DownloadForOfflineOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useState } from "react";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import OfferStatusStepper from "./OfferStatusStepper";
import { ArrowCircleDown2, ArrowRight2 } from "iconsax-react";
import { COLORS } from "../../../../../../../../../../theme";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import { useAppDispatch } from "../../../../../../../../../../redux/store";
import {
  setIsContractorDetailsDrawerOpen,
  setSelectedContractorDrawerTab,
} from "../../../../../../../../../../redux/slices/contractorSlice";
import { setIsCandidateDetailsDrawerOpen } from "../../../../../../../../../../redux/slices/submissionSlice";
import { setCondidateTabValue } from "../../../../../../../../../../redux/slices/candidateSlice";

const StatusDetails = () => {
  const { selectedOffer } = useMakeAnOffer();
  const dispatch = useAppDispatch();

  const allStatusStep = [
    "Offer Extended",

    "Under Negotiation",

    "Offer Revised",
    "Offer Accepted",
  ];
  const expiteStatusStep = ["Offer Extended", "Expired"];
  const rejectedStatusStep = ["Offer Extended", "Rejected"];

  const statusStep =
    selectedOffer.status === "Rejected"
      ? rejectedStatusStep
      : selectedOffer.status === "Expired"
      ? expiteStatusStep
      : allStatusStep;
  const onBoardContract = () => {
    dispatch(setIsContractorDetailsDrawerOpen(true));
    dispatch(setIsCandidateDetailsDrawerOpen(false));
    dispatch(setCondidateTabValue(0));
    dispatch(setSelectedContractorDrawerTab(5));
  };

  return (
    <>
      <Stack
        className="scrollHide"
        sx={{ minWidth: 196, height: 270, overflow: "auto" }}
      >
        <OfferStatusStepper
          activeStep={allStatusStep.indexOf(selectedOffer.status)}
          stepData={allStatusStep}
        />
      </Stack>
      {selectedOffer.status !== "Rejected" &&
        selectedOffer.status !== "Expired" && (
          <Stack gap={2} pt={"40px"} mx={1}>
            <AtsRoundedButton
              buttonWidth={180}
              buttonHeight={34}
              text="Download Offer"
              buttonVariant="outline"
              startIcon={<ArrowCircleDown2 size={16} color={COLORS.PRIMARY} />}
            />
            <AtsRoundedButton
              buttonWidth={180}
              buttonHeight={34}
              text="Onboard Contractor"
              fs={12}
              fw={500}
              endIcon={<ArrowRight2 size={16} color={"white"} />}
              onClick={onBoardContract}
            />
          </Stack>
        )}
    </>
  );
};

export default StatusDetails;
