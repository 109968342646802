import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRow from "../../../components/AtsRow";
import { Grid } from "@mui/material";
import AtsRowBetween from "../../../components/AtsRowBetween";

const data = [
  {
    title: "MOST EMPLOYEES",
    items: [
      { text: "New York City Metropolitan Area", value: "14" },
      { text: "Ngeria", value: "7" },
      { text: "Greater Sealttle Area", value: "4" },
    ],
  },
  {
    title: "FAST GROWING",
    items: [
      { text: "New York City Metropolitan Area", value: "+33%" },
      { text: "Ngeria", value: "+17%" },
      { text: "Greater Sealttle Area", value: "0%" },
    ],
  },
  {
    title: "REMOTE LOCATIONS",
    items: [
      { text: "New York City Metropolitan Area", value: "+33%" },
      { text: "Ngeria", value: "+17%" },
      { text: "Greater Sealttle Area", value: "0%" },
    ],
  },
];

const JobRequisitionsByLocations = () => {
  return (
    <>
      <Box
        sx={{
          p: "25px",
          border: BORDERS.GRAY,
          borderRadius: "10px",
          bgcolor: "white",
        }}
      >
        <Stack gap={"20px"}>
          <AtsTitleText
            text={"Job requisitions by locations"}
            fs={18}
            fw={600}
          />
          <Box
            sx={{
              borderRadius: "10px",
              height: 400,
              bgcolor: BG_COLORS.PRIMARY,
            }}
          ></Box>
          <AtsRow gap={"5px"}>
            <>
              <AtsTitleText text={"Bubble size"} fs={12} fw={700} />
              <AtsTitleText text={"-"} fs={12} fw={700} />
              <AtsTitleText
                text={"Number of professionals"}
                fs={12}
                fw={400}
                color={COLORS.LIGHT_GRAY}
              />
            </>
          </AtsRow>
          <Grid container rowSpacing={"30px"} columnSpacing={"40px"}>
            {data.map((category, index) => (
              <CategoryGridItem
                key={index}
                title={category.title}
                items={category.items}
              />
            ))}
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default JobRequisitionsByLocations;

const CategoryGridItem = ({ title, items }) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
    <Stack gap={"5px"}>
      <Typography
        sx={{
          letterSpacing: "2px",
          fontSize: 12,
          color: COLORS.LIGHT_GRAY,
          mb: "5px",
        }}
      >
        {title}
      </Typography>
      <Stack gap={"3px"}>
        {items.map((item, index) => (
          <TextBetweenWrapper key={index} text={item.text} value={item.value} />
        ))}
      </Stack>
    </Stack>
  </Grid>
);

const TextBetweenWrapper = ({ text, value }) => {
  return (
    <AtsRowBetween>
      <>
        <AtsTitleText text={text} fs={12} fw={500} color={COLORS.DARK_GRAY} />
        <AtsTitleText text={value} fs={12} fw={700} />
      </>
    </AtsRowBetween>
  );
};
