import { createContext, useContext, useState } from "react";
import { InsightTypeTabs } from "../enums/insightsEnums";

type InsightsModuleContextType = {
  selectedInsightsTab: InsightTypeTabs;
  handleChangeTab: (value: InsightTypeTabs) => void;
};

const InsightsModule = createContext<InsightsModuleContextType | null>(null);

export const useInsights = () =>
  useContext(InsightsModule) as InsightsModuleContextType;

export const InsightsProvider = ({ children }: any) => {
  const [selectedInsightsTab, setSelectedInsightsTab] =
    useState<InsightTypeTabs>(InsightTypeTabs.Talent);

  const handleChangeTab = (value: InsightTypeTabs) => {
    setSelectedInsightsTab(value);
  };

  return (
    <InsightsModule.Provider
      value={{
        selectedInsightsTab,
        handleChangeTab,
      }}
    >
      {children}
    </InsightsModule.Provider>
  );
};
