/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { TimerIcon } from "../../../../../../../../../assets/icons/Icons";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import UserProfileInfo from "../../../../../../../../../components/common/UserProfileInfo";
import {
  getInterviewState,
  TabSelect,
} from "../../../../../../../../../redux/slices/interviewSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import {
  BG_COLORS,
  BORDERS,
  BOX_SHADOW,
  COLORS,
} from "../../../../../../../../../theme";
import { dateFormatDDDDMMMMD } from "../../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import { getAuthState } from "../../../../../../../../../redux/slices/authSlice";
import { useClickOutSide } from "../../../../../../../../../utils/common";
import { useState } from "react";

const InterviewSummary = () => {
  const {
    nextTab,
    count,
    selectedDate,
    selectedSlot,
    createInterview,
    interviewer,
  } = useAppSelector(getInterviewState);
  const { userInfo } = useAppSelector(getAuthState);
  const [show, setShow] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const attendeesNames = createInterview.interviewers
    .map((preInterview) => {
      const findAttendees = interviewer.find(
        (interview) => interview.value === preInterview
      );
      if (findAttendees) {
        return findAttendees?.label;
      }

      return null;
    })
    .filter((interviewer) => interviewer && interviewer !== userInfo.email);
  const adminName = `${userInfo?.first_name ?? ""} ${
    userInfo?.last_name ?? ""
  }(You)`;
  return (
    <>
      <Stack
        sx={{
          px: 3,
          py: 2,
          bgcolor: BG_COLORS.GRAY,
          borderRadius: 2,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText fs={18} fw={600} text="Interview Summary" />
        </Stack>
        <UserProfileInfo />
        <Stack gap={"16px"} sx={{ pt: 2, pb: 2 }}>
          <AtsRowBetween>
            <>
              <AtsRow gap={"6px"}>
                <>
                  <AtsIcon
                    icon="icon-profile-2user"
                    color={COLORS.PRIMARY}
                    height="14px"
                    fs={14}
                  />
                  <AtsTitleText
                    fs={12}
                    fw={400}
                    textColor={COLORS.LIGHT_GRAY}
                    text={"Attendees"}
                  />
                </>
              </AtsRow>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  display: "flex",
                }}
              >
                {adminName}
                {"  "}
                {attendeesNames.length > 0 && (
                  <div
                    className=""
                    ref={domNode}
                    style={{
                      position: "relative",
                      marginLeft: "3px",
                    }}
                  >
                    <span
                      style={{
                        color: COLORS.PRIMARY,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setShow(!show)}
                    >{` +${attendeesNames.length} More`}</span>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        width: "max-content",
                      }}
                    >
                      <Collapse
                        in={show}
                        sx={{
                          boxShadow: BOX_SHADOW.GRAY,
                          borderRadius: "5px",
                          bgcolor: "white",
                        }}
                      >
                        <Stack
                          gap={"5px"}
                          sx={{
                            py: "10px",
                            px: "15px",
                            borderRadius: "5px",
                          }}
                        >
                          {attendeesNames.map((atten) => (
                            <AtsTitleText
                              key={atten}
                              fs={12}
                              fw={400}
                              textColor={COLORS.LIGHT_GRAY}
                              text={atten}
                            />
                          ))}
                        </Stack>
                      </Collapse>
                    </Box>
                  </div>
                )}
              </Typography>
              {/* <AtsTitleText fs={12} fw={400} text={value} /> */}
            </>
          </AtsRowBetween>
          <RenderTextWrap
            text="Duration"
            value={
              nextTab === TabSelect.ATENDEE_DETAILS ? "--" : `${count} Mins`
            }
            renderIcon={<TimerIcon />}
          />
          <RenderTextWrap
            text={"Date"}
            value={
              nextTab === TabSelect.ATENDEE_DETAILS
                ? "--"
                : dateFormatDDDDMMMMD(selectedDate)
            }
            renderIcon={
              <AtsIcon
                icon="icon-calendar-1"
                color={COLORS.PRIMARY}
                height="14px"
                fs={14}
              />
            }
          />
          <RenderTextWrap
            text={"Time"}
            value={selectedSlot.length ? selectedSlot : "--"}
            renderIcon={
              <AtsIcon
                icon="icon-clock"
                color={COLORS.PRIMARY}
                height="14px"
                fs={14}
              />
            }
          />
        </Stack>
      </Stack>
    </>
  );
};

export default InterviewSummary;

const RenderTextWrap = ({
  text,
  value,
  renderIcon,
}: {
  text: string;
  value: string;
  renderIcon: JSX.Element;
}) => {
  return (
    <AtsRowBetween>
      <>
        <AtsRow gap={"6px"}>
          <>
            {renderIcon}
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text={text}
            />
          </>
        </AtsRow>
        <AtsTitleText fs={12} fw={400} text={value} />
      </>
    </AtsRowBetween>
  );
};
