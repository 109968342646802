import { Box, IconButton } from "@mui/material";
import { BORDERS } from "../../theme";
import AtsRow from "../../components/AtsRow";
import AtsTitleText from "../../components/AtsTitleText";
import { SecuritySafe } from "iconsax-react";
import { Close } from "@mui/icons-material";
import { useContracts } from "../../providers/ContractsProvider";
import { SignatureStepEnum } from "../../enums/contractsEnums";
import SignatureOptions from "./components/SignatureOptions";
import SignatureBottomBox from "./components/SignatureBottomBox";
import SignatureFontStyleBox from "./components/SignatureFontStyleBox";
import SignatureUploadBox from "./components/SignatureUploadBox";
import SignatureDrawBox from "./components/SignatureDrawBox";
import SignatureCropBox from "./components/SignatureCropBox";

const AddSignature = () => {
  const {
    sigCanvas,
    selectedSignatureStep,
    toggleSignatureModal,
    setSelectedSignatureStep,
    onSign,
  } = useContracts();
  return (
    <>
      <Box
        sx={{
          px: "30px",
          py: "20px",
          borderBottom: BORDERS.GRAY,
          position: "relative",
        }}
      >
        <AtsRow gap={"10px"}>
          <>
            <AtsTitleText text={"Add your signature"} fs={18} fw={600} />
            <AtsRow
              gap={"8px"}
              sx={{
                bgcolor: "#bdeed2",
                px: "13px",
                py: "6px",
                borderRadius: "20px",
              }}
            >
              <>
                <AtsTitleText
                  text={"Legally binding"}
                  fs={14}
                  fw={400}
                  textColor="#000"
                />
                <SecuritySafe size={16} color="#000" />
              </>
            </AtsRow>
          </>
        </AtsRow>
        <IconButton
          onClick={toggleSignatureModal}
          sx={{ p: 1, position: "absolute", top: 10, right: 10, color: "#000" }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          px: "40px",
          pt: "29px",
          pb: "46px",
        }}
      >
        {selectedSignatureStep === SignatureStepEnum.SignatureFonts ? (
          <SignatureFontStyleBox />
        ) : selectedSignatureStep === SignatureStepEnum.SignatureUpload ? (
          <SignatureUploadBox />
        ) : selectedSignatureStep === SignatureStepEnum.SignatureDraw ? (
          <SignatureDrawBox />
        ) : selectedSignatureStep === SignatureStepEnum.SignatureCrop ? (
          <SignatureCropBox />
        ) : (
          <SignatureOptions />
        )}
      </Box>
      {selectedSignatureStep === SignatureStepEnum.SignatureFonts ? (
        <SignatureBottomBox
          backText="Back"
          nextText="Confirm"
          onBack={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
          onNext={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
        />
      ) : selectedSignatureStep === SignatureStepEnum.SignatureUpload ? (
        <SignatureBottomBox
          backText="Back"
          nextText="Confirm"
          onBack={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
          onNext={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
        />
      ) : selectedSignatureStep === SignatureStepEnum.SignatureDraw ? (
        <SignatureBottomBox
          backText="Back"
          nextText="Sign"
          onBack={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
          onNext={() => {
            onSign();
            toggleSignatureModal();
          }}
          //   isDisabled={sigCanvas}
        />
      ) : selectedSignatureStep === SignatureStepEnum.SignatureCrop ? (
        <SignatureBottomBox
          backText="Back"
          nextText="Confirm"
          onBack={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
          onNext={() =>
            setSelectedSignatureStep(SignatureStepEnum.SignatureOptions)
          }
        />
      ) : (
        <SignatureBottomBox
          backText="Cancel"
          nextText="Sign"
          onBack={toggleSignatureModal}
          onNext={() => {}}
        />
      )}
    </>
  );
};

export default AddSignature;
