import { ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import { Container, Stack } from "@mui/material";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import {
  CalenderActiveIcon,
  TimerIcon,
} from "../../../../../../../assets/icons/Icons";
import { Box } from "@mui/material";
import { useMakeAnOffer } from "../../../../../../../providers/MakeAnOfferProvider";
import { useRequestToConvert } from "../../../../../../../providers/RequestToConvert";
import { useAppDispatch } from "../../../../../../../redux/store";
import { setIsCandidateDetailsDrawerOpen } from "../../../../../../../redux/slices/submissionSlice";
import { setCondidateTabValue } from "../../../../../../../redux/slices/candidateSlice";

const RequestConvertOfferSent = () => {
  const dispatch = useAppDispatch();
  const {
    setIsCompleteRequest,
    setIsOfferDetails,
    setSelectedOfferTab,
    formattedCandidateDetails,
  } = useMakeAnOffer();
  const { setOpenRequestConvertDialog } = useRequestToConvert();
  return (
    <>
      <Stack
        sx={{
          mt: 2,
        }}
      >
        <Container maxWidth="sm">
          <Stack mt={7}>
            <Stack gap={"10px"} alignItems={"center"}>
              <Box>
                <img
                  width={45}
                  src={require("../../../../../../../assets/images/check.gif")}
                  alt="check"
                />
              </Box>
              <AtsTitleText
                text={"Success! Your Offer is sent.."}
                fs={16}
                fw={500}
              />
            </Stack>
            <Stack pt={2} gap={1}>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                sx={{ width: "100%" }}
              >
                <Stack
                  sx={{
                    borderRadius: 2,
                    bgcolor: BG_COLORS.GREEN,
                    px: 3,
                    py: 1,
                  }}
                >
                  <AtsTitleText
                    text={`You sent an offer to ${formattedCandidateDetails.name}`}
                    fs={16}
                    fw={600}
                    textColor={COLORS.GREEN}
                  />
                </Stack>
              </Stack>
              <Stack
                sx={{
                  border: BORDERS.GRAY,
                  borderRadius: 2,
                  px: 3,
                  py: 4,
                }}
              >
                <Stack gap={2}>
                  <AtsTitleText
                    text={"Offer Details"}
                    fs={14}
                    fw={600}
                    textColor={COLORS.LIGHT_GRAY}
                  />
                  <Stack direction={"row"}>
                    <Stack gap={2} sx={{ flex: 1 }}>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsTitleText
                          text={formattedCandidateDetails.hourPerWeek}
                          fs={14}
                          fw={500}
                          textColor="#000"
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <CalenderActiveIcon />
                        <AtsTitleText
                          text={formattedCandidateDetails.duration}
                          fs={14}
                          fw={500}
                          textColor="#000"
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsTitleText
                          text={formattedCandidateDetails.rate}
                          fs={14}
                          fw={500}
                          textColor="#000"
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      gap={2}
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        borderLeft: BORDERS.GRAY,
                      }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "center" }}
                      >
                        <img
                          src={formattedCandidateDetails.image}
                          alt="Nvidia Logo"
                          style={{
                            height: "auto",
                            marginRight: "10px",
                            width: "66px",
                            borderRadius: "100%",
                          }}
                        />
                      </Stack>
                      <AtsTitleText
                        text={formattedCandidateDetails.name}
                        fs={14}
                        fw={700}
                      />
                    </Stack>
                  </Stack>
                  <Stack gap={1} pt={2}>
                    <AtsTitleText
                      text={"Track Offer"}
                      fs={14}
                      fw={600}
                      textColor={COLORS.LIGHT_GRAY}
                    />
                    <Stack direction={"row"} gap={1}>
                      <AtsTitleText
                        text={
                          "Your offer has been sent. They’ll review your order and respond in a maximum of 24 hours."
                        }
                        fs={12}
                        fw={500}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                sx={{ width: "100%" }}
                pt={2}
              >
                <AtsRoundedButton
                  text="View your Offer Details"
                  endIcon={<ArrowForwardIos />}
                  buttonWidth={"70%"}
                  onClick={() => {
                    setIsOfferDetails(true);
                    setIsCompleteRequest(false);
                    setOpenRequestConvertDialog(false);
                    dispatch(setIsCandidateDetailsDrawerOpen(true));
                    dispatch(setCondidateTabValue(5));
                    setSelectedOfferTab(2);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default RequestConvertOfferSent;
