import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsBackLink from "../../../../components/AtsBackLink";
import AtsChip from "../../../../components/AtsChip";
import AtsTitleText from "../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../theme";
import FontUtil from "../../../../utils/FontUtil";
import TaskCard from "../TaskCard";
import { toLowerCase } from "../../../../utils/stringUtils";

interface Props {
  isOpen: boolean;
}

interface TaskProps {
  status: string;
  task: string;
  date: string;
  day: string;
  id: number;
}

const TASKS: TaskProps[] = [
  {
    status: "PENDING",
    task: "Screening of current application",
    date: "May 27, 2024 - 8:00 AM",
    day: "TODAY",
    id: 1,
  },
  {
    status: "CLOSED",
    task: "Sr. Java Developer Offer latter review",
    date: "May 27, 2024 - 8:00 AM",
    day: "TODAY",
    id: 2,
  },
  {
    status: "PENDING",
    task: "Drupal interview",
    date: "May 27, 2024 - 8:00 AM",
    day: "TODAY",
    id: 3,
  },
  {
    status: "PENDING",
    task: "Jobs design in react app",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 4,
  },
  {
    status: "CLOSED",
    task: "Report card in colleagues app",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 5,
  },
  {
    status: "PENDING",
    task: "Sr. Dot NET Developer Offer latter review",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 6,
  },
  {
    status: "PENDING",
    task: "Screening of current application",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 7,
  },
  {
    status: "CLOSED",
    task: "Screening of current application",
    date: "May 27, 2024 - 8:00 AM",
    day: "TOMORROW",
    id: 8,
  },
];

const TaskSidebar = ({ isOpen }: Props) => {
  const { font10 } = FontUtil();
  const [taskList, setTaskList] = useState<TaskProps[]>([]);
  const [activeStatus, setActiveStatus] = useState<string>("PENDING");
  const [allTasks, setAllTasks] = useState<TaskProps[]>(TASKS);
  const [closingTaskId, setClosingTaskId] = useState<number | null>(null);

  const onClickStatus = (status: string) => {
    setActiveStatus(status);
    const taskFilter = allTasks.filter((task) => task.status === status);
    setTaskList(status === "ALL" ? allTasks : taskFilter);
  };

  const getCount = (status: string) => {
    if (status === "ALL") return allTasks.length;
    return allTasks.filter((task) => task.status === status).length;
  };

  const statusCount = (
    count: number | string,
    status: string,
    value: string
  ) => {
    const isActive = activeStatus === value;
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={0.5}
        sx={{
          height: 22,
          borderRight: value !== "ALL" ? BORDERS.GRAY : "none",
          cursor: "pointer",
          ...(value === "PENDING" ? { pr: 1.3 } : { px: 1.3 }),
        }}
        onClick={() => onClickStatus(value)}
      >
        <AtsTitleText
          text={status}
          fs={10}
          fw={isActive ? 700 : 400}
          textColor={isActive ? COLORS.BLACK : COLORS.LIGHT_GRAY}
        />
        <AtsChip
          text={count}
          sx={{
            height: "17px",
            bgcolor: isActive ? "var(--primary)" : "white",
            color: isActive ? "white" : "balck",
            ...font10,
          }}
        />
      </Stack>
    );
  };

  const onCloseTask = (id: number) => {
    setClosingTaskId(id);
    setTimeout(() => {
      const updatedTasks = allTasks.map((task) =>
        task.id === id ? { ...task, status: "CLOSED" } : task
      );
      setAllTasks(updatedTasks);
      setClosingTaskId(null); // Reset the closing state
      const taskFilter = updatedTasks.filter(
        (task) => activeStatus === "ALL" || task.status === activeStatus
      );
      setTaskList(taskFilter);
    }, 300);
  };

  useEffect(() => {
    onClickStatus(activeStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTasks]);

  const renderTaskCard = (task: TaskProps) => {
    return (
      <Stack
        key={task.id}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...(closingTaskId === task.id && {
            transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
          }),
          transform: closingTaskId === task.id ? "translateX(100%)" : "none",
          opacity: closingTaskId === task.id ? 0 : 1,
        }}
      >
        <TaskCard
          index={task.id}
          status={task.status}
          taskDes={task.task}
          dueTime={task.date}
          onClose={onCloseTask}
        />
      </Stack>
    );
  };

  const renderTaskDayTitle = (title: string) => {
    return taskList.filter((task) => task.day === title).length ? (
      <Stack sx={{ pt: title === "TOMORROW" ? 4 : 0 }} px={isOpen ? 5 : 1}>
        <AtsTitleText
          text={toLowerCase(title)}
          fs={12}
          fw={700}
          textColor={"#98999a"}
        />
      </Stack>
    ) : (
      ""
    );
  };

  return (
    <>
      <Box
        gap={1}
        pt={"20px"}
        sx={{
          height: "100%",
        }}
      >
        <Stack
          sx={{ pb: 2, pt: 1 }}
          px={isOpen ? 5 : 1}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <AtsTitleText fs={24} fw={600} text="Reminders" />
        </Stack>
        <Stack direction={"row"} pb={2} px={isOpen ? 5 : 1}>
          {statusCount(getCount("PENDING"), "Pending", "PENDING")}
          {statusCount(getCount("CLOSED"), "Closed", "CLOSED")}
          {statusCount(getCount("ALL"), "All", "ALL")}
        </Stack>
        <Stack
          className="tasksBar"
          gap={2}
          sx={{
            pt: 2,
            pb: 1,
            overflow: "auto",
            height: "calc(100% - 183px)",
          }}
        >
          {renderTaskDayTitle("TODAY")}
          {taskList
            .filter((task) => task.day === "TODAY")
            .map((task, index) => renderTaskCard(task))}
          {renderTaskDayTitle("TOMORROW")}
          {taskList
            .filter((task) => task.day === "TOMORROW")
            .map((task, index) => renderTaskCard(task))}
        </Stack>
        <Stack direction={"row"} justifyContent={"center"} py={2}>
          <AtsBackLink text="Show all" isRightIcon />
        </Stack>
      </Box>
    </>
  );
};

export default TaskSidebar;
