import React from "react";
import AtsRow from "../../../components/AtsRow";
import { Stack } from "@mui/material";
import { Box } from "iconsax-react";
import AtsTitleText from "../../../components/AtsTitleText";
import CheckGif from "../../../assets/images/check.gif";
import { TickCircle } from "iconsax-react";
import { COLORS } from "../../../theme";

const Submit = () => {
  return (
    <AtsRow
      justifyContent={"center"}
      sx={{
        height: "100%",
      }}
    >
      <Stack
        gap={"20px"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TickCircle variant="Bold" size={35} color={COLORS.GREEN} />
        <AtsTitleText
          text={"Your registration process is completed"}
          fs={24}
          fw={400}
          textColor="#000"
        />
      </Stack>
    </AtsRow>
  );
};

export default Submit;
