import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { Typography } from "@mui/material";
import ProfileModal from "./ProfileModal";
import AtsRow from "../../../components/AtsRow";
import { Add, CloseCircle, SearchNormal1 } from "iconsax-react";
import AtsTextArea from "../../../components/AtsTextArea";
import {
  interestes,
  useCreateAccount,
} from "../../../providers/CreateAccountProvider";
import AtsBackLink from "../../../components/AtsBackLink";
import AtsCheckbox from "../../../components/AtsCheckbox";

const Interestes = () => {
  const [open, setOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [search, setSearch] = useState("");
  const { selectedInterestes, setSelectedInterestes } = useCreateAccount();

  const toggelOpenClose = () => {
    setOpen(!open);
  };

  const handleChange = (value: string) => {
    if (selectedInterestes.includes(value)) {
      const filterInterestes = selectedInterestes.filter(
        (preVal) => preVal !== value
      );
      setSelectedInterestes(filterInterestes);
    } else {
      setSelectedInterestes([...selectedInterestes, value]);
    }
  };

  const filterValue = interestes?.filter((val) => {
    if (search === "") {
      return val;
    } else if (val.title.toLowerCase().includes(search.toLowerCase())) {
      return val;
    }
    return false;
  });
  return (
    <>
      <Stack gap={"24px"}>
        <Stack gap={"13px"}>
          <AtsTitleText
            text={"What you're into"}
            fs={18}
            fw={600}
            textColor="#000"
          />
          <AtsTitleText
            text={
              "Find common ground with other guests and Hosts by adding interests to your profile."
            }
            fs={16}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"20"}
          flexWrap={"wrap"}
        >
          <AtsRow
            sx={{
              width: 90,
              height: 39,
              borderRadius: "40px",
              border: "1px dashed #3366cc",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={toggelOpenClose}
          >
            <Add color={COLORS.PRIMARY} />
          </AtsRow>
        </Stack>
        <Typography
          sx={{
            textDecoration: "underline",
            color: "black",
            fontSize: 14,
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          Add interests
        </Typography>
      </Stack>
      <ProfileModal
        width={536}
        open={open}
        header={"What are you into?"}
        subText={
          "Pick some interests that you enjoy and that you want to show on your profile."
        }
        toggelOpenClose={toggelOpenClose}
        renderBottomText={
          <Typography
            sx={{
              textDecoration: "underline",
              fontSize: 14,
              fontWeight: 600,
              color: "#000",
            }}
          >
            {`${selectedInterestes.length}/${interestes.length} selected`}
          </Typography>
        }
        renderConponents={
          <Stack gap={"30px"}>
            {showAll ? (
              <>
                <AtsRow>
                  <AtsBackLink text="Back" onClick={() => setShowAll(false)} />
                </AtsRow>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  minHeight={35}
                  sx={{
                    px: "16px",
                    border: BORDERS.GRAY,
                    borderRadius: "38px",
                  }}
                >
                  <SearchNormal1 size={16} />
                  <input
                    type="text"
                    className="customInput"
                    placeholder={"Search for Interests"}
                    style={{
                      width: "100%",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <CloseCircle variant="Bold" size={16} />
                </Stack>
                <Stack sx={{ maxHeight: 272, overflow: "auto" }}>
                  {filterValue.map((value, index) => (
                    <AtsRow
                      gap={"20px"}
                      key={index}
                      sx={{
                        py: "20px",
                        borderBottom: BORDERS.GRAY,
                      }}
                    >
                      <>
                        <Box>
                          <AtsCheckbox
                            checked={selectedInterestes.includes(value.title)}
                            onChange={(e) => handleChange(value.title)}
                          />
                        </Box>
                        <AtsRow gap={"10px"}>
                          <>
                            {value.icon}
                            <AtsTitleText
                              text={value.title}
                              fs={18}
                              fw={400}
                              textColor={COLORS.DARK_GRAY}
                            />
                          </>
                        </AtsRow>
                      </>
                    </AtsRow>
                  ))}
                </Stack>
              </>
            ) : (
              <>
                <AtsRow gap={"11px"} flexWrap={"wrap"}>
                  <>
                    {interestes.slice(0, 10).map((item, index) => (
                      <AtsRow
                        key={index}
                        gap={"8px"}
                        sx={{
                          borderRadius: "50px",
                          border: selectedInterestes.includes(item.title)
                            ? BORDERS.PRIMARY
                            : BORDERS.GRAY,
                          px: "16px",
                          py: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleChange(item.title)}
                      >
                        <>
                          {" "}
                          {selectedInterestes.includes(item.title)
                            ? item.activeIcon
                            : item.icon}
                          <AtsTitleText
                            text={item.title}
                            fs={12}
                            fw={400}
                            textColor={
                              selectedInterestes.includes(item.title)
                                ? COLORS.PRIMARY
                                : "#000"
                            }
                          />
                        </>
                      </AtsRow>
                    ))}
                  </>
                </AtsRow>
                <AtsRow>
                  <AtsTitleText
                    text={"Show all"}
                    textColor={COLORS.PRIMARY}
                    isLink
                    isUnderline
                    fs={14}
                    fw={500}
                    onClick={() => setShowAll(true)}
                  />
                </AtsRow>
              </>
            )}
          </Stack>
        }
        onSave={() => {}}
      />
    </>
  );
};

export default Interestes;
