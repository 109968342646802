import moment from "moment";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectService } from "../Api/projectServices";
import { TimesheetServices } from "../Api/timesheetServices";
import { ProjectTimesheetTabsEnum } from "../enums/projectEnums";
import {
  CandidateListResponse,
  ContractorByProjectResponse,
} from "../types/projectTypes";
import {
  CandidateTimelogParams,
  CandidateTimelogResponse,
  DisputedData,
  DisputeTimesheetRequest,
  TimeEntriesOfCandidateParams,
  TimelogEntriesResponse,
} from "../types/timesheetTypes";
import { getDateFormatMMMDYYYYY } from "../utils/dateUtils";
import { setIsDisputed } from "../redux/slices/contractorTimesheetSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
// import { toast } from "react-toastify";
import { getContractorDetailsState } from "../redux/slices/contractorSlice";
import { OnboardingCandidatesListResponse } from "../types/onboardingTypes";
import toast from "react-hot-toast";

export interface FidURLProps {
  fid: string | null;
  url: string;
}

export interface EvidencesProps {
  evidences: FidURLProps[];
  note: string;
}
export type AllSelectedContratorTypes =
  | ContractorByProjectResponse
  | CandidateListResponse
  | OnboardingCandidatesListResponse
  | null;

type TimesheetModuleContextType = {
  pdfRef: any;
  loading: boolean;
  timelogLoading: boolean;
  timeEntryLoading: boolean;
  createDisputeLoading: boolean;
  timelogApproveLoading: boolean;
  dateText: string;
  totalWorkingHours: number;
  totalDisputedHours: number;
  totalApprovedHours: number;
  inputValue: string;
  selectedStatus: ProjectTimesheetTabsEnum;
  date: Date;
  contractorsTimesheet: ContractorByProjectResponse[];
  contractorTimeEntries: TimelogEntriesResponse[];
  selectedTimelogOfContractorDetails: TimelogEntriesResponse[];
  selectedContractorTimesheet: ContractorByProjectResponse | null;
  disputedTimesheet: DisputedData | null;
  candidateTimelog: CandidateTimelogResponse[];
  selectedTimelogOfContractor: string[];
  evidencesWithNote: EvidencesProps;

  setSelectedStatus: React.Dispatch<
    React.SetStateAction<ProjectTimesheetTabsEnum>
  >;
  setCandidateTimelog: React.Dispatch<
    React.SetStateAction<CandidateTimelogResponse[]>
  >;
  setSelectedContractorTimesheet: React.Dispatch<
    React.SetStateAction<AllSelectedContratorTypes>
  >;
  setSelectedTimelogOfContractor: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  setSelectedTimelogOfContractorDetails: React.Dispatch<
    React.SetStateAction<TimelogEntriesResponse[]>
  >;
  setEvidencesWithNote: React.Dispatch<React.SetStateAction<EvidencesProps>>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;

  getContractorsByProjectId: (id: string) => void;
  getTimeEntriesOfContractor: (email: string, id: string) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  onClearDispute: () => void;
  onApproveTimesheet: (nids: number[], id: string) => void;
  onResolveTimesheet: (nid: number, id: string) => void;
  createDispute: (request: DisputeTimesheetRequest, project_id: string) => void;
};

const TimesheetModule = createContext<TimesheetModuleContextType | null>(null);

export const useTimesheet = () =>
  useContext(TimesheetModule) as TimesheetModuleContextType;

export const TimesheetProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [timelogLoading, setTimelogLoading] = useState(false);
  const [timeEntryLoading, setTimeEntryLoading] = useState(false);
  const [createDisputeLoading, setCreateDisputeLoading] = useState(false);
  const [timelogApproveLoading, setTimelogApproveLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] =
    useState<ProjectTimesheetTabsEnum>(ProjectTimesheetTabsEnum.Pending);
  const [date, setDate] = useState<Date>(moment(new Date()).toDate());
  const [contractorsTimesheet, setContractorsTimesheet] = useState<
    ContractorByProjectResponse[]
  >([]);
  const [selectedContractorTimesheet, setSelectedContractorTimesheet] =
    useState<ContractorByProjectResponse | null>(null);
  const [candidateTimelog, setCandidateTimelog] = useState<
    CandidateTimelogResponse[]
  >([]);
  const [contractorTimeEntries, setContractorTimeEntries] = useState<
    TimelogEntriesResponse[]
  >([]);
  const [
    selectedTimelogOfContractorDetails,
    setSelectedTimelogOfContractorDetails,
  ] = useState<TimelogEntriesResponse[]>([]);
  const [selectedTimelogOfContractor, setSelectedTimelogOfContractor] =
    useState<string[]>([]);
  const [totalWorkingHours, setTotalWorkingHours] = useState<number>(0);
  const [totalDisputedHours, setTotalDisputedHours] = useState<number>(0);
  const [totalApprovedHours, setTotalApprovedHours] = useState<number>(0);
  const [evidencesWithNote, setEvidencesWithNote] = useState<EvidencesProps>({
    evidences: [],
    note: "",
  });

  const [disputedTimesheet, setDisputedTimesheet] =
    useState<DisputedData | null>(null);

  const onClearDispute = () => {
    setEvidencesWithNote({
      evidences: [],
      note: "",
    });
  };

  const onPrevClick = () => {
    setDate(moment(date).subtract(1, "w").toDate());
  };

  const onNextClick = () => {
    setDate(moment(date).add(1, "w").toDate());
  };

  const convertTimeStamp = (value: string) => {
    const date = new Date(value);
    const timestamp = Math.floor(date.getTime() / 1000);
    return timestamp.toString();
  };

  const dateText = useMemo(() => {
    const from = moment(date)?.startOf("week").toString();
    setStartDate(convertTimeStamp(from));
    const to = moment(date)?.endOf("week").toString();
    setEndDate(convertTimeStamp(to));
    return `${getDateFormatMMMDYYYYY(from)} - ${getDateFormatMMMDYYYYY(to)}`;
  }, [date]);

  const createDispute = async (
    request: DisputeTimesheetRequest,
    project_id: string
  ) => {
    setCreateDisputeLoading(true);
    try {
      const response = await TimesheetServices.disputeTimesheet(request);
      toast.success(response.message);
      setDisputedTimesheet(response.data);

      dispatch(setIsDisputed(true));
      getTimeEntriesOfContractor(selectedContractorTimesheet.email, project_id);
    } catch (error) {
      console.error(error);
    } finally {
      setCreateDisputeLoading(false);
    }
  };

  const getTimeEntriesOfContractor = async (email: string, id: string) => {
    setTimeEntryLoading(true);
    try {
      const request = {
        email,
        project_id: id,
        start_date: startDate,
        end_date: endDate,
      } as TimeEntriesOfCandidateParams;
      const response = await TimesheetServices.getTimeEntriesOfCandidate(
        request
      );
      if (response.length) {
        const timelogData = response.reduce((acc, curr) => {
          let existingEntry = acc.find((item) => item.nid === curr.nid);

          if (existingEntry) {
            existingEntry.time_logs.push(curr);
            existingEntry.working_hours = existingEntry.time_logs
              .reduce((total, log) => total + parseFloat(log.working_hours), 0)
              .toFixed(2);
          } else {
            acc.push({
              ...curr,
              working_hours: parseFloat(curr.working_hours).toFixed(2),
              time_logs: [curr],
            });
          }

          return acc;
        }, []);

        const working_hours = timelogData.reduce(
          (total, log) => total + parseFloat(log.working_hours),
          0
        );
        const approved_hours = timelogData
          .filter((log) => log.approved_time.length)
          .reduce((total, log) => total + parseFloat(log.approved_time), 0);
        const disputed_hrs = timelogData
          .filter((log) => log.disputed_time.length)
          .reduce((total, log) => total + parseFloat(log.disputed_time), 0);
        setTotalWorkingHours(working_hours);
        setTotalDisputedHours(disputed_hrs);
        setTotalApprovedHours(approved_hours);
        setContractorTimeEntries(timelogData);
      } else {
        setTotalWorkingHours(0);
        setTotalDisputedHours(0);
        setTotalApprovedHours(0);
        setContractorTimeEntries([]);
      }
      setTimeEntryLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeEntryLoading(false);
    }
  };

  const getTimelogForContractor = async (emails: string, id: string) => {
    setTimelogLoading(true);
    try {
      const request = {
        emails: emails,
        project_id: id,
        start_date: startDate,
        end_date: endDate,
        status: selectedStatus,
      } as CandidateTimelogParams;
      const response = await TimesheetServices.getCandidateTimelog(request);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setTimelogLoading(false);
    }
  };

  const getContractorsByProjectId = async (id: string) => {
    setLoading(true);
    try {
      const body = {
        ...(inputValue.length && { name: inputValue }),
      };
      const response = await ProjectService.contractorListByProjectId(id, body);
      setContractorsTimesheet(response.results);
      setLoading(false);
      if (response.results) {
        const emails = response.results.map((res) => res.email).join(",");
        if (emails && emails.length) {
          const timeLog = await getTimelogForContractor(emails, id);
          setCandidateTimelog(timeLog);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onApproveTimesheet = async (nids: number[], id: string) => {
    setTimelogApproveLoading(true);
    try {
      await TimesheetServices.approveTimelog({ nids });
      await getContractorsByProjectId(id);
      await getTimeEntriesOfContractor(selectedContractorTimesheet.email, id);
    } catch (error) {
      console.error(error);
      setTimelogApproveLoading(false);
    } finally {
      setTimelogApproveLoading(false);
    }
  };
  const onResolveTimesheet = async (nid: number, id: string) => {
    setTimelogApproveLoading(true);
    try {
      await TimesheetServices.resolveDispute({ nid });
      await getContractorsByProjectId(id);
      await getTimeEntriesOfContractor(selectedContractorTimesheet.email, id);
    } catch (error) {
      console.error(error);
      setTimelogApproveLoading(false);
    } finally {
      setTimelogApproveLoading(false);
    }
  };

  return (
    <TimesheetModule.Provider
      value={{
        pdfRef,
        loading,
        timelogLoading,
        timeEntryLoading,
        createDisputeLoading,
        timelogApproveLoading,
        date,
        dateText,
        selectedStatus,
        inputValue,
        contractorsTimesheet,
        contractorTimeEntries,
        candidateTimelog,
        selectedContractorTimesheet,
        selectedTimelogOfContractor,
        totalDisputedHours,
        totalWorkingHours,
        totalApprovedHours,
        selectedTimelogOfContractorDetails,
        evidencesWithNote,
        disputedTimesheet,

        setSelectedStatus,
        setSelectedTimelogOfContractorDetails,
        setCandidateTimelog,
        setSelectedTimelogOfContractor,
        setSelectedContractorTimesheet,
        setEvidencesWithNote,
        setInputValue,
        getContractorsByProjectId,
        getTimeEntriesOfContractor,
        onNextClick,
        onPrevClick,
        onClearDispute,
        createDispute,
        onApproveTimesheet,
        onResolveTimesheet,
      }}
    >
      {children}
    </TimesheetModule.Provider>
  );
};
