import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectService } from "../../../../../../Api/projectServices";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import {
  getContractorDetailsState,
  setSelectedContractor,
} from "../../../../../../redux/slices/contractorSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../theme";
import {
  ContractorRoleResponse,
  RolesForProjectParams,
  RolesForProjectResponse,
} from "../../../../../../types/projectTypes";
import ContractorsActiveList from "./modules/ContractorsActiveList";
import RolesForProject from "./modules/RolesForProject";
import { ProjectContractorsStatusEnum } from "../../../../../../enums/projectEnums";

const Contractors = () => {
  const dispatch = useAppDispatch();
  const { selectedContractor } = useAppSelector(getContractorDetailsState);
  const { id } = useParams();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("xxl"));
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [rolesList, setRolesList] = useState<ContractorRoleResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight ===
      event.target.scrollHeight
    ) {
      if (totalItem !== rolesList.length) {
        setPage(page + 1);
      }
    }
  };
  const getRoles = async () => {
    try {
      setLoading(true);
      const requestObj = {
        project_id: id,
        ...(search.length && { title: search }),
      } as RolesForProjectParams;
      const response = await ProjectService.getRolesForProject(requestObj);
      if (response.results.length) {
        if (typeof response.pager.count === "number") {
          setTotalItem(response.pager.count);
        } else {
          setTotalItem(Number(response.pager.count));
        }
        if (page === 0) {
          setRolesList(response.results);
          Object.keys(selectedContractor).length === 0 &&
            dispatch(setSelectedContractor(response.results[0]));
        } else {
          setRolesList((prevList) => [...prevList, ...response.results]);
        }
      } else {
        setTotalItem(0);
        dispatch(setSelectedContractor({}));
        setRolesList([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRoles();
  }, [page, debouncedSearch]);
  return (
    <>
      <Stack mb={"20px"}>
        <AtsTitleText
          fs={18}
          fw={600}
          text={`${rolesList?.length} Contractors`}
        />
      </Stack>

      <Stack
        sx={{
          height: "calc(100% - 51px)",
        }}
      >
        <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
          <Box
            sx={{
              width: isLarge ? 280 : 260,
              height: "100%",
            }}
          >
            <div
              // ref={jobListRef}
              // onScroll={loading ? () => {} : loadMoreItems}
              className="scrollHide"
              style={{
                overflowX: "scroll",
                height: "100%",
                width: "100%",
              }}
            >
              <Stack
                gap={"13px"}
                sx={{
                  // height: "100%",
                  pr: 2,
                  pb: "10px",
                }}
              >
                <Stack direction={"row"} gap={"25px"} alignItems={"center"}>
                  <Stack
                    direction={"row"}
                    gap={2}
                    alignItems={"center"}
                    sx={{
                      width: 250,
                      // minHeight: 28,
                    }}
                  >
                    <Stack sx={{ width: "40%" }}>
                      <AtsTitleText
                        text={`${totalItem ?? 0} Roles`}
                        fs={14}
                        fw={600}
                        textColor={COLORS.BLACK}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      width={"60%"}
                    >
                      <AnimatePresence>
                        {showInput ? (
                          <Stack
                            direction={"row"}
                            gap={1}
                            sx={{
                              width: "100%",
                              borderRadius: "20px",
                              border: BORDERS.GRAY,
                              bgcolor: "white",
                              pl: 1,
                              // height: 25,
                            }}
                          >
                            <input
                              type="text"
                              className="customInput"
                              style={{
                                width: "80%",
                              }}
                              value={search}
                              onChange={(e) => {
                                setPage(0);
                                setSearch(e.target.value);
                              }}
                            />
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              // mt={0.5}
                              pl={2}
                              sx={{ cursor: "pointer", width: "20%" }}
                              onClick={() => setShowInput(false)}
                            >
                              <AtsIcon icon="icon-search-normal-1" />
                            </Stack>
                          </Stack>
                        ) : (
                          <Stack
                            mt={0.5}
                            pl={2}
                            sx={{ cursor: "pointer", width: "15%" }}
                            onClick={() => setShowInput(true)}
                          >
                            <AtsIcon icon="icon-search-normal-1" />
                          </Stack>
                        )}
                      </AnimatePresence>
                    </Stack>
                  </Stack>
                </Stack>
                {rolesList.map((role) => (
                  <RolesForProject
                    roleData={role}
                    selectedRole={selectedContractor}
                    onSelect={() => dispatch(setSelectedContractor(role))}
                    loading={loading}
                  />
                ))}
              </Stack>
            </div>

            {/* {fetchJobList} */}
          </Box>
          <Stack
            gap={"40px"}
            className="scrollHide"
            sx={{
              overflowX: "scroll",
              height: "100%",
              flex: 1,
            }}
          >
            <ContractorsActiveList
              status={ProjectContractorsStatusEnum.ACTIVE}
            />
            <Stack gap={"13px"}>
              <ContractorsActiveList
                status={ProjectContractorsStatusEnum.INACTIVE}
              />
            </Stack>
          </Stack>
          {/* {jobDetailsComponent} */}
        </Stack>
      </Stack>
    </>
  );
};

export default Contractors;
