import { Box, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SubmissionService } from "../../../../../../../../Api/submissionServices";
import OpenLink from "../../../../../../../../assets/images/openLink.png";
import PdfIcon from "../../../../../../../../assets/images/pdfIcon.png";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { getSubmissionState } from "../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import { AssessmentsResponse } from "../../../../../../../../types/submissionTypes";

const AssessmentsTab = () => {
  const { candidateDetails } = useAppSelector(getSubmissionState);
  const [loading, setLoading] = useState<boolean>(false);
  const [assessments, setAssessments] = useState<AssessmentsResponse[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const fetchMoreData = () => {
    if (totalItem !== assessments.length) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getAssessment = async () => {
    setLoading(true);
    try {
      const response = await SubmissionService.getAssessmentsOfCandidate({
        candidate_id: candidateDetails?.id,
      });
      setAssessments(response.results);

      if (typeof response.pager.count === "number") {
        setTotalItem(response.pager.count);
      } else {
        setTotalItem(Number(response.pager.count));
      }
      if (page === 0) {
        setAssessments(response.results);
      } else {
        setAssessments([...assessments, ...response.results]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssessment();
  }, [page]);

  return (
    <Stack
      gap={1}
      sx={{
        pb: 3,
        height: "100%",
      }}
    >
      <Stack pb={2}>
        <AtsTitleText fs={22} fw={600} textColor="#000" text={"Assessments"} />
      </Stack>
      <Stack
        gap={"16px"}
        className="scrollHide"
        id="scrollableDiv"
        sx={{
          height: "calc(100% - 42px)",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={assessments.length}
          next={fetchMoreData}
          hasMore={assessments.length < totalItem}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {loading
            ? Array.from(new Array(4)).map((_, index) => (
                <Stack key={index} direction={"row"} alignItems={"flex-start"}>
                  <Stack direction={"row"} gap={"21px"} sx={{ flex: 1 }}>
                    <Skeleton variant="rounded" width={54} height={54} />
                    <Stack gap={"10px"}>
                      <Skeleton variant="text" width={100} height={30} />
                      <Skeleton variant="text" width={50} height={25} />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <Skeleton variant="text" width={50} height={30} />
                  </Stack>
                </Stack>
              ))
            : assessments.length > 0 &&
              assessments.map((assessment) => (
                <Stack
                  sx={{
                    position: "relative",
                    height: "auto",
                  }}
                  key={assessment.title}
                >
                  <Stack direction={"row"} alignItems={"flex-start"}>
                    <Stack direction={"row"} gap={"21px"} sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          border: BORDERS.GRAY,
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "52px",
                          height: "52px",
                        }}
                      >
                        <img
                          src={PdfIcon}
                          alt="Nvidia Logo"
                          style={{ width: 21 }}
                        />
                      </Box>
                      <Stack gap={"10px"}>
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={assessment.title}
                        />
                        <AtsTitleText
                          fs={14}
                          fw={400}
                          textColor={COLORS.DARK_GRAY}
                          text={`${0} KB`}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={1}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ pr: 2 }}
                      >
                        <a
                          style={{
                            textDecoration: "none",
                          }}
                          href={assessment?.assessment_file?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <img
                              src={OpenLink}
                              alt="Nvidia Logo"
                              style={{ width: 18 }}
                            />
                            <AtsTitleText
                              fs={14}
                              fw={600}
                              textColor={COLORS.PRIMARY}
                              text={"Open Link"}
                            />
                          </Stack>
                        </a>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
        </InfiniteScroll>
      </Stack>
    </Stack>
  );
};

export default AssessmentsTab;
