import React, { useEffect, useState } from "react";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import { IColumnProps } from "../../../../../../types/commonType";
import {
  CandidateListResponse,
  TeamMemberProps,
} from "../../../../../../types/projectTypes";
import { Stack } from "@mui/material";
import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../theme";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import { FilterIcon } from "../../../../../../assets/icons/Icons";
import { ProjectService } from "../../../../../../Api/projectServices";
import { useParams } from "react-router-dom";

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Team Member",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

interface IProps {
  teamMember: TeamMemberProps[];
  loading: boolean;
}

const ActiveTeamMemberList = ({ teamMember, loading }: IProps) => {
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const customizeRow = (
    column: IColumnProps,
    contractor: TeamMemberProps,
    index: number
  ) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  padding: 10,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                  <Stack>
                    <AccountCircle sx={{ fontSize: 70, color: "#6d6d6d" }} />
                  </Stack>
                  <Stack gap={1}>
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      textColor="#000"
                      text={`${contractor.first_name} ${contractor.last_name}`}
                    />

                    <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={""}
                    />
                    <AtsTitleText fs={12} fw={500} text={"Java Team"} />
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={contractor[column.key]}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton px={0.6}>
              <AtsIcon icon="icon-eye" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = teamMember.map(
      (value: TeamMemberProps, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                height: "68px",
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
                cursor: "pointer",
                backgroundColor: value.uid === hoveredRow ? "#f2f6ff" : "white",
              }}
              onMouseEnter={() => setHoveredRow(value.uid)}
              onMouseLeave={() => {
                setHoveredRow(null);
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) =>
                customizeRow(val, value, index)
              )}
            </tr>
          </React.Fragment>
        );
      }
    );
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px 5px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <AtsDataTable
        // isTopBorder
        data={teamMember}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={loading}
      />
    </>
  );
};

export default ActiveTeamMemberList;
