import { Box, Stack } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";

interface IProps {
  icon: string;
  number: string | number;
  title: string;
}

const TotalNumberCard = ({ icon, number, title }: IProps) => {
  return (
    <Box
      sx={{
        p: "20px",
        border: BORDERS.GRAY,
        borderRadius: "10px",
        bgcolor: "white",
      }}
    >
      <AtsRow gap={"10px"}>
        <>
          <Box
            sx={{
              width: 44,
              height: 44,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "7px",
              bgcolor: BG_COLORS.PRIMARY,
            }}
          >
            <img src={icon} alt="" />
          </Box>
          <Stack gap={"5px"}>
            <AtsTitleText text={number} fs={18} fw={600} />
            <AtsTitleText text={title} fs={14} fw={400} />
          </Stack>
        </>
      </AtsRow>
    </Box>
  );
};

export default TotalNumberCard;
