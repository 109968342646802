import { ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SubmissionService } from "../../Api/submissionServices";
import { LogoutIcon } from "../../assets/icons/Icons";
import { getAuthState, setIsAuth } from "../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { RoutesProps, USER_ROUTES } from "../../router/ATS_routes";
import { useClickOutSide } from "../../utils/common";
import "../css/header.css";
import AtsRow from "../../components/AtsRow";

const UserInfo = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const { pathname } = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  const onLogout = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      await SubmissionService.logOut({ token });
      localStorage.clear();
      dispatch(setIsAuth(false));
    } catch (error) {}
  };
  return (
    <>
      <Box ref={domNode} sx={{ position: "relative" }}>
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", cursor: "pointer" }}
          gap={1}
          onClick={() => setShow(!show)}
        >
          <Box
            sx={{
              height: "40px",
              width: "40px",
              border: "1px solid #dadada",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          ></Box>
          <ExpandMore />
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            right: "0px",
            top: "50px",
            zIndex: 9999,
            width: "250px",
          }}
        >
          <Collapse
            sx={{
              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
              borderRadius: "10px",
            }}
            in={show}
          >
            <Box sx={{ m: 1 }}>
              <Stack
                gap={0.5}
                sx={{
                  px: 2,
                  pt: 1,
                  pb: 2,
                  borderBottom: "1px solid #dadada",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {userInfo?.first_name ??
                    // eslint-disable-next-line no-useless-concat
                    "User" + " " + userInfo?.last_name ??
                    ""}
                </Typography>
                <Typography sx={{ color: "var(--light-gray)", fontSize: 12 }}>
                  {userInfo?.sub_role?.role_name ?? ""}
                </Typography>
                <Typography variant="body2" sx={{ color: "var(--primary)" }}>
                  {userInfo?.company?.title ?? ""}
                </Typography>
              </Stack>
              <Stack
                gap={0.5}
                sx={{
                  // px: 2,
                  pt: 1,
                  pb: 2,
                  borderBottom: "1px solid #dadada",
                }}
              >
                {USER_ROUTES.map((route: RoutesProps, index: number) => (
                  <NavLink to={route.path} key={index} className="link2">
                    <AtsRow gap={"10px"}>
                      <>
                        <Box
                          sx={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {route.path === pathname
                            ? route.activIcon
                            : route.icon}
                        </Box>

                        <div className="link_text">{route.label}</div>
                      </>
                    </AtsRow>
                  </NavLink>
                ))}
              </Stack>
              <Stack
                gap={0.5}
                sx={{
                  // px: 2,
                  pt: 1,
                  pb: 2,
                }}
                onClick={onLogout}
              >
                <AtsRow gap={"10px"} className="link2">
                  <>
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LogoutIcon />
                    </Box>

                    <div
                      style={{ color: "var(--light-gray)" }}
                      className="link_text"
                    >
                      Log out
                    </div>
                  </>
                </AtsRow>
              </Stack>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default UserInfo;
