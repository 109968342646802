import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../Api/authService";
import { jobsServices } from "../Api/jobsServices";
import authSlice from "./slices/authSlice";
import candidateSlice from "./slices/candidateSlice";
import dashboardSlice from "./slices/dashboardSlice";
import interviewSlice from "./slices/interviewSlice";
import inviteMembersSlice from "./slices/inviteMembersSlice";
import jobSlice from "./slices/jobSlice";
import makeAnOfferSlice from "./slices/makeAnOfferSlice";
import postOpportunitySlice from "./slices/postOpportunitySlice";
import webSocketSlice from "./slices/webSocketSlice";
import messageSlice from "./slices/messageSlice";
import conversationSlice from "./slices/conversationSlice";
import callingSlice from "./slices/callingSlice";
import submissionSlice from "./slices/submissionSlice";
import projectsSlice from "./slices/projectsSlice";
import calendarSlice from "./slices/calendarSlice";
import invoicesSlice from "./slices/invoicesSlice";
import projectDetailsSlice from "./slices/projectDetailsSlice";
import contractorSlice from "./slices/contractorSlice";
import { companyDetailsService } from "../Api/companyDetailsService";
import contractorTimesheetSlice from "./slices/contractorTimesheetSlice";
import { responsibilitiesServices } from "../Api/responsibilitiesServices";
// Use throughout your app instead of plain `useDispatch` and `useSelector`

export const store = configureStore({
  reducer: {
    auth: authSlice,
    dashboard: dashboardSlice,
    interview: interviewSlice,
    candidate: candidateSlice,
    postOpportunity: postOpportunitySlice,
    makeAnOffer: makeAnOfferSlice,
    inviteMembers: inviteMembersSlice,
    submissions: submissionSlice,
    job: jobSlice,
    webSocket: webSocketSlice,
    message: messageSlice,
    conversation: conversationSlice,
    calling: callingSlice,
    projects: projectsSlice,
    invoices: invoicesSlice,
    calendar: calendarSlice,
    projectDetails: projectDetailsSlice,
    contractor: contractorSlice,
    contractorTimesheet: contractorTimesheetSlice,
    [authService.reducerPath]: authService.reducer,
    [jobsServices.reducerPath]: jobsServices.reducer,
    [companyDetailsService.reducerPath]: companyDetailsService.reducer,
    [responsibilitiesServices.reducerPath]: responsibilitiesServices.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authService.middleware,
      jobsServices.middleware,
      companyDetailsService.middleware,
      responsibilitiesServices.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
