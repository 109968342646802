import { Box, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubmissionService } from "../../../../../../../Api/submissionServices";
import {
  AboveAverageIcon,
  AverageIcon,
  BelowAverageIcon,
  ExceptionalIcon,
  UnsatisfactoryIcon,
} from "../../../../../../../assets/icons/Icons";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { useTimesheet } from "../../../../../../../providers/TimesheetProvider";
import { getProjectsState } from "../../../../../../../redux/slices/projectsSlice";
import { useAppSelector } from "../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { PerformanceReviewRequest } from "../../../../../../../types/contractorTypes";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsTextArea from "../../../../../../../components/AtsTextArea";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";

const PerformanceReviewTab = () => {
  const { id } = useParams();
  const { projectsDetails } = useAppSelector(getProjectsState);
  const { selectedContractorTimesheet } = useTimesheet();
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const name = selectedContractorTimesheet?.first_name
    ? `${selectedContractorTimesheet?.first_name}'s`
    : "";
  const initialPerformanceReviews = [
    {
      title: "General Rating",
      description: "",
      rating: 0,
      keyValue: "rating",
    },
    {
      title: `How did you like working with ${selectedContractorTimesheet?.first_name}?`,
      description: "",
      rating: 0,
      keyValue: "value",
    },
    {
      title: `How was ${name} communication during the project?`,
      description: "",
      rating: 0,
      keyValue: "communication",
    },
    {
      title: `Did ${name} work match their experience?`,
      description: "",
      rating: 0,
      keyValue: "service_as_described",
    },
    {
      title: `Did ${name} work satisfy the requirements of your project?`,
      description: "",
      rating: 0,
      keyValue: "quality",
    },
    {
      title: `Would you recommend ${selectedContractorTimesheet?.first_name} to a friend?`,
      description: "",
      rating: 0,
      keyValue: "recommendation",
    },
  ];

  const [performanceReviews, setPerformanceReviews] = useState(
    initialPerformanceReviews
  );

  const getRattingOfCandidate = async (candidate_id: string) => {
    setLoading(true);
    try {
      const response = await SubmissionService.getCandidateRattingSummary({
        candidate_id,
      });
      if (response) {
        const updateReview = {
          nid: "100115768",
          candidate_id,
          project_id: id,
          project_name: projectsDetails?.title,
          title: "",
          description: "",
          communication: Math.floor(Number(response.average_communication)),
          service_as_described: Math.floor(
            Number(response.average_service_as_described)
          ),
          quality: Math.floor(Number(response.average_quality)),
          recommendation: Math.floor(Number(response.average_recommendation)),
          value: Math.floor(Number(response.average_value)),
          rating: Math.floor(response.average_rating_star),
        } as PerformanceReviewRequest;

        const updatedArray = performanceReviews.map((item) => {
          const key = item.keyValue;
          if (updateReview[key] !== undefined) {
            return { ...item, rating: updateReview[key] };
          }
          return item;
        });
        setPerformanceReviews(updatedArray);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    selectedContractorTimesheet &&
      getRattingOfCandidate(selectedContractorTimesheet?.uid);
  }, []);

  const handleChage = (keyvalue: string, rate: number, index: number) => {
    const preReview = [...performanceReviews];
    const preObj = { ...performanceReviews[index] };
    if (preObj.rating === rate) {
      const updateObj = {
        ...preObj,
        rating: 0,
      };
      preReview.splice(index, 1, updateObj);
      setPerformanceReviews(preReview);
    } else {
      const updateObj = {
        ...preObj,
        rating: rate,
      };
      preReview.splice(index, 1, updateObj);
      setPerformanceReviews(preReview);
    }
  };

  const onSubmitReview = async () => {
    setLoading(true);
    try {
      const ratingsObj = performanceReviews.reduce((acc, review) => {
        acc[review.keyValue] = review.rating;
        return acc;
      }, {});
      const body = {
        nid: "100115768",
        candidate_id: selectedContractorTimesheet?.uid,
        project_id: id,
        project_name: projectsDetails?.title,
        title: "test",
        description,
        ...ratingsObj,
      } as PerformanceReviewRequest;
      await SubmissionService.updateCandidateReview(body);
      getRattingOfCandidate(selectedContractorTimesheet?.uid);
      setDescription("");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Stack
        gap={"10px"}
        sx={{
          height: "100%",
        }}
      >
        <AtsTitleText text={`Performance Review`} fs={18} fw={600} />
        <Box
          sx={{
            height: "calc(100% - 22px)",
          }}
        >
          <Box
            sx={
              {
                //   height: "100%",
                //   overflow: "auto",
              }
            }
          >
            <Stack gap={"40px"} py={"10px"}>
              <Stack
                sx={{
                  border: BORDERS.GRAY,
                  borderRadius: "10px",
                  p: "30px",
                }}
              >
                <Stack pb={"30px"}>
                  <AtsRow gap={"8px"}>
                    <>
                      <Box>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#1950sw0zba)">
                            <path
                              d="M7.022 0A7.002 7.002 0 0 0 0 7.022C.011 10.86 3.213 14 7.052 14H14V7.052C14 3.212 10.861.011 7.022 0zm.601 10.566a.996.996 0 0 1-.683.243.996.996 0 0 1-.684-.243.79.79 0 0 1-.273-.621c0-.252.09-.46.273-.62a.996.996 0 0 1 .684-.244c.273 0 .5.082.683.243a.79.79 0 0 1 .274.621.803.803 0 0 1-.274.62zm1.62-4.575a2.57 2.57 0 0 1-.542.711l-.588.547a1.368 1.368 0 0 0-.347.495c-.066.17-.102.383-.107.642H6.193c0-.497.058-.891.17-1.178.115-.293.303-.55.547-.75.251-.21.446-.404.577-.58a.952.952 0 0 0 .197-.579c0-.514-.222-.774-.665-.774a.615.615 0 0 0-.495.225c-.126.15-.194.352-.2.61H4.598c.008-.684.221-1.215.645-1.595.424-.38 1.017-.571 1.78-.571.76 0 1.35.175 1.769.528.418.352.626.85.626 1.498-.003.265-.06.53-.175.771z"
                              fill="#36C"
                            />
                          </g>
                          <defs>
                            <clipPath id="1950sw0zba">
                              <path fill="#fff" d="M0 0h14v14H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Box>
                      <AtsTitleText
                        text={"How does the performance review work?"}
                        fs={12}
                        fw={600}
                        textColor={COLORS.PRIMARY}
                      />
                    </>
                  </AtsRow>
                </Stack>
                <Stack gap={"40px"}>
                  {loading ? (
                    <Stack gap={1}>
                      <Skeleton variant="text" width={50} height={25} />
                      <Skeleton variant="text" width={50} height={25} />
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                      >
                        <Stack direction={"row"} gap={0.5}>
                          {Array.from(new Array(5)).map((_, index) => (
                            <Skeleton
                              variant="rounded"
                              width={35}
                              height={35}
                            />
                          ))}
                        </Stack>
                        <Stack direction={"row"} gap={0.5}>
                          <AtsTitleText
                            text={"Your Rating:"}
                            fs={12}
                            fw={400}
                            textColor={"#000"}
                          />
                          <Skeleton variant="text" width={50} height={25} />
                        </Stack>
                      </Stack>
                      <Stack sx={{ mt: 1 }}>
                        <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={55}
                        />
                      </Stack>
                    </Stack>
                  ) : (
                    performanceReviews.map((review, index) => (
                      <Stack key={index} gap={"20px"}>
                        <SkillsComponent
                          title={review.title}
                          des={review.description}
                          rating={review.rating}
                          onSelecteRate={(rate) =>
                            handleChage(review.keyValue, rate, index)
                          }
                        />
                      </Stack>
                    ))
                  )}
                </Stack>
              </Stack>
              <Stack gap={"30px"}>
                <AtsTitleText
                  text={
                    "Additionally, please provide a brief review, focusing on your experience and the Contractor’s interaction during the project."
                  }
                  fs={16}
                  fw={600}
                  textColor="#000"
                />
                <Stack gap={"5px"}>
                  <AtsTitleText text={"Review Description"} fs={14} fw={600} />
                  <AtsTextArea
                    rows={6}
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDescription(value);
                    }}
                  />
                </Stack>
              </Stack>
              <AtsRowBetween>
                <>
                  <AtsTitleText
                    text={"By submitting I agree to the review guidelines."}
                    fs={14}
                    fw={400}
                  />
                  <AtsRoundedButton
                    buttonWidth={126}
                    buttonHeight={38}
                    text="Submit"
                    fs={14}
                    fw={400}
                    onClick={onSubmitReview}
                    disabled={loading}
                    loading={loading}
                  />
                </>
              </AtsRowBetween>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default PerformanceReviewTab;

interface IProps {
  title: string;
  des: string;
  rating: number;
  comments?: string;
  onChange?: (comment: string) => void;
  onSelecteRate?: (rate: number) => void;
}

const SkillsComponent = ({ title, des, rating, onSelecteRate }: IProps) => {
  return (
    <Stack gap={"5px"}>
      <AtsTitleText fs={14} fw={600} textColor="#000" text={title} />
      <AtsTitleText fs={12} fw={400} textColor="#000" text={des} />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack direction={"row"} gap={0.5}>
          {Array.from(new Array(5)).map((_, index) => (
            <Box
              sx={{
                width: 35,
                height: 35,
                borderRadius: 1,
                border: renderImojiComponent(index + 1, rating).border,
                bgcolor: renderImojiComponent(index + 1, rating).bgColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => onSelecteRate(index + 1)}
            >
              {renderImojiComponent(index + 1, rating).icon}
            </Box>
          ))}
        </Stack>
        {renderTextComponent(rating)}
      </Stack>
    </Stack>
  );
};

const renderTextComponent = (rating: number) => {
  return (
    <Stack direction={"row"} gap={0.5}>
      <AtsTitleText fs={12} fw={400} textColor="#000" text={"Your Rating:"} />
      <AtsTitleText
        text={
          rating === 5
            ? "Exceptional"
            : rating === 4
            ? "Above Average"
            : rating === 3
            ? "Average"
            : rating === 2
            ? "Below Average"
            : rating === 1
            ? "Unsatisfactory"
            : "--"
        }
        fs={12}
        fw={400}
        textColor={
          rating === 5
            ? "#098c35"
            : rating === 4
            ? "#4eb111"
            : rating === 3
            ? "#ec7249"
            : rating === 2
            ? "#ec7249"
            : "#df3232"
        }
      />
    </Stack>
  );
};

const renderImojiComponent = (index: number, rating: number) => {
  const ratingsMap = {
    5: {
      icon: <ExceptionalIcon />,
      border: "#098c35",
      bgColor: "#098c35",
      lightBg: "#daf3e5",
    },
    4: {
      icon: <AboveAverageIcon />,
      border: "#4eb111",
      bgColor: "#4eb111",
      lightBg: "#ecffe1",
    },
    3: {
      icon: <AverageIcon />,
      border: "#ffcd34",
      bgColor: "#ffcd34",
      lightBg: "#fff8e3",
    },
    2: {
      icon: <BelowAverageIcon />,
      border: "#ec7249",
      bgColor: "#ec7249",
      lightBg: "#ffeee8",
    },
    1: {
      icon: <UnsatisfactoryIcon />,
      border: "#df3232",
      bgColor: "#df3232",
      lightBg: "#ecffe1",
    },
  };

  const defaultStyles = {
    border: "1px solid #c4c4c4",
    bgColor: "#e6e6e6",
  };

  if (index === rating) {
    const { icon, border, bgColor } = ratingsMap[rating];
    return { icon, border, bgColor };
  }

  if (rating >= 1 && rating <= 5) {
    const { border, lightBg } = ratingsMap[rating];
    if (index < rating) {
      return {
        icon: index,
        border: `1px solid ${border}`,
        bgColor: lightBg,
      };
    }
  }

  return {
    icon: index,
    ...defaultStyles,
  };
};
