import { createContext, useContext, useState } from "react";
import { MyProfileTabsEnums } from "../enums/myProfileEnums";

type MyProfileModuleContextType = {
  selectedMyProfileTab: MyProfileTabsEnums;
  setSelectedMyProfileTab: React.Dispatch<
    React.SetStateAction<MyProfileTabsEnums>
  >;
};

const MyProfileModule = createContext<MyProfileModuleContextType | null>(null);

export const useMyProfile = () =>
  useContext(MyProfileModule) as MyProfileModuleContextType;

export const MyProfileProvider = ({ children }: any) => {
  const [selectedMyProfileTab, setSelectedMyProfileTab] =
    useState<MyProfileTabsEnums>(MyProfileTabsEnums.Account);

  return (
    <MyProfileModule.Provider
      value={{
        selectedMyProfileTab,
        setSelectedMyProfileTab,
      }}
    >
      {children}
    </MyProfileModule.Provider>
  );
};
