import { Box, Stack } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsRow from "../../../components/AtsRow";
import { Edit2, Trash } from "iconsax-react";

const dummyCard = [
  {
    cardType: "Visa",
    cardNumber: "1234",
    expiry: "Expiry 06/2026",
    isDefault: true,
  },
  {
    cardType: "Master card",
    cardNumber: "5678",
    expiry: "Expiry 06/2026",
    isDefault: false,
  },
  {
    cardType: "Rupay",
    cardNumber: "5679",
    expiry: "Expiry 06/2026",
    isDefault: false,
  },
  {
    cardType: "Rupay",
    cardNumber: "5677",
    expiry: "Expiry 06/2026",
    isDefault: false,
  },
];

const PaymentMethod = () => {
  return (
    <Box
      sx={{
        height: "97%",
        overflowY: "auto",
        pr: "17px",
        pb: "10px",
      }}
    >
      {dummyCard.map((card, index) => {
        const cardText = `${card.cardType} Ending in ${card.cardNumber}`;
        return (
          <RenderCard
            key={index}
            isDefault={card.isDefault}
            cardTitle={cardText}
            cardExpiry={card.expiry}
            renderDefaultButton={() => {
              if (card.isDefault) {
                return (
                  <AtsRoundedButton
                    text="Default"
                    fs={12}
                    fw={400}
                    buttonHeight={23}
                    buttonWidth={61}
                  />
                );
              } else {
                return (
                  <AtsRoundedButton
                    text="Make default"
                    fs={12}
                    fw={400}
                    buttonVariant="whiteContained"
                    buttonHeight={23}
                    buttonWidth={106}
                  />
                );
              }
            }}
            renderActionComponent={() => {
              return (
                <AtsRowBetween
                  sx={{
                    px: "20px",
                    py: "15px",
                  }}
                >
                  <>
                    <AtsRow
                      gap={"4px"}
                      sx={{
                        cursor: !card.isDefault ? "auto" : "pointer",
                      }}
                    >
                      <>
                        <Edit2
                          size={16}
                          color={
                            card.isDefault ? COLORS.PRIMARY : COLORS.LIGHT_GRAY
                          }
                        />
                        <AtsTitleText
                          fs={12}
                          fw={400}
                          textColor={
                            card.isDefault ? COLORS.PRIMARY : COLORS.LIGHT_GRAY
                          }
                          text={"Edit"}
                        />
                      </>
                    </AtsRow>

                    <AtsRow
                      gap={"4px"}
                      sx={{
                        cursor: card.isDefault ? "auto" : "pointer",
                      }}
                    >
                      <>
                        <Trash
                          size={16}
                          color={COLORS.LIGHT_GRAY}
                          opacity={card.isDefault ? 0.3 : 1}
                        />
                      </>
                    </AtsRow>
                  </>
                </AtsRowBetween>
              );
            }}
            isLastCard={dummyCard.length - 1 !== index}
          />
        );
      })}
    </Box>
  );
};

export default PaymentMethod;

const RenderCard = ({
  cardTitle,
  cardExpiry,
  renderDefaultButton,
  isDefault,
  renderActionComponent,
  isLastCard,
}) => {
  return (
    <Box
      sx={{
        border: BORDERS.GRAY,
        borderRadius: "10px",
        mb: isLastCard ? "16px" : "0px",
        ml: "10px",
        ...(isDefault && { boxShadow: " 0 4px 4px 0 rgba(0, 0, 0, 0.25)" }),
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        sx={{
          px: "20px",
          pb: "25px",
          pt: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <Stack direction={"row"} alignItems={"flex-start"} gap={"18px"}>
          <Box
            sx={{
              width: 44,
              height: 30,
              border: BORDERS.GRAY,
              borderRadius: "6px",
            }}
          ></Box>
          <Stack gap={"10px"}>
            <AtsTitleText
              fs={14}
              fw={600}
              textColor={"#000"}
              text={cardTitle}
            />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text={cardExpiry}
            />
          </Stack>
        </Stack>
        {renderDefaultButton()}
      </Stack>
      {renderActionComponent()}
    </Box>
  );
};
