import { Box, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import AtsRowBetween from "./AtsRowBetween";
import { BORDERS } from "../theme";
import AtsTitleText from "./AtsTitleText";
import AtsRow from "./AtsRow";
import { SearchZoomIn, SearchZoomOut1 } from "iconsax-react";
import { CgSoftwareDownload } from "react-icons/cg";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const AtsPdfViewer = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.2, 3));
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));

  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "document.pdf";
    link.click();
  };

  return (
    <>
      <Stack
        sx={{
          height: "100%",
        }}
      >
        <AtsRowBetween
          sx={{
            px: "20px",
            py: "26px",
            borderBottom: BORDERS.GRAY,
          }}
        >
          <>
            <AtsTitleText
              text={"Master Service Agreement (MSA)- Contract Details"}
              fs={18}
              fw={600}
            />
            <AtsRow>
              <>
                <IconButton
                  onClick={zoomIn}
                  sx={{
                    p: "5px",
                  }}
                >
                  <SearchZoomIn size={18} />
                </IconButton>
                <IconButton
                  onClick={zoomOut}
                  sx={{
                    p: "5px",
                  }}
                >
                  <SearchZoomOut1 size={18} />
                </IconButton>
                <IconButton
                  onClick={downloadPdf}
                  sx={{
                    p: "5px",
                  }}
                >
                  <CgSoftwareDownload />
                </IconButton>
              </>
            </AtsRow>
          </>
        </AtsRowBetween>
        <Box
          sx={{
            height: "calc(100% - 90px)",
            overflow: "auto",
          }}
        >
          {pdfFile && (
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default AtsPdfViewer;
