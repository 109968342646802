import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";
import { Eye, InfoCircle, ReceiptEdit, Star } from "iconsax-react";
import { Start } from "@mui/icons-material";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import PdfIcon from "../../../assets/images/pdfIcon.png";
import { SOWResponse } from "../../../types/contractTypes";
import { ATS_ROUTES } from "../../../router/routes";
import { ContractsTabsEnums } from "../../../providers/ContractsProvider";
import { useNavigate } from "react-router-dom";

const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

interface IProps {
  data: SOWResponse;
}

const StatementOfWorkCard = ({ data }: IProps) => {
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 161,
          mx: "9px",
          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 177,
                minHeight: 161,
                bgcolor: BG_COLORS.PRIMARY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <ReceiptEdit size="60" color={COLORS.LIGHT_GRAY} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <Stack gap={"3px"} textAlign={"end"}>
                  <AtsRow gap={"3px"}>
                    <>
                      <Star />
                      <AtsTitleText
                        text={"talent"}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </>
                  </AtsRow>
                  <AtsTitleText text={"SOW"} textColor={"#000"} />
                </Stack>
              </Box>
              {data.isActive && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    padding: "2px 7px 3px",
                    bgcolor: "#1bbd40",
                    borderTopLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <AtsTitleText
                    text={"Active"}
                    textColor={"white"}
                    fs={11}
                    fw={600}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 161,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      <Grid container spacing={"20px"}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"SOW No."}
                            value={`SOW${data.id}`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"Contractor Name"}
                            value={data.contractorName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"Job"} value={data.job} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper
                            text={"Start date"}
                            value={data.startDate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"End date"} value={data.endDate} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextWrapper text={"Project"} value={data.project} />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      ...(data.isE_Sign
                        ? { minWidth: 190, maxWidth: 210 }
                        : { minWidth: 202 }),

                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pr: "19px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {data.isE_Sign ? (
                      <EsignComponent id={data.id} />
                    ) : data.isRenew ? (
                      <RenewComponent />
                    ) : (
                      <ViewContractComponent />
                    )}
                  </Box>
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
    </>
  );
};

export default StatementOfWorkCard;

const TextWrapper = ({ text, value }) => {
  return (
    <Stack gap={"2px"}>
      <AtsTitleText
        text={text}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
      />
      <AtsTitleText
        text={value}
        fs={14}
        fw={500}
        textColor={COLORS.DARK_GRAY}
      />
    </Stack>
  );
};

const EsignComponent = ({ id }) => {
  const navigate = useNavigate();
  return (
    <Stack direction={"row"} alignItems={"flex-start"} gap={"7px"}>
      <Box>
        <InfoCircle size={16} variant="Bold" color="#e63030" />
      </Box>
      <Stack gap={"18px"}>
        <AtsTitleText
          text={`Your Master Services Agreement (MSA) is ready for e-signature. 
Please e-sign it now to access the Dashboard.`}
          fs={12}
          fw={400}
        />
        <AtsRoundedButton
          text="E-Signature"
          isInitial
          buttonWidth={119}
          buttonHeight={26}
          fs={12}
          onClick={() => {
            const contractDetailsRoutes = ATS_ROUTES.CONTRACTS_DETAILS.replace(
              ":contractType",
              "statement-of-work"
            ).replace(":contractId", "1234");
            navigate(contractDetailsRoutes);
          }}
        />
      </Stack>
    </Stack>
  );
};
const ViewContractComponent = () => {
  return (
    <Stack gap={"18px"} sx={{}}>
      <AtsRoundedButton
        text="View contract"
        isInitial
        buttonVariant="outline"
        buttonWidth={129}
        buttonHeight={26}
        fs={12}
        fw={400}
        startIcon={<Eye color={COLORS.PRIMARY} size={16} />}
      />
      <AtsRoundedButton
        text="Download contract"
        isInitial
        buttonVariant="outline"
        buttonWidth={158}
        buttonHeight={26}
        fs={12}
        fw={400}
        startIcon={<img src={PdfIcon} alt="pdf" height={16} />}
      />
    </Stack>
  );
};
const RenewComponent = () => {
  return (
    <Stack direction={"row"} alignItems={"flex-start"} gap={"7px"}>
      <Box>
        <InfoCircle size={16} variant="Bold" color="#e63030" />
      </Box>
      <Stack gap={"18px"} sx={{}}>
        <AtsTitleText text={`Contract ends today`} fs={12} fw={400} />
        <AtsRoundedButton
          text="Renew"
          isInitial
          buttonWidth={68}
          buttonHeight={26}
          fs={12}
          fw={400}
        />
        <AtsRoundedButton
          text="Download contract"
          isInitial
          buttonVariant="outline"
          buttonWidth={158}
          buttonHeight={26}
          fs={12}
          fw={400}
          startIcon={<img src={PdfIcon} alt="pdf" height={16} />}
        />
      </Stack>
    </Stack>
  );
};
