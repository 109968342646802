import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { Box, Grid, Stack } from "@mui/material";
import BillingInfo from "../components/BillingInfo";
import PaymentMethod from "../components/PaymentMethod";
import { BORDERS, COLORS } from "../../../theme";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { Add, Edit2 } from "iconsax-react";

const BillingPaymentDetails = () => {
  return (
    <>
      <Stack gap={"16px"}>
        <AtsTitleText
          fs={18}
          fw={600}
          textColor={"#000"}
          text={"Billing and Payment Details"}
        />
        <Grid container spacing={"20px"}>
          <Grid item xs={12} sm={6}>
            <BoxWrapper
              title={"Billing information"}
              renderButtons={() => (
                <AtsRoundedButton
                  text="Update information"
                  buttonVariant="outline"
                  buttonHeight={30}
                  buttonWidth={185}
                  startIcon={<Edit2 size="14" color={COLORS.PRIMARY} />}
                />
              )}
            >
              <Box pl={"10px"}>
                <BillingInfo />
              </Box>
            </BoxWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BoxWrapper
              title={"Payment Method"}
              renderButtons={() => (
                <AtsRoundedButton
                  text="Add payment method"
                  buttonVariant="outline"
                  buttonHeight={30}
                  buttonWidth={203}
                  startIcon={<Add size="18" color={COLORS.PRIMARY} />}
                />
              )}
            >
              <>
                <PaymentMethod />
              </>
            </BoxWrapper>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default BillingPaymentDetails;

const BoxWrapper = ({ title, children, renderButtons }) => {
  return (
    <Box
      sx={{
        padding: "24px 35px 30px 20px",

        border: BORDERS.GRAY,
        height: "430px",
        borderRadius: "6px",
      }}
    >
      <Stack pb={"23px"} pl={"10px"}>
        <AtsTitleText fs={16} fw={600} text={title} />
      </Stack>
      <Box
        sx={{
          height: "calc(100% - 73px)",
        }}
      >
        {children}
      </Box>
      <Box>{renderButtons()}</Box>
    </Box>
  );
};
