import { Box, Dialog, Grid, Stack } from "@mui/material";
import AtsBackLink from "../../../../../../../../../components/AtsBackLink";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import UserInfo from "../../../../../../../../../layout/Header/UserInfo";
// import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { WarningIcon } from "../../../../../../../assets/Icons";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import {
  getMakeAnOfferState,
  setIsOpenMakeAnOfferDialog,
} from "../../../../../../../../../redux/slices/makeAnOfferSlice";
import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import InterviewSummary from "../components/InterviewSummary";
import {
  getInterviewState,
  initialCreateInterviewState,
  setCreateInterview,
  setInterviewIntialState,
  setIsOpenInterviewDetailsDialog,
  setNextTab,
  TabSelect,
} from "../../../../../../../../../redux/slices/interviewSlice";
import InterviewDateTimeSelection from "../InterviewDateTimeSelection";
import InterviewScheduled from "../InterviewScheduled";
import AttendeeDetails from "../AttendeeDetails";
import { useState } from "react";
import { ArrowForwardIos, ChevronLeft } from "@mui/icons-material";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { InterviewsService } from "../../../../../../../../../Api/interviewServices";
import {
  CreateUpdateInterviewParams,
  InterviewData,
} from "../../../../../../../../../types/interviewTypes";
import { toast } from "react-toastify";
import { CalendarServices } from "../../../../../../../../../Api/calendarServices";
import { attendeeDetailsValidation } from "../../../../../../../../../utils/validationUtils";
import { ValuePiece } from "../../../../../../../../../components/AtsDateCalenderPicker";
import moment from "moment";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { useParams } from "react-router-dom";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsRow from "../../../../../../../../../components/AtsRow";
import { GoogleEventRequest } from "../../../../../../../../../types/calendarTypes";

const ScheduleInterviewDialog = () => {
  const { nid } = useParams();
  const dispatch = useAppDispatch();
  const {
    nextTab,
    interviewTab,
    count,
    selectedDate,
    selectedSlot,
    createInterview,
    isOpenInterviewDetailsDialog,
    time,
  } = useAppSelector(getInterviewState);
  const { candidateDetails } = useAppSelector(getSubmissionState);

  const [error, setError] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [createInterviewData, setCreateInterviewData] =
    useState<InterviewData | null>(null);

  const convertDateAndTime = () => {
    let dateToFormat: ValuePiece;

    if (Array.isArray(selectedDate)) {
      dateToFormat = selectedDate[0];
    } else {
      dateToFormat = selectedDate;
    }
    if (dateToFormat) {
      const combinedString = `${moment(dateToFormat).format(
        "YYYY-MM-DD"
      )} ${selectedSlot}`;
      const date = moment(combinedString, "YYYY-MM-DD hh:mm A");

      return date.toISOString().split(".")[0];
    }
  };

  const onNext = async () => {
    let validation = await attendeeDetailsValidation(createInterview);
    setError(validation.error);
    if (validation.isValid) {
      try {
        dispatch(setNextTab(TabSelect.SCHEDULE));
      } catch (error) {}
    }
  };

  const getMeetLink = async () => {
    try {
      const body = {
        attendees: createInterview.interviewers,
        title: createInterview.subject,
        description: createInterview.description,
        startTime: time.start,
        endTime: time.end,
      } as GoogleEventRequest;
      const response = await CalendarServices.createGoolgeEvent(body);
      return response.hangoutLink;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const scheduleEvent = async () => {
    setLoading(true);
    const date_time = convertDateAndTime();
    const meetLink = await getMeetLink();
    const response = await CalendarServices.getCandidateInterviewByJob({
      job_id: nid,
      candidate_id: candidateDetails?.id,
    });

    let updateInterview = {};

    if (!response.results.length) {
      const preInteview = {
        ...createInterview,
        date_time,
        status_id: 3428,
        type_id: 3919,
        interview_link: meetLink,
      } as CreateUpdateInterviewParams;
      updateInterview = preInteview;
    } else {
      const preInteview = {
        ...createInterview,
        date_time,
        status_id: 3428,
        type_id: 3919,
        interview_link: meetLink,
      } as CreateUpdateInterviewParams;
      updateInterview = preInteview;
    }
    const createInterviewResponse = await InterviewsService.createInterview(
      updateInterview
    );
    // dispatch(setInterviewTab(1));
    dispatch(setNextTab(TabSelect.SUCCESS_SCHEDULE));
    dispatch(setCreateInterview(initialCreateInterviewState));
    setCreateInterviewData(createInterviewResponse.data);
    toast.success(createInterviewResponse.message);

    try {
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const disabled = () => {
    if (count === 0 || !selectedSlot.length) return true;
    return false;
  };

  const renderNextOrScheduleButton = () =>
    nextTab === TabSelect.SCHEDULE ? (
      <AtsRowBetween gap={5}>
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={() => dispatch(setNextTab(TabSelect.ATENDEE_DETAILS))}
          >
            <ChevronLeft sx={{ fontSize: 22, color: "#3366cc" }} />
            <AtsTitleText
              fs={12}
              textColor={COLORS.PRIMARY}
              fw={500}
              text="Go Back"
            />
          </Stack>
          <AtsRoundedButton
            disabled={disabled() || loading}
            // loading={loading}
            onClick={scheduleEvent}
            text="Schedule Event"
            endIcon={<ArrowForwardIos sx={{ fontSize: "13px !important" }} />}
            variant="contained"
            buttonWidth={"170px"}
          />
        </>
      </AtsRowBetween>
    ) : (
      <AtsRow justifyContent={"end"}>
        <AtsRoundedButton
          onClick={onNext}
          text="Next"
          endIcon={<ArrowForwardIos sx={{ fontSize: "13px !important" }} />}
          variant="contained"
          buttonWidth={"170px"}
        />
      </AtsRow>
    );
  return (
    <>
      <Dialog open={isOpenInterviewDetailsDialog} fullScreen>
        <Stack
          direction={"row"}
          className="header"
          sx={{ justifyContent: "space-between" }}
        >
          <Box
            sx={{
              height: "50px",
            }}
          >
            <img
              height={"100%"}
              src={require("../../../../../../../../../assets/images/logo.png")}
              alt="esteemed"
            />
          </Box>
          <Stack direction={"row"} alignItems={"center"} gap={2} mx={3}>
            <UserInfo />
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 83px)",
          }}
        >
          <Box
            sx={{
              height: "97%",
              // overflow: "scroll",
              p: 2,
            }}
          >
            <Stack
              // m={2}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                border: BORDERS.GRAY,
                borderRadius: 1,
                height: "100%",
              }}
            >
              <>
                <Stack
                  direction={"row"}
                  px={4}
                  py={2}
                  sx={{ justifyContent: "space-between" }}
                >
                  <AtsTitleText text={"Schedule Interview"} fs={24} fw={600} />
                  <AtsBackLink
                    text="Back to Candidate’s Details"
                    onClick={() => {
                      dispatch(setInterviewIntialState());
                    }}
                  />
                </Stack>
                {nextTab !== TabSelect.SUCCESS_SCHEDULE && (
                  <Stack
                    direction={"row"}
                    px={4}
                    pb={"20px"}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <AtsTitleText
                      text={"Enter meeting details"}
                      fs={16}
                      fw={600}
                    />
                  </Stack>
                )}
                <Stack
                  sx={{
                    height: "calc(100% - 63px)",
                  }}
                  px={4}
                  py={2}
                >
                  {nextTab === TabSelect.SUCCESS_SCHEDULE ? (
                    <InterviewScheduled
                      createInterviewData={createInterviewData}
                    />
                  ) : (
                    <Grid container columnSpacing={"50px"} height={"97%"}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        lg={8.5}
                        xl={9}
                        xxl={9}
                        height={"100%"}
                      >
                        <Stack>
                          <Stack
                            sx={{
                              p: "30px",
                              border: BORDERS.GRAY,
                              borderRadius: "10px",
                            }}
                          >
                            {nextTab === TabSelect.SCHEDULE ? (
                              <InterviewDateTimeSelection />
                            ) : (
                              <AttendeeDetails error={error} />
                            )}
                          </Stack>

                          {/* <JobDetailsAndTerms /> */}
                        </Stack>
                        <Stack
                          sx={{
                            py: "30px",
                          }}
                        >
                          {renderNextOrScheduleButton()}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={4} md={5} lg={3.5} xl={3} xxl={3}>
                        <InterviewSummary />
                      </Grid>
                    </Grid>
                  )}
                </Stack>
              </>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ScheduleInterviewDialog;
