import { Box, Drawer } from "@mui/material";
import {
  getContractorDetailsState,
  setContractors,
  setIsContractorDetailsDrawerOpen,
  setSelectedContractorDrawerTab,
  setSelectedContractorTimesheet,
} from "../../../../../../redux/slices/contractorSlice";
import { setInitialContractorTimeSheetState } from "../../../../../../redux/slices/contractorTimesheetSlice";
import { setCandidateDetails } from "../../../../../../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import ContractorDetailBottomSection from "./modules/ContractorDetailBottomSection";
import ContractorDetailsTopSection from "./modules/ContractorDetailsTopSection";
import { useMemo } from "react";
import { RequestToConvertProvider } from "../../../../../../providers/RequestToConvert";
import { MakeAnOfferProvider } from "../../../../../../providers/MakeAnOfferProvider";

const ContractorDetailsDrawer = () => {
  const dispatch = useAppDispatch();
  const { isContractorDetailsDrawerOpen } = useAppSelector(
    getContractorDetailsState
  );
  const bottomSection = useMemo(() => <ContractorDetailBottomSection />, []);
  const topSection = useMemo(
    () => (
      <MakeAnOfferProvider>
        <RequestToConvertProvider>
          <ContractorDetailsTopSection />
        </RequestToConvertProvider>
      </MakeAnOfferProvider>
    ),
    []
  );
  return (
    <>
      <Drawer
        sx={{}}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            width: "1130px",
            // width:
            //   isBiScreen || isDesktop
            //     ? "1130px"
            //     : isMiniDesktop
            //     ? "90%"
            //     : "75%",
            overflow: "hidden",
          },
        }}
        anchor="right"
        open={isContractorDetailsDrawerOpen}
        onClose={() => onCloseContractorDetailsDrawer(dispatch)}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            outline: "0",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            pb: 2,
            height: "100%",
          }}
        >
          {topSection}
          {bottomSection}
        </Box>
      </Drawer>
    </>
  );
};

export default ContractorDetailsDrawer;

export const onCloseContractorDetailsDrawer = (dispatch: any) => {
  dispatch(setContractors([]));
  dispatch(setSelectedContractorDrawerTab(0));
  dispatch(setCandidateDetails(null));
  dispatch(setSelectedContractorTimesheet(null));
  dispatch(setInitialContractorTimeSheetState());
  dispatch(setIsContractorDetailsDrawerOpen(false));
};
