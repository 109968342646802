import { Box, Stack } from "@mui/material";
import React from "react";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRow from "../../../components/AtsRow";
import Menu from "../icons/Menu.svg";

const CompanyOverview = () => {
  return (
    <>
      <Stack
        gap={"20px"}
        pb={"30px"}
        sx={{
          borderBottom: BORDERS.GRAY,
        }}
      >
        <AtsTitleText text={"Company overview"} fs={18} fw={600} />
        <TextWrapper title={"Industry"} value={"Software Development"} />
        <TextWrapper
          title={"Headquarters"}
          value={"Olympia, Washington, United States"}
        />
        <AtsRow gap={"5px"}>
          <>
            <img src={Menu} alt="" />
            <AtsTitleText
              text={"See Company description"}
              fs={12}
              fw={600}
              textColor={COLORS.PRIMARY}
            />
          </>
        </AtsRow>
      </Stack>
    </>
  );
};

export default CompanyOverview;

const TextWrapper = ({ title, value }) => {
  return (
    <Stack gap={"5px"}>
      <AtsTitleText
        text={title}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
      />
      <AtsTitleText
        text={value}
        fs={14}
        fw={700}
        textColor={COLORS.DARK_GRAY}
      />
    </Stack>
  );
};
