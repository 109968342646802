import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";
import { InfoCircle, ReceiptEdit, Star } from "iconsax-react";
import { Start } from "@mui/icons-material";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";
import { ContractsTabsEnums } from "../../../providers/ContractsProvider";

const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

const ActiveContracts = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 188,
          mx: "9px",
          boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 252,
                minHeight: 188,
                bgcolor: BG_COLORS.PRIMARY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <ReceiptEdit size="90" color={COLORS.LIGHT_GRAY} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <Stack gap={"3px"} textAlign={"end"}>
                  <AtsRow gap={"3px"}>
                    <>
                      <Star />
                      <AtsTitleText
                        text={"talent"}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </>
                  </AtsRow>
                  <AtsTitleText text={"MSA"} textColor={"#000"} />
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 188,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      gap={"55px"}
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      <Stack
                        gap={"15px"}
                        sx={{
                          minWidth: 100,
                        }}
                      >
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={"Details"}
                          textColor={"#000"}
                        />
                        <Stack gap={"20px"}>
                          <DateText
                            text={"Start Date"}
                            value={"Sep 12, 2024"}
                          />
                          <DateText text={"End Date"} value={"Sep 12, 2025"} />
                        </Stack>
                      </Stack>
                      <Stack gap={"15px"}>
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={"Included in your contract"}
                          textColor={"#000"}
                        />
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: 1.43,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {dummyText}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      minWidth: 190,
                      maxWidth: 210,
                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pt: "25px",
                      pr: "19px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      gap={"7px"}
                    >
                      <Box>
                        <InfoCircle size={16} variant="Bold" color="#e63030" />
                      </Box>
                      <Stack gap={"18px"}>
                        <AtsTitleText
                          text={`Your Master Services Agreement (MSA) is ready for e-signature. 
Please e-sign it now to access the Dashboard.`}
                          fs={12}
                          fw={400}
                        />
                        <AtsRoundedButton
                          text="E-Signature"
                          isInitial
                          buttonWidth={119}
                          buttonHeight={26}
                          fs={12}
                          onClick={() => {
                            const contractDetailsRoutes =
                              ATS_ROUTES.CONTRACTS_DETAILS.replace(
                                ":contractType",
                                "master-contracts"
                              ).replace(":contractId", "1234");
                            navigate(contractDetailsRoutes);
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
    </>
  );
};

export default ActiveContracts;

const DateText = ({ text, value }) => {
  return (
    <Stack gap={"2px"}>
      <AtsTitleText text={text} fs={12} fw={400} textColor={COLORS.DARK_GRAY} />
      <AtsTitleText text={value} fs={14} fw={600} />
    </Stack>
  );
};
