import { Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsTextInput from "../../../../../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { useOnboarding } from "../../../../../../../../../providers/OnboardingProvider";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { ResponsibilitiesResponse } from "../../../../../../../../../types/onboardingTypes";
import FormWrapper from "./FormWrapper";
import axios from "axios";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";

const ResponsibilitesStep = () => {
  const { contractorPlacmentDetails } = useAppSelector(
    getContractorDetailsState
  );
  const {
    createOnboardingState,
    handleCreateOnboardingState,
    setResponsibilitiesLoading,
    responsibilitiesLoading,
  } = useOnboarding();

  const getResponsibilities = async (job_title) => {
    setResponsibilitiesLoading(true);
    try {
      const newMessage = {
        role: "user",
        // content: `Sample job posting for a ${postOpportunity?.job_title} job with detailed Job description, Key Responsibilities, Requirements/Skills and fix this text to be marked up with semantic HTML using only lists, headers, or paragraph tags and remove first paragaph, job title heading, job posting heading`,
        content: `Structured list of typical roles and responsibilities for a "${job_title}" position, laid out in headings and bullet points in Json array with keys category and responsibilities`,
      };
      const newMsg = [newMessage];
      const url = "https://api.openai.com/v1/chat/completions";
      // const token = `Bearer ${process.env.CHAT_GPT_API_KEY}`;
      const token = `Bearer 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`;
      const model = "gpt-4";
      const response = await axios.post(
        url,
        {
          model: model,
          messages: newMsg,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const content =
        response.data?.choices && response.data?.choices[0]?.message?.content;
      if (content) {
        handleCreateOnboardingState(
          "job_responsibilities",
          JSON.parse(content.trim())
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setResponsibilitiesLoading(false);
    }
  };
  useEffect(() => {
    contractorPlacmentDetails &&
      contractorPlacmentDetails.job_title.length &&
      getResponsibilities(contractorPlacmentDetails.job_title);
  }, []);

  const handleChange = (
    key: string,
    value: string | string[],
    index: number
  ) => {
    const preResp = [...createOnboardingState.job_responsibilities];
    const updatedRespo = {
      ...createOnboardingState.job_responsibilities[index],
      [key]: value,
    };
    preResp.splice(index, 1, updatedRespo);
    handleCreateOnboardingState("job_responsibilities", preResp);
    // setResponsibilities(preResp);
  };
  const handleResponsibilitiesChange = (
    response: ResponsibilitiesResponse,
    value: string,
    childIndex: number,
    index: number
  ) => {
    const reponString = [...response.responsibilities];
    reponString.splice(childIndex, 1, value);
    handleChange("responsibilities", reponString, index);
  };

  return (
    <>
      <FormWrapper heading="Responsibilities">
        <Stack gap={"20px"} pt={"20px"}>
          {responsibilitiesLoading ? (
            <FormWrapper heading="">
              <Stack gap={"20px"}>
                <Stack gap={"4px"}>
                  <AtsTitleText text={"Category"} fs={12} fw={500} />
                  <Skeleton variant="rounded" width={"100"} height={37} />
                </Stack>
                <Stack gap={"4px"}>
                  <Stack gap={"3px"}>
                    <AtsTitleText text={"Responsibilities"} fs={12} fw={500} />
                    {Array.from(new Array(4)).map((_, idx) => (
                      <Skeleton
                        key={idx}
                        variant="rounded"
                        width={"100"}
                        height={37}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </FormWrapper>
          ) : (
            createOnboardingState.job_responsibilities.map(
              (response, index) => (
                <FormWrapper key={index} heading="">
                  <Stack gap={"20px"}>
                    <Stack gap={"4px"}>
                      <AtsTitleText text={"Category"} fs={12} fw={500} />
                      <AtsTextInput
                        value={response.category}
                        onChange={(e) =>
                          handleChange("category", e.target.value, index)
                        }
                      />
                    </Stack>
                    <Stack gap={"4px"}>
                      <Stack gap={"3px"}>
                        <AtsTitleText
                          text={"Responsibilities"}
                          fs={12}
                          fw={500}
                        />
                        {response.responsibilities.length > 0 &&
                          response.responsibilities.map((item, idx) => (
                            <AtsTextInput
                              value={item}
                              onChange={(e) =>
                                handleResponsibilitiesChange(
                                  response,
                                  e.target.value,
                                  idx,
                                  index
                                )
                              }
                            />
                          ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </FormWrapper>
              )
            )
          )}
        </Stack>
      </FormWrapper>
    </>
  );
};

export default ResponsibilitesStep;
