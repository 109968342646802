import { Box, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import { IColumnProps } from "../../../../../../../types/commonType";
import { BORDERS } from "../../../../../../../theme";
import { useInvoices } from "../../../../../../../providers/InvoicesProvider";
import { InvoiceDetailsResponse } from "../../../../../../../types/invoiceTypes";

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "date",
    header: "Time Period",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "description",
    header: "Contractor",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "quantity",
    header: "Hours",
    width: "auto",
    minWidth: "200px",
  },
  {
    key: "rate",
    header: "Hourly Rate",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "amount",
    header: "Amount",
    width: "auto",
    minWidth: "200px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const InvoiceItemsList = () => {
  const { invoiceDetails } = useInvoices();
  const customizeRow = (
    column: IColumnProps,
    invoice: InvoiceDetailsResponse
  ) => {
    switch (column.key) {
      case "date":
        const dateValue = `${invoice?.start_date} - ${invoice?.end_date}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={dateValue}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "description":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={600}
                  text={invoice.description}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "quantity":
        const value = `${invoice.quantity} hrs`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={value}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "rate":
        const rateValue = `$${invoice.rate}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={rateValue}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "amount":
        const amountValue = `$${invoice.amount}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={400}
                  text={amountValue}
                />
              </Stack>
            </Stack>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = invoiceDetails.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "12px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsTitleText
              text={column.header}
              fs={12}
              fw={600}
              textColor="#4f4f4f"
              isCursor
            />
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Box mb={"23px"}>
        <AtsTitleText text={"Invoice items"} fs={16} fw={600} />
      </Box>
      <Box>
        <AtsDataTable
          isNotStickyHeader
          isTopBorder
          data={invoiceDetails}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={false}
        />
      </Box>
    </>
  );
};

export default InvoiceItemsList;
