import React, { useEffect, useState } from "react";
import JobCardWrapper from "../../../PostOpportunity/components/JobCardWrapper";
import { Box, Stack } from "@mui/material";
import { BORDERS, COLORS } from "../../../../theme";
import ButtonSelection from "../../../PostOpportunity/components/ButtonSelection";
import AtsTitleText from "../../../../components/AtsTitleText";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setCreateProjectState,
} from "../../../../redux/slices/projectsSlice";
import JobTitleSelector from "../../../PostOpportunity/components/JobTitleSelector";
import AtsAutoComplete from "../../../../components/AtsAutoComplete";
import {
  createProjectParams,
  TeamMemberProps,
} from "../../../../types/projectTypes";
import { ProjectService } from "../../../../Api/projectServices";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import SelectInterviewer from "../../../Jobs/Submissions/pipeline/Candidate/CandidateDetails/CandidateDetailsTabs/InterViewTab/components/SelectInterviewer";

const members = ["Chrish McGrath", "Ayush Mittal", "Shakti Thakor"];

const ExistingMembers = () => {
  const dispatch = useAppDispatch();
  const { createProjectState } = useAppSelector(getProjectsState);
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState<TeamMemberProps[]>([]);

  const handleChange = (value: string) => {
    const preMember = [...createProjectState.team_members];
    if (preMember.includes(value)) {
      const updateMemeber = preMember.filter((day) => day !== value);
      const opportunityObj = {
        ...createProjectState,
        team_members: updateMemeber.filter((str) => str !== ""),
      } as createProjectParams;
      console.log("opportunityObj", opportunityObj);
      dispatch(setCreateProjectState(opportunityObj));
    } else {
      const updateMemeber = [...preMember, value];
      const opportunityObj = {
        ...createProjectState,
        team_members: updateMemeber.filter((str) => str !== ""),
      } as createProjectParams;
      console.log("opportunityObj", opportunityObj);
      dispatch(setCreateProjectState(opportunityObj));
    }
  };

  const getTeamMember = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getProjectMember({});
      setTeamMembers(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getNameText = (preEmail: string) => {
    const getMember = teamMembers.find((member) => member.email === preEmail);
    return `${getMember?.first_name ?? ""} ${getMember?.last_name ?? ""}`;
  };

  useEffect(() => {
    getTeamMember();
  }, []);

  return (
    <JobCardWrapper
      header="Add existing team members to the project"
      subText="Add existing team members to your project"
    >
      <Stack px={1}>
        <Stack gap={2}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            {/* <AtsAutoComplete
              // handleChange={(value) => {
              //   setSearchValue(value);
              //   setPage(0);
              // }}
              options={[]}
              searchValue={""}
              selected={""}
              // onScroll={loadMoreItems}
              onSelect={(selected) => {}}
              placeHolder="Enter name of your team members"
            /> */}
            <SelectInterviewer
              selected={createProjectState.team_members}
              onChange={(selected) => handleChange(selected)}
            />
          </Box>
          {/* <Stack
            direction={"row"}
            gap={1}
            flexWrap={"wrap"}
            pb={2}
            sx={{ borderBottom: BORDERS.GRAY }}
          >
            {createProjectState.team_members?.length > 0 &&
              createProjectState.team_members
                .filter((str) => str !== "")
                .map((memberEmail, index) => (
                  <ButtonSelection
                    key={index}
                    onSelect={() => handleChange(memberEmail)}
                    text={getNameText(memberEmail)}
                    isSelected={true}
                  />
                ))}
          </Stack> */}
        </Stack>
        <Stack gap={1} pt={2}>
          <AtsTitleText
            text={"Other team members"}
            fs={"0.875rem"}
            fw={600}
            textColor={COLORS.LIGHT_GRAY}
          />
          <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
            {loading
              ? Array.from(new Array(8)).map((_, index) => (
                  <AtsRoundedButton
                    key={index}
                    text={"...loading"}
                    loading={loading}
                    buttonVariant={"whiteContained"}
                  />
                ))
              : teamMembers.map((member, index) => (
                  <ButtonSelection
                    onSelect={() => handleChange(member.email)}
                    key={index}
                    text={`${member?.first_name ?? ""} ${
                      member?.last_name ?? ""
                    }`}
                    loading={false}
                    isSelected={createProjectState.team_members.includes(
                      member?.email
                    )}
                  />
                ))}
          </Stack>
        </Stack>
      </Stack>
    </JobCardWrapper>
  );
};

export default ExistingMembers;
