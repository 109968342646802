import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { InterviewCalenderIcon } from "../../../../../../../../assets/icons/Icons";
import AtsDataTable from "../../../../../../../../components/AtsDataTable";
import AtsText from "../../../../../../../../components/AtsText";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { useInterviews } from "../../../../../../../../providers/InterviewsProvider";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import { GetCandidateInterviewListByJobResponse } from "../../../../../../../../types/calendarTypes";
import { getDateFormatMMMDYYYYY } from "../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";
import InterviewInfo from "./modules/InterviewInfo";
import { InterviewListHeader } from "./modules/InterviewListHeader";
import NoInterviewDetails from "./modules/NoInterviewDetails";
import AtsRoundedButton from "../../../../../../../../components/AtsRoundedButton";
import { InterviewStatusEnum } from "../../../../../../../../enums/commonEnums";
import { useAppSelector } from "../../../../../../../../redux/store";
import { getInterviewState } from "../../../../../../../../redux/slices/interviewSlice";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "job_title",
    header: "Job name",
    width: "200px",
    minWidth: "150px",
  },
  {
    key: "interviewId",
    header: "Interview Id",
    width: "130px",
    minWidth: "130px",
  },
  {
    key: "date",
    header: "Date",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "time",
    header: "Time",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "duration",
    header: "Duration",
    width: "auto",
    minWidth: "100px",
  },
  {
    key: "status",
    header: "Status",
    width: "200px",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "auto",
    minWidth: "50px",
  },
];

const InterviewList = () => {
  const {
    getInterviewList,
    interviewList,
    interviewListLoading,
    setInterviewInfo,
    getInterviewDetails,
  } = useInterviews();
  const [collapsedRows, setCollapsedRows] = useState<string>("");
  const { interviewTab } = useAppSelector(getInterviewState);

  useEffect(() => {
    setCollapsedRows("");
    getInterviewList();
  }, [interviewTab]);

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows === rowId) {
      setCollapsedRows("");
    } else {
      setCollapsedRows(rowId);
    }
  };

  const cellStyles = {
    padding: "0px 10px",
  };

  const customizeRow = (
    column: IColumnProps,
    list: GetCandidateInterviewListByJobResponse
  ) => {
    switch (column.key) {
      case "job_title":
        return (
          <td
            className={`${
              collapsedRows !== list.nid ? "customTD" : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Box>
                <InterviewCalenderIcon />
              </Box>
              <AtsTitleText
                fs={12}
                fw={600}
                text={`${list.job_title}`}
                textColor="#000"
              />
            </Stack>
          </td>
        );
      case "interviewId":
        return (
          <td
            className={`${
              collapsedRows !== list.nid ? "customTD" : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={`#INT-${list.nid}`} />
            </Stack>
          </td>
        );
      case "date":
        return (
          <td
            className={`${
              collapsedRows !== list.nid ? "customTD" : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                text={
                  isNotNullOrUndefined(list.date)
                    ? getDateFormatMMMDYYYYY(list.date)
                    : "--"
                }
              />
            </Stack>
          </td>
        );
      case "time":
        return (
          <td
            className={`${
              collapsedRows !== list.nid ? "customTD" : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                text={
                  isNotNullOrUndefined(list.date)
                    ? moment(list.date).format("h:mm A")
                    : "--"
                }
              />
            </Stack>
          </td>
        );
      case "duration":
        return (
          <td
            className={`${
              collapsedRows !== list.nid ? "customTD" : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={`${list.duration} min`} />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td
            className={`${
              collapsedRows !== list.nid ? "customTD" : "borderTopLeftRadius"
            }`}
            style={cellStyles}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                fs={12}
                fw={500}
                text={list.status}
                buttonVariant={getButtonColor(list.status)}
              />
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = interviewList.map(
      (value: GetCandidateInterviewListByJobResponse, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                backgroundColor:
                  collapsedRows === value.nid ? "#e2ebff" : "white",
                borderBottom:
                  collapsedRows === value.nid ? "none" : BORDERS.GRAY,
                height: "68px",
                margin: "0px 8px",
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
              <td
                className={`${
                  collapsedRows !== value.nid
                    ? "customTDLast"
                    : "borderTopRightRadius"
                }`}
                style={cellStyles}
              >
                <IconButton
                  onClick={() => {
                    toggleCollapse(value.nid);
                    setInterviewInfo(value);
                    // collapsedRows === value.nid &&
                    //   getInterviewDetails(value.nid);
                  }}
                >
                  <KeyboardArrowDown
                    sx={{
                      transition: "all 500ms ease-in-out",
                      ...(collapsedRows === value.nid && {
                        rotate: "180deg",
                      }),
                    }}
                  />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td
                colSpan={12}
                style={{
                  marginBottom: 0,
                  padding: 0,
                }}
              >
                {collapsedRows === value.nid && <InterviewInfo />}
              </td>
            </tr>
          </React.Fragment>
        );
      }
    );
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "10px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <InterviewListHeader headerText={column.header} />
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {interviewListLoading ? (
        <Stack gap={"10px"}>
          {Array.from(new Array(4)).map((_, index) => (
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          ))}
        </Stack>
      ) : interviewList.length > 0 ? (
        <AtsDataTable
          isHidBottomBorder
          isBoxShadow
          data={interviewList}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={interviewListLoading}
        />
      ) : (
        <NoInterviewDetails />
      )}
    </Box>
  );
};

export default InterviewList;

const getButtonColor = (status: string) => {
  switch (status) {
    case InterviewStatusEnum.Canceled:
      return "dangerContained";
    case InterviewStatusEnum.Confirmed:
    case InterviewStatusEnum.Completed:
      return "greenContained";
    case InterviewStatusEnum.Scheduled:
      return "orangeContained";
    case InterviewStatusEnum.SchedulingInProgress:
      return "waringContained";
    case InterviewStatusEnum.ToBeRescheduled:
      return "purpleContained";

    default:
      return "primaryContained";
  }
};
