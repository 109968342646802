import { Box, Collapse, Stack } from "@mui/material";
import {
  ArrowDown,
  ArrowDown2,
  SearchNormal,
  SearchNormal1,
} from "iconsax-react";
import React, { useState } from "react";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";
import PdfIcon from "../../../../../../../assets/images/pdfIcon.png";
import { useClickOutSide } from "../../../../../../../utils/common";
import OpenLink from "../../../../../../../assets/images/openLink.png";

const resumes = [
  {
    title: "Globex Interactive",
    pdfLabel: "Trevor Cunninghan Resume.pdf",
    size: "432 KB",
    icon: PdfIcon,
  },
  {
    title: "Lumex Diamonds",
    pdfLabel: "Trevor Cunninghan Resume.pdf",
    size: "432 KB",
    icon: PdfIcon,
  },
  {
    title: "First HR Consulting",
    pdfLabel: "Trevor Cunninghan Resume.pdf",
    size: "432 KB",
    icon: PdfIcon,
  },
];

const ContractsTab = () => {
  const [show, setShow] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  return (
    <>
      <Stack gap={"19px"}>
        <AtsTitleText text={`Contracts`} fs={18} fw={600} />
        <AtsRowBetween>
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              minHeight={30}
              width={232}
              sx={{
                px: "16px",
                border: BORDERS.GRAY,
                borderRadius: "38px",
              }}
            >
              <input
                type="text"
                className="customInput"
                placeholder="Search contract name"
                style={{
                  width: "100%",
                }}
                // value={inputValue}
                // onChange={(e) => {
                //   const value = e.target.value;
                //   setInputValue(value);
                // }}
              />
              <SearchNormal1 size={16} />
            </Stack>
            <Box
              ref={domNode}
              sx={{
                position: "relative",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                sx={{
                  cursor: "pointer",
                  px: "14px",
                  py: "6px",
                  border: BORDERS.GRAY,
                  borderRadius: "48px",
                }}
                onClick={() => setShow(!show)}
              >
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                  <AtsTitleText
                    text={"Sort by:"}
                    textColor={COLORS.DARK_GRAY}
                    fs={14}
                    fw={500}
                  />
                  <AtsTitleText
                    text={"Newest"}
                    textColor={COLORS.DARK_GRAY}
                    fs={14}
                    fw={400}
                  />
                </Stack>
                <Stack>
                  <ArrowDown2 size={16} />
                </Stack>
              </Stack>
              <Box
                sx={{
                  position: "absolute",
                  bgcolor: "white",
                  right: "0px",
                  // top: "50px",
                  zIndex: 9999,
                  width: "-webkit-fill-available",
                }}
              >
                <Collapse
                  in={show}
                  sx={{
                    border: BORDERS.GRAY,

                    borderRadius: "5px",
                  }}
                >
                  <Stack
                    gap={"7px"}
                    sx={{
                      p: "10px",
                    }}
                  >
                    {["Newest", "Oldest"].map((value, index) => (
                      <Stack
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <AtsTitleText
                          key={index}
                          text={value}
                          fs={12}
                          fw={500}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Collapse>
              </Box>
            </Box>
          </>
        </AtsRowBetween>

        <Box
          sx={{
            height: "calc(100% - 155px)",
          }}
        >
          <Stack gap={"16px"}>
            {resumes.map((resume, index) => (
              <Stack
                gap={"12px"}
                sx={{
                  position: "relative",
                  height: "auto",
                  py: "19px",
                  ...(resumes.length - 1 !== index && {
                    borderBottom: BORDERS.GRAY,
                  }),
                }}
              >
                <Box
                  sx={{
                    bgcolor: BG_COLORS.PRIMARY,
                    borderRadius: "40px",
                    py: "4px",
                    px: "10px",
                    width: "fit-content",
                  }}
                >
                  <AtsTitleText
                    text={resume.title}
                    textColor={COLORS.PRIMARY}
                    fs={12}
                    fw={500}
                  />
                </Box>
                <Stack key={index} direction={"row"} alignItems={"flex-start"}>
                  <Stack direction={"row"} gap={"21px"} sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        border: BORDERS.GRAY,
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "52px",
                        height: "52px",
                      }}
                    >
                      <img
                        src={PdfIcon}
                        alt="Nvidia Logo"
                        style={{ width: 21 }}
                      />
                    </Box>
                    <Stack gap={"10px"}>
                      <AtsTitleText fs={16} fw={600} text={resume.pdfLabel} />
                      <AtsTitleText
                        fs={14}
                        fw={400}
                        textColor={COLORS.DARK_GRAY}
                        text={`${resume.size} KB`}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} gap={1}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      sx={{ pr: 2 }}
                    >
                      <div
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <img
                            src={OpenLink}
                            alt="Nvidia Logo"
                            style={{ width: 18 }}
                          />
                          <AtsTitleText
                            fs={14}
                            fw={600}
                            textColor={COLORS.PRIMARY}
                            text={"Open Link"}
                          />
                        </Stack>
                      </div>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default ContractsTab;
