import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../theme";

const text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.`;

const rolesAndResponsibilities = [
  {
    title: "Propose changes to current Java infrastructure",
    text1: text,
    text2: text,
    text3: text,
  },
  {
    title: "Manage Java and Java EE application development",
    text1: text,
    text2: text,
    text3: text,
  },
  {
    title: "Contribute to all stages of software development lifecycle",
    text1: text,
    text2: text,
    text3: text,
  },
  {
    title: "Propose changes to current Java infrastructure",
    text1: text,
    text2: text,
    text3: text,
  },
  {
    title: "Propose changes to current Java infrastructure",
    text1: text,
    text2: text,
    text3: text,
  },
];

const ResponsibilitiesTab = () => {
  return (
    <>
      <Stack>
        <Stack
          sx={{
            borderBottom: BORDERS.GRAY,
          }}
          pb={"21px"}
        >
          <AtsTitleText text={`Roles and Responsibilites`} fs={18} fw={600} />
        </Stack>
        <Stack>
          {rolesAndResponsibilities.map((role, index) => (
            <Accordion
              disableGutters
              // expanded={expandedPanels.includes(index)}
              // onChange={handleChange}
              sx={{
                "&.MuiPaper-root": {
                  boxShadow: "none !important",
                  borderBottom: BORDERS.GRAY,
                  backgroundColor: "transparent",
                  "::before": {
                    bgcolor: "transparent ",
                  },
                },
              }}
            >
              <AccordionSummary
                sx={{
                  px: 0,
                }}
                expandIcon={<ArrowDown2 size="24" color={COLORS.BLACK} />}
              >
                <AtsRow gap={"13px"}>
                  <>
                    <Box>
                      <svg
                        width="33"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity=".3"
                          d="M26.936 6.24c3.29 3.68 1.178 10.938-4.72 16.21-5.897 5.274-13.345 6.566-16.636 2.886-3.291-3.68 2.901-13.824 8.798-19.097 5.898-5.274 9.267-3.68 12.558 0z"
                          fill="#D03660"
                        />
                        <path
                          d="m13.086 17.925 1.625 1.625 4.333-4.333"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.833 8.5h4.333c2.167 0 2.167-1.084 2.167-2.167 0-2.167-1.084-2.167-2.167-2.167h-4.333c-1.084 0-2.167 0-2.167 2.167 0 2.166 1.083 2.166 2.167 2.166z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.333 6.355c3.608.195 5.417 1.528 5.417 6.479v6.5c0 4.333-1.083 6.5-6.5 6.5h-6.5c-5.417 0-6.5-2.167-6.5-6.5v-6.5c0-4.94 1.81-6.284 5.417-6.479"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Box>
                    <AtsTitleText text={role.title} fs={14} fw={600} />
                  </>
                </AtsRow>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  px: 0,
                }}
              >
                <Stack gap={"10px"} ml={"50px"}>
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <Box
                      sx={{
                        mt: "5px",
                        minWidth: "5px",
                        minHeight: "5px",
                        bgcolor: COLORS.BLACK,
                        borderRadius: "50%",
                      }}
                    ></Box>

                    <AtsTitleText
                      text={role.text1}
                      fs={12}
                      fw={400}
                      lineHeight={1.5}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <Box
                      sx={{
                        mt: "5px",
                        minWidth: "5px",
                        minHeight: "5px",
                        bgcolor: COLORS.BLACK,
                        borderRadius: "50%",
                      }}
                    ></Box>

                    <AtsTitleText
                      text={role.text2}
                      fs={12}
                      fw={400}
                      lineHeight={1.5}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    gap={"5px"}
                  >
                    <Box
                      sx={{
                        mt: "5px",
                        minWidth: "5px",
                        minHeight: "5px",
                        bgcolor: COLORS.BLACK,
                        borderRadius: "50%",
                      }}
                    ></Box>

                    <AtsTitleText
                      text={role.text3}
                      fs={12}
                      fw={400}
                      lineHeight={1.5}
                    />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default ResponsibilitiesTab;
