import React, { useEffect, useState } from "react";
import JobCardWrapper from "../components/JobCardWrapper";
import AtsSwitch, { ISwitch } from "../../../components/AtsSwitch";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setBudgetError,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import AtsSlider from "../../../components/AtsSlider";
import { Alert, Box, Stack } from "@mui/material";
import { BORDERS, COLORS } from "../../../theme";
import AtsTitleText from "../../../components/AtsTitleText";
import { formattedAmount } from "../../../utils/common";
import { JobsService } from "../../../Api/jobsListingServices";
import { JobCategoriesResponse } from "../../../types/jobsTypes";
import ImportantThings from "../components/ImportantThings";
import { JobTypeEnum } from "../../../enums/jobsEnums";

const YourBudget = () => {
  const dispatch = useAppDispatch();
  const { postOpportunity, jobDetailsById, budgetError } = useAppSelector(
    getPostOpportunityState
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<JobCategoriesResponse[]>([]);
  const [active, setActive] = useState<string>("");
  const [price, setPrice] = useState<number[]>([]);

  const handleSetPrice = (newValue: number[]) => {
    const lowValue = newValue[0].toString();
    const highValue = newValue[1].toString();
    if (active === "Annual") {
      dispatch(
        setPostOpportunity({
          ...postOpportunity,
          job_salary_high: highValue,
          job_salary_low: lowValue,
        } as PostOpportunityProps)
      );
    } else {
      dispatch(
        setPostOpportunity({
          ...postOpportunity,
          job_pay_rate_high: highValue,
          job_pay_rate_low: lowValue,
        } as PostOpportunityProps)
      );
    }
  };

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setPrice(newValue as number[]);
    handleSetPrice(newValue as number[]);
  };
  const handleChangeRate = (key: keyof PostOpportunityProps, value: string) => {
    console.log("handleChangeRate", key, value);
    dispatch(
      setPostOpportunity({
        ...postOpportunity,
        [key]: value,
      } as PostOpportunityProps)
    );
  };
  const fetchPaymentType = async () => {
    setLoading(true);
    try {
      const response = await JobsService.getJobPaymentType({});
      const getPaymentType = response.results;
      if (getPaymentType.length) {
        const filteredPaymentType = getPaymentType.filter(
          (payment) => payment.title === "Hourly" || payment.title === "Annual"
        );
        setPaymentType(filteredPaymentType);
        // if (jobDetailsById.job_type === JobTypeEnum.DIRECT_HIRE) {
        //   setActive(filteredPaymentType[1]?.title);
        //   setPrice([1, 100000]);
        // } else {
        //   setActive(filteredPaymentType[0]?.title);
        //   setPrice([0, 50]);
        // }
        dispatch(
          setPostOpportunity({
            ...postOpportunity,
            job_payment_type_id: filteredPaymentType[0]?.id,
          } as PostOpportunityProps)
        );
      }
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (active === "Annual") {
      setPrice([
        Number(postOpportunity.job_salary_low),
        Number(postOpportunity.job_salary_high),
      ]);
      // handleSetPrice([100, 1000000]);
    } else {
      setPrice([
        Number(postOpportunity.job_pay_rate_low),
        Number(postOpportunity.job_pay_rate_high),
      ]);
      // handleSetPrice([1, 50]);
    }
  }, [
    postOpportunity.job_salary_low,
    postOpportunity.job_salary_high,
    postOpportunity.job_pay_rate_low,
    postOpportunity.job_pay_rate_high,
    active,
  ]);

  useEffect(() => {
    if (jobDetailsById.job_type === JobTypeEnum.DIRECT_HIRE) {
      setActive("Annual");
      setPrice([
        Number(postOpportunity.job_salary_low),
        Number(postOpportunity.job_salary_high),
      ]);
      // handleSetPrice([100, 1000000]);
    } else {
      setActive("Hourly");
      setPrice([
        Number(postOpportunity.job_pay_rate_low),
        Number(postOpportunity.job_pay_rate_high),
      ]);
      // handleSetPrice([1, 50]);
    }
    fetchPaymentType();
  }, []);
  return (
    <>
      <JobCardWrapper
        header="Now set your budget"
        subText="Set a rate of pay for your opportunity"
      >
        <>
          <AtsSwitch
            sx={{ flex: 1 }}
            switchData={paymentType.map((payment) => ({
              title: payment.title,
              type: payment.id,
            }))}
            active={active}
            onChange={(selected) => {}}
          />
          <Stack
            gap={1.5}
            sx={{
              px: 5,
              py: 6.2,
              bgcolor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <AtsSlider
              max={active === "Hourly" ? 100 : 5000000}
              min={active === "Hourly" ? 0 : 1}
              onChange={handlePriceChange}
              value={price}
            />
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
              // sx={{
              //   ml: active === "Hourly" ? 0 : "55px",
              // }}
            >
              <RenderBudgetRateComponent
                value={
                  active === "Hourly"
                    ? postOpportunity.job_pay_rate_low
                    : postOpportunity.job_salary_low
                }
                type={active}
                width={85}
                handleRate={(value) => {
                  if (active === "Hourly") {
                    // if (Number(value) >= 1 && Number(value) <= 100) {
                    handleChangeRate(
                      active === "Hourly"
                        ? "job_pay_rate_low"
                        : "job_salary_low",
                      value
                    );
                    // }
                  } else {
                    // if (Number(value) >= 100 && Number(value) <= 50000000) {
                    handleChangeRate(
                      active === "Hourly"
                        ? "job_pay_rate_low"
                        : "job_salary_low",
                      value
                    );
                    // }
                  }
                }}
              />
              <AtsTitleText text={"to"} fs={"0.625rem"} />
              <RenderBudgetRateComponent
                value={
                  active === "Hourly"
                    ? postOpportunity.job_pay_rate_high
                    : postOpportunity.job_salary_high
                }
                type={active}
                width={active === "Hourly" ? 85 : 140}
                handleRate={(value) => {
                  if (active === "Hourly") {
                    // if (Number(value) >= 1 && Number(value) <= 100) {
                    handleChangeRate(
                      active === "Hourly"
                        ? "job_pay_rate_high"
                        : "job_salary_high",
                      value
                    );
                    // }
                  } else {
                    // if (Number(value) >= 100 && Number(value) <= 5000000) {
                    handleChangeRate(
                      active === "Hourly"
                        ? "job_pay_rate_high"
                        : "job_salary_high",
                      value
                    );
                    // }
                  }
                }}
              />
            </Stack>
            {active !== "Hourly" && (
              <Box sx={{ textAlign: "center" }}>
                <AtsTitleText fs={14} fw={400} text={`per year`} />
              </Box>
            )}
            {budgetError.length > 0 && (
              <Alert severity="error">{budgetError}</Alert>
            )}
          </Stack>
          <Stack mt={3}>
            <ImportantThings
              header="Find someone faster"
              subText="Offering 100% remote work increases your chances of finding the best, faster."
            />
          </Stack>
        </>
      </JobCardWrapper>
    </>
  );
};

export default YourBudget;

const RenderBudgetRateComponent = ({
  value,
  type,
  width,
  handleRate,
}: {
  value: string;
  type: string;
  width: number;
  handleRate: (value: string) => void;
}) => {
  const formatNumber = (value) => {
    if (!value) return 0;
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = new Intl.NumberFormat("en-US").format(integerPart);
    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart.substring(0, 2)}`
      : formattedInteger;
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const formattedAmount = input
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    handleRate(formattedAmount);
  };

  return (
    <Stack gap={0.5}>
      <Stack
        direction={"row"}
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "6px",
          minHeight: 47,
          // minWidth: width,
          width: 124,
          position: "relative",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            width: "27px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
        >
          <AtsTitleText
            text={"$"}
            fs={18}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Box>
        <Box
          sx={{
            bgcolor: "#fff",
            fontSize: 18,
            color: COLORS.BLACK,
            fontWeight: 600,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <input
            type="text"
            className="rateInput"
            style={{
              fontWeight: 700,
              fontSize: 14,
              width: "90%",
            }}
            value={formatNumber(value)}
            onChange={handleChange}
          />
        </Box>
        {type === "Hourly" && (
          <Box sx={{ position: "absolute", bottom: -20 }}>
            <AtsTitleText fs={14} fw={400} text={`per hour`} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
