import { Box, Stack } from "@mui/material";
import AtsTabs from "../../../../../../../../components/AtsTabs";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  getInterviewState,
  setInterviewTab,
  setIsInterviewDetails,
  setNextTab,
} from "../../../../../../../../redux/slices/interviewSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import InterviewList from "./InterviewList";

const InterviewTab = () => {
  const dispatch = useAppDispatch();
  const { interviewTab } = useAppSelector(getInterviewState);

  return (
    <>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <AtsTitleText text="Interviews" fs={20} fw={600} />
          </Stack>
        </Stack>
        <AtsTabs
          value={interviewTab}
          onChange={(event, newValue) => {
            dispatch(setInterviewTab(newValue));
            dispatch(setNextTab(0));
            dispatch(setIsInterviewDetails(false));
          }}
          tabData={["Job Interviews", "All Interviews"]}
        />
        <Box
          className="scrollHide"
          sx={{
            height: "calc(100% - 80px)",
            overflow: "scroll",
          }}
        >
          <InterviewList />
        </Box>
      </Stack>
    </>
  );
};

export default InterviewTab;
