import { Box, Stack } from "@mui/material";
import ProfilePic from "../../../assets/images/candidate.png";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";

const MyProfileInfo = () => {
  const { accountDetailsState, companyInfoState } = useCreateAccount();
  const userName = `${accountDetailsState.first_name} ${accountDetailsState.last_name}`;
  const address = `${companyInfoState?.company_address?.locality ?? ""}, ${
    companyInfoState?.company_address?.country_code ?? ""
  }`;
  return (
    <Stack gap={"32px"}>
      <Box
        sx={{
          width: 200,
          height: 200,
        }}
      >
        <img
          src={ProfilePic}
          alt="Nvidia Logo"
          style={{
            height: "auto",

            width: "100%",
            borderRadius: "100%",
          }}
        />
      </Box>
      <Stack textAlign={"center"} gap={"10px"}>
        <AtsTitleText text={userName} fs={18} fw={600} textColor="#000" />
        <Stack textAlign={"center"} gap={"2px"}>
          <AtsTitleText
            text={accountDetailsState.job_title}
            fs={14}
            fw={500}
            textColor={COLORS.DARK_GRAY}
          />
          <AtsTitleText
            text={address}
            fs={12}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MyProfileInfo;
