import { Box, Stack, Typography } from "@mui/material";
import { Eye, ReceiptEdit, Star } from "iconsax-react";
import PdfIcon from "../../../assets/images/pdfIcon.png";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRow from "../../../components/AtsRow";
import AtsTitleText from "../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../theme";

const dummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

const InActiveContractCard = () => {
  return (
    <>
      <Box
        sx={{
          border: BORDERS.GRAY,
          borderRadius: "10px",
          minHeight: 188,
          mx: "9px",
        }}
      >
        <AtsRow
          sx={{
            height: "100%",
          }}
        >
          <>
            <Box
              sx={{
                position: "relative",
                minWidth: 252,
                minHeight: 188,
                bgcolor: BG_COLORS.GRAY,
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <ReceiptEdit size="90" color={COLORS.LIGHT_GRAY} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 20,
                  right: 10,
                }}
              >
                <Stack gap={"3px"} textAlign={"end"}>
                  <AtsRow gap={"3px"}>
                    <>
                      <Star />
                      <AtsTitleText
                        text={"talent"}
                        textColor={COLORS.LIGHT_GRAY}
                      />
                    </>
                  </AtsRow>
                  <AtsTitleText text={"MSA"} textColor={"#000"} />
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                // my: "10px",
                height: 188,
              }}
            >
              <AtsRow
                sx={{
                  my: "10px",
                  height: "89%",
                }}
              >
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      gap={"55px"}
                      sx={{
                        pl: "40px",
                        pr: "23px",
                        py: "19px",
                      }}
                    >
                      <Stack gap={"15px"}>
                        <AtsTitleText
                          fs={16}
                          fw={600}
                          text={"Included in your contract"}
                          textColor={"#000"}
                        />
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: 1.43,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {dummyText}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      minWidth: 190,
                      height: "85%",
                      borderLeft: BORDERS.GRAY,
                      pl: "25px",
                      pr: "19px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Stack gap={"18px"} sx={{}}>
                      <AtsRoundedButton
                        text="View contract"
                        isInitial
                        buttonVariant="outline"
                        buttonWidth={129}
                        buttonHeight={26}
                        fs={12}
                        fw={400}
                        startIcon={<Eye color={COLORS.PRIMARY} size={16} />}
                      />
                      <AtsRoundedButton
                        text="Download contract"
                        isInitial
                        buttonVariant="outline"
                        buttonWidth={158}
                        buttonHeight={26}
                        fs={12}
                        fw={400}
                        startIcon={<img src={PdfIcon} alt="pdf" height={16} />}
                      />
                    </Stack>
                  </Box>
                </>
              </AtsRow>
            </Box>
          </>
        </AtsRow>
      </Box>
    </>
  );
};

export default InActiveContractCard;

const DateText = ({ text, value }) => {
  return (
    <Stack gap={"2px"}>
      <AtsTitleText text={text} fs={12} fw={400} textColor={COLORS.DARK_GRAY} />
      <AtsTitleText text={value} fs={14} fw={600} />
    </Stack>
  );
};
