import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectService } from "../../../../../../Api/projectServices";
import AtsCard from "../../../../../../components/AtsCard";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import {
  getProjectDetailsState,
  setCurrentIndex,
  setSelectedProjectDetailsTab,
} from "../../../../../../redux/slices/projectDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { COLORS } from "../../../../../../theme";
import { TeamMemberProps } from "../../../../../../types/projectTypes";
import ActiveTeamMemberList from "./ActiveTeamMemberList";

const TeamMember = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { selectedProjectDetailsTab, currentIndex, projectTab } =
    useAppSelector(getProjectDetailsState);
  const [activeTeamMembers, setActiveTeamMembers] = useState<TeamMemberProps[]>(
    []
  );
  const [inActiveTeamMembers, setInactiveTeamMembers] = useState<
    TeamMemberProps[]
  >([]);
  const [activeTotalItem, setActiveTotalItem] = useState<number>(0);
  const [inactiveTotalItem, setInactiveTotalItem] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [inactiveLoading, setInactiveLoading] = useState<boolean>(false);

  const getActiveMembers = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getActiveProjectMember({
        project_id: id,
      });
      setActiveTeamMembers(response.results);
      if (typeof response.pager.count === "number") {
        setActiveTotalItem(response.pager.count);
      } else {
        setActiveTotalItem(Number(response.pager.count));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getInactiveMembers = async () => {
    setInactiveLoading(true);
    try {
      const response = await ProjectService.getInActiveProjectMember({
        project_id: id,
      });
      setInactiveTeamMembers(response.results);
      if (typeof response.pager.count === "number") {
        setInactiveTotalItem(response.pager.count);
      } else {
        setInactiveTotalItem(Number(response.pager.count));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInactiveLoading(false);
    }
  };

  useEffect(() => {
    getActiveMembers();
  }, []);
  useEffect(() => {
    getInactiveMembers();
  }, []);

  // useEffect(() => {
  //   Object.keys(selectedContractor).length && getContractorDetails();
  // }, [selectedContractor]);

  const backItem = () => {
    dispatch(setCurrentIndex(currentIndex - 1));
    const findProjectTab = projectTab.find(
      (_, index) => index === currentIndex - 1
    );
    dispatch(setSelectedProjectDetailsTab(findProjectTab));
  };

  return (
    <Stack
      gap={"10px"}
      sx={{
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <AtsTitleText text={selectedProjectDetailsTab.name} fs={20} fw={600} />
      </Stack>
      <AtsTitleText
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
        text={selectedProjectDetailsTab.description}
      />

      <Box
        component={"div"}
        className="scrollHide"
        sx={{
          height: "calc(100% - 130px)",
          overflow: "auto",
        }}
      >
        <Stack gap={"25px"} mt={"20px"}>
          <Stack gap={"14px"}>
            <AtsTitleText
              fs={16}
              fw={500}
              text={`${activeTotalItem} Active member(s)`}
              textColor={COLORS.PRIMARY}
            />
            <AtsCard sx={{ mb: "10px" }}>
              <Box
                sx={{
                  px: "19px",
                  pb: "27px",

                  // py: 2,
                }}
              >
                <ActiveTeamMemberList
                  teamMember={activeTeamMembers}
                  loading={loading}
                />
              </Box>
            </AtsCard>
          </Stack>
          <Stack gap={"14px"}>
            <AtsTitleText
              fs={16}
              fw={500}
              text={`${inactiveTotalItem} Inactive member(s)`}
              textColor={COLORS.PRIMARY}
            />
            <AtsCard sx={{ mb: "10px" }}>
              <Box
                sx={{
                  px: "19px",
                  pb: "27px",

                  // py: 2,
                }}
              >
                <ActiveTeamMemberList
                  teamMember={inActiveTeamMembers}
                  loading={inactiveLoading}
                />
              </Box>
            </AtsCard>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pt={1}
      >
        <AtsRoundedButton
          text="Back"
          buttonVariant="outline"
          buttonHeight={34}
          buttonWidth={79}
          onClick={backItem}
        />

        {/* <AtsRoundedButton
          text="Next"
          buttonHeight={34}
          buttonWidth={113}
          // onClick={nextItem}
        /> */}
      </Stack>
    </Stack>
  );
};

export default TeamMember;
