import { Grid, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { ContractFontsEnum } from "../../../enums/contractsEnums";
import AtsRow from "../../../components/AtsRow";
import { useContracts } from "../../../providers/ContractsProvider";
import { BG_COLORS, BORDERS } from "../../../theme";

const SignatureFontStyleBox = () => {
  const { signatureText, setFont, font } = useContracts();
  return (
    <>
      <Stack gap={"20px"}>
        <AtsTitleText
          text={"Select a style for your signature."}
          textColor="#000"
          fs={16}
          fw={600}
        />
        <Grid container spacing={"14px"}>
          {Object.values(ContractFontsEnum).map((fontText, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <AtsRow
                justifyContent={"center"}
                sx={{
                  bgcolor: BG_COLORS.PRIMARY,
                  py: "40px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  border: fontText === font ? BORDERS.PRIMARY : "transparent",
                }}
                onClick={() => setFont(fontText as ContractFontsEnum)}
              >
                <>
                  {" "}
                  <AtsTitleText
                    className={fontText}
                    text={signatureText}
                    textColor="#000"
                    fs={34}
                    fw={400}
                  />
                </>
              </AtsRow>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
};

export default SignatureFontStyleBox;
