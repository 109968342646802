import { Grid, Skeleton, Stack } from "@mui/material";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";
import InterviewButton from "./InterviewButton";
import moment from "moment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import {
  getInterviewState,
  setSelectedSlot,
  setTime,
} from "../../../../../../../../redux/slices/interviewSlice";
import { CalendarServices } from "../../../../../../../../Api/calendarServices";
import { AllUserAvailabilityResponse } from "../../../../../../../../types/calendarTypes";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";
import AtsRow from "../../../../../../../../components/AtsRow";

const timeSlot = [
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 AM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
];

interface IProps {
  allUserAvailability: AllUserAvailabilityResponse | null;
}

const TimeSlot = ({ allUserAvailability }: IProps) => {
  const dispatch = useAppDispatch();
  const { count, selectedDate, selectedSlot, createInterview } =
    useAppSelector(getInterviewState);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeSlot, setTimeSlot] = useState([]);

  const selecteSlot = (slot: string) => {
    if (selectedSlot === slot) {
      dispatch(setSelectedSlot(""));
    } else {
      dispatch(setSelectedSlot(slot));
    }
  };

  const filterCurrentDayAvailability = (
    getAllAvailability,
    googleAvailability,
    unAvailability
  ) => {
    // Get current date and the corresponding day of the week
    const currentDate = new Date(selectedDate as any);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDay = daysOfWeek[currentDate.getDay()];

    const isSlotInList = (slot, slotList) => {
      return slotList.some((listSlot) => {
        return listSlot.start === slot.start && listSlot.end === slot.end;
      });
    };

    const getGoogleSlotsForDate = (email, googleAvailability) => {
      const googleData = googleAvailability.find(
        (g) => g.email === email && g.status === "success"
      );
      if (!googleData) return [];

      return googleData.data
        .filter((event) => {
          const eventDate = new Date(event.start.dateTime).toDateString();
          return eventDate === currentDate.toDateString();
        })
        .map((event) => ({
          start: event.start.dateTime.split("T")[1].substring(0, 5),
          end: event.end.dateTime.split("T")[1].substring(0, 5),
        }));
    };

    const result = [];

    for (const email in getAllAvailability) {
      if (getAllAvailability.hasOwnProperty(email)) {
        const userAvailability = getAllAvailability[email].availability;

        const currentDayAvailability = userAvailability?.find(
          (day) => day.day === currentDay && day.is_unavailable === 0
        );
        if (currentDayAvailability) {
          const googleSlots = getGoogleSlotsForDate(email, googleAvailability);

          let filteredSlots = currentDayAvailability.slots.filter(
            (slot) =>
              !isSlotInList(slot, unAvailability.slots) &&
              !isSlotInList(slot, googleSlots)
          );

          const getSlots = {
            slots: filteredSlots,
          };
          result.push([...filteredSlots]);
          // result[email] = {
          //   nid: getAllAvailability[email].nid,
          //   day: currentDay,
          //   slots: filteredSlots,
          // };
        }
      }
    }
    return result;
  };
  const unAvailabilityOfUsers = async () => {
    setLoading(true);
    try {
      const unAvailabilityResponse =
        await CalendarServices.getUnAvailabilityOfUsers({
          emails: createInterview.interviewers,
          date: moment(selectedDate as any).format("YYYY-MM-DD"),
        });

      const googleEventResponse =
        await CalendarServices.getAllCalendarEventsOfUsers({
          emails: createInterview.interviewers,
          date: moment(selectedDate as any).format("YYYY-MM-DD"),
        });
      const currentDayAvailability = filterCurrentDayAvailability(
        allUserAvailability,
        googleEventResponse,
        unAvailabilityResponse.data
      );
      const flattenedArray = currentDayAvailability.flat();

      const uniqueSlots = flattenedArray.filter(
        (slot, index, self) =>
          index ===
          self.findIndex((s) => s.start === slot.start && s.end === slot.end)
      );
      setTimeSlot(uniqueSlots);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isNotNullOrUndefined(allUserAvailability)) {
      unAvailabilityOfUsers();
    }
  }, [selectedDate, allUserAvailability]);

  const formatToISOString = (dateStr, timeStr) => {
    const date = new Date(dateStr);

    const [hours, minutes] = timeStr.split(":").map(Number);

    date.setHours(hours, minutes);

    return date.toISOString();
  };

  return (
    <>
      <Container maxWidth="xs" sx={{ pt: "7px" }}>
        <Stack gap={1}>
          <Stack
            sx={{
              py: "10px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              fs={14}
              fw={600}
              text={dateFormatDDDDMMMMD(selectedDate)}
            />
          </Stack>
          <Stack pt={2}>
            <AtsTitleText
              sx={{
                fontSize: 11,
                fontWeight: 500,
                color: COLORS.LIGHT_GRAY,
              }}
              text="SELECT TIME SLOT"
            />
            <AtsRow
              columnGap={"20px"}
              rowGap={"10px"}
              flexWrap={"wrap"}
              mt={"10px"}
            >
              <>
                {loading
                  ? Array.from(new Array(9)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        height={35}
                        width={100}
                      />
                    ))
                  : timeSlot.map((slot, index) => {
                      console.log("slot", slot, selectedDate);
                      const time = moment(slot?.start, "HH:mm").format(
                        "hh:mm A"
                      );
                      return (
                        <InterviewButton
                          key={index}
                          value={time}
                          isSelected={selectedSlot === time}
                          onClick={() => {
                            selecteSlot(time);
                            const startDate = formatToISOString(
                              selectedDate,
                              slot?.start
                            );
                            const endDate = formatToISOString(
                              selectedDate,
                              slot?.end
                            );
                            dispatch(
                              setTime({
                                start: startDate,
                                end: endDate,
                              })
                            );
                          }}
                        />
                      );
                    })}
              </>
            </AtsRow>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default TimeSlot;
