import React, { createContext, useContext, useRef, useState } from "react";
import { SOWResponse } from "../types/contractTypes";
import { ContractFontsEnum, SignatureStepEnum } from "../enums/contractsEnums";
import SignaturePad from "react-signature-canvas";

export enum ContractsTabsEnums {
  MasterContracts = "Master Contracts",
  StatementsOfWork = "Statements of Work",
}

const dummyStatementOfWork = [
  {
    isActive: false,
    isE_Sign: true,
    isRenew: false,
    id: "1234",
    contractorName: "Frank Kenneth",
    job: "Front-end Drupal developer",
    startDate: "Sep 19, 2024",
    endDate: "Feb 20, 2025",
    project: "Globex Interactive",
  },
  {
    isActive: true,
    isE_Sign: false,
    isRenew: true,
    id: "1234",
    contractorName: "Frank Kenneth",
    job: "Front-end Drupal developer",
    startDate: "Sep 19, 2024",
    endDate: "Feb 20, 2025",
    project: "Globex Interactive",
  },
  {
    isActive: true,
    isE_Sign: false,
    isRenew: false,
    id: "1234",
    contractorName: "Frank Kenneth",
    job: "Front-end Drupal developer",
    startDate: "Sep 19, 2024",
    endDate: "Feb 20, 2025",
    project: "Globex Interactive",
  },
];

type ContractsModuleContextType = {
  sigCanvas: SignaturePad | null;
  selectedContractTab: ContractsTabsEnums;
  selectedSignatureStep: SignatureStepEnum;
  font: ContractFontsEnum;
  signatureText: string;
  signatureImg: string;
  showSignatureModal: boolean;
  sowData: SOWResponse[];
  setSelectedContractTab: React.Dispatch<
    React.SetStateAction<ContractsTabsEnums>
  >;
  setFont: React.Dispatch<React.SetStateAction<ContractFontsEnum>>;
  setSignatureText: React.Dispatch<React.SetStateAction<string>>;
  setShowSignatureModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSignatureStep: React.Dispatch<
    React.SetStateAction<SignatureStepEnum>
  >;

  toggleSignatureModal: () => void;
  clear: () => void;
  onSign: () => void;
  onSelectSignatureStep: (step: SignatureStepEnum) => void;
};

const ContractsModule = createContext<ContractsModuleContextType | null>(null);

export const useContracts = () =>
  useContext(ContractsModule) as ContractsModuleContextType;

export const ContractsProvider = ({ children }: any) => {
  const sigCanvas = useRef<SignaturePad | null>(null);

  const [selectedContractTab, setSelectedContractTab] =
    useState<ContractsTabsEnums>(ContractsTabsEnums.MasterContracts);
  const [sowData, setSowData] = useState<SOWResponse[]>(dummyStatementOfWork);
  const [signatureText, setSignatureText] = useState<string>("");
  const [selectedSignatureStep, setSelectedSignatureStep] =
    useState<SignatureStepEnum>(SignatureStepEnum.SignatureOptions);
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
  const [font, setFont] = useState<ContractFontsEnum>(
    ContractFontsEnum.CedarvilleCursive
  );

  const [signatureImg, setSignatureImg] = useState("");

  const toggleSignatureModal = () => {
    setShowSignatureModal(!showSignatureModal);
    setSelectedSignatureStep(SignatureStepEnum.SignatureOptions);
    setFont(ContractFontsEnum.CedarvilleCursive);
  };

  const onSelectSignatureStep = (step: SignatureStepEnum) => {
    setSelectedSignatureStep(step);
  };

  const clear = () => sigCanvas.current.clear();
  const onSign = () =>
    setSignatureImg(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
  const onSignSubmit = () => {};

  return (
    <ContractsModule.Provider
      value={{
        sigCanvas,
        sowData,
        font,
        signatureText,
        signatureImg,
        showSignatureModal,
        selectedContractTab,
        selectedSignatureStep,
        setSelectedSignatureStep,
        setSelectedContractTab,
        setFont,
        setSignatureText,
        setShowSignatureModal,
        toggleSignatureModal,
        onSelectSignatureStep,
        clear,
        onSign,
      }}
    >
      {children}
    </ContractsModule.Provider>
  );
};
