import { Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { BG_COLORS, COLORS } from "../../../../../../../../../theme";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { getContractorDetailsState } from "../../../../../../../../../redux/slices/contractorSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { convertTimestampToDate } from "../../../../../../../../../utils/dateUtils";
import { formattedAmount } from "../../../../../../../../../utils/common";

const ProfileModule = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { isCandidateDetailsLoading } = useAppSelector(getSubmissionState);
  const { contractorPlacmentDetails, contractorEffortsDetails } =
    useAppSelector(getContractorDetailsState);

  const cost = contractorPlacmentDetails.employment_type_id.length
    ? contractorPlacmentDetails.employment_type_id === "36"
      ? formattedAmount(contractorPlacmentDetails.bill_salary)
      : formattedAmount(Number(contractorPlacmentDetails.bill_rate) * 2080)
    : "00";
  const contractorEndDate = contractorPlacmentDetails.end_date.length
    ? convertTimestampToDate(contractorPlacmentDetails.end_date)
    : "--";
  const totalTimeHours = contractorEffortsDetails
    ? contractorEffortsDetails.project_hrs
    : "00";
  const details = [
    {
      label: "Cost Estimate",
      value: `$${cost}`,
    },
    {
      label: "Payment Schedule",
      value: "Bi-Weekly",
    },
    {
      label: "Total Hours Worked",
      value: `$${totalTimeHours} hrs`,
    },
    {
      label: "Contract end",
      value: contractorEndDate,
    },
  ];
  const filterData = [
    {
      label: "Cost Estimate",
      value: `$${cost}`,
    },
    {
      label: "Payment Schedule",
      value: "Bi-Weekly",
    },
  ];

  const filterDetails = contractorPlacmentDetails.employment_type_id.length
    ? contractorPlacmentDetails.employment_type_id === "36"
      ? filterData
      : details
    : details;
  return (
    <Stack
      px={4}
      py={1.5}
      gap={2}
      direction={isMobile ? "column" : "row"}
      sx={{
        bgcolor: BG_COLORS.PRIMARY,
        borderRadius: 2,
      }}
    >
      {isCandidateDetailsLoading
        ? Array.from(new Array(4)).map((_, index) => (
            <Stack key={index} gap={1} sx={{ flex: 1 }}>
              <Skeleton height={20} />
              <Skeleton height={20} />
            </Stack>
          ))
        : filterDetails.map((detail, index) => (
            <Stack key={index} gap={1} sx={{ flex: 1 }}>
              <AtsTitleText
                text={detail.label}
                fs={12}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
              />
              {isCandidateDetailsLoading ? (
                <Skeleton height={20} />
              ) : (
                <AtsTitleText text={detail.value} fs={14} fw={500} />
              )}
            </Stack>
          ))}
    </Stack>
  );
};

export default ProfileModule;
