import React from "react";

import { Box, Stack } from "@mui/material";

import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { IColumnProps } from "../../../../../../../types/commonType";
import { TimelogEntriesResponse } from "../../../../../../../types/timesheetTypes";
import { convertTimestampToDate } from "../../../../../../../utils/dateUtils";

const cellStyles = {
  padding: "9px 15px",
};
const firstCellStyles = {
  padding: "9px 0px 9px 10px",
};

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "date",
    header: "Start Date",
    width: "auto",
    minWidth: "100px",
  },
  {
    key: "startEndTime",
    header: "End Date",
    width: "auto",
    minWidth: "140px",
  },
  {
    key: "title",
    header: "Task",
    width: "auto",
    minWidth: "150px",
  },
  {
    key: "work_description",
    header: "Description of work",
    width: "auto",
    minWidth: "140px",
  },

  {
    key: "working_hours",
    header: "Time",
    width: "90px",
    minWidth: "90px",
  },
];

interface IProps {
  timelogs: TimelogEntriesResponse[];
}
const TimelogEntriesList = ({ timelogs }: IProps) => {
  const getTimeFormat = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const customizeRow = (
    column: IColumnProps,
    timelog: TimelogEntriesResponse
  ) => {
    switch (column.key) {
      case "date":
        const start = getTimeFormat(
          new Date(Number(timelog.start_time) * 1000)
        );
        const startDate = convertTimestampToDate(timelog.start_time);
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={`${startDate} ${start}`}
              />
            </Stack>
          </td>
        );
      case "title":
      case "work_description":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={timelog[column.key]}
              />
            </Stack>
          </td>
        );
      case "startEndTime":
        const end = getTimeFormat(new Date(Number(timelog.end_time) * 1000));
        const endDate = convertTimestampToDate(timelog.end_time);
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={` ${endDate} ${end}`}
              />
            </Stack>
          </td>
        );

      case "working_hours":
        const hours = `${timelog?.working_hours} hrs`;
        const disputedHours =
          // !timelog?.approved_time?.length &&
          // Number(timelog.approved_time) > 0 &&
          timelog.disputed_time?.length && Number(timelog.disputed_time) > 0
            ? `Disputed ${timelog?.disputed_time} hrs`
            : "";
        const ApprovedHours =
          timelog?.approved_time?.length && Number(timelog.approved_time) > 0
            ? `Approved ${timelog?.approved_time} hrs`
            : "";

        return (
          <td
            style={{
              ...cellStyles,
              //   backgroundColor:
              //     timelog?.approved_time?.length &&
              //     Number(timelog.approved_time) > 0
              //       ? "#e1ffe8"
              //       : timelog.disputed_time?.length &&
              //         Number(timelog.disputed_time) > 0
              //       ? "#fff3f3"
              //       : selectedTimelogOfContractor.includes(timelog.nid)
              //       ? BG_COLORS.PRIMARY
              //       : "#f0f5ff",
            }}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={600}
                textColor={COLORS.BLACK}
                text={hours}
              />
            </Stack>
            {/* {disputedHours?.length > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <AtsTitleText
                      fs={11}
                      fw={600}
                      textColor={"#e63030"}
                      text={disputedHours}
                    />
                  </Box>
                )}
                {ApprovedHours?.length > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <AtsTitleText
                      fs={11}
                      fw={600}
                      textColor={COLORS.GREEN}
                      text={ApprovedHours}
                    />
                  </Box>
                )} */}
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = timelogs.map(
      (value: TimelogEntriesResponse, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                minHeight: "44px",
                height: 67,
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
                cursor:
                  value.disputed_time?.length || value?.approved_time?.length
                    ? "default"
                    : "pointer",
                position: "relative",
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
            </tr>
          </React.Fragment>
        );
      }
    );
    return <>{leadsData}</>;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "18px 15px",
            textAlign: "start",
            // backgroundColor:
            //   column.header === "Time"
            //     ? isApprovedAll
            //       ? "#e1ffe8"
            //       : "#f0f5ff"
            //     : "transparent",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <AtsTitleText
              text={column.header}
              fs={12}
              fw={600}
              textColor="#000"
              isCursor
            />
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <Box
      sx={{
        borderLeft: BORDERS.GRAY,
        borderRight: BORDERS.GRAY,
      }}
    >
      <AtsDataTable
        isNotStickyHeader
        data={timelogs}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={false}
      />
    </Box>
  );
};

export default TimelogEntriesList;
