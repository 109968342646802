import { Add } from "@mui/icons-material";
import { Skeleton, Stack } from "@mui/material";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import AtsRow from "../../../../components/AtsRow";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import { useEffect, useState } from "react";
import { CalendarServices } from "../../../../Api/calendarServices";
import { DEV_URL } from "../../../../utils/url";

const ConnectGoogleCalendar = () => {
  const successPath = `${DEV_URL}availability`;
  const failurePath = `${DEV_URL}availability`;

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const connectGoolge = () => {
    window.location.href = `https://cdevapi.esteemed.io/v1/google-auth?successRedirect=${successPath}&failureRedirect=${failurePath}&authorization=Bearer ${localStorage.getItem(
      "accessToken"
    )}&`;
  };

  const getCalendarConnected = async () => {
    setLoading(true);
    try {
      const response = await CalendarServices.calendarConnected();
      setIsConnected(true);
    } catch (error) {
      console.error(error);
      setIsConnected(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCalendarConnected();
  }, []);

  return (
    <>
      <Stack gap={"6px"} pb={"12px"}>
        <AtsRow>
          <>
            <AtsTitleText
              text={"Connect Google Calendar"}
              fs={16}
              fw={600}
              textColor="#000"
            />
          </>
        </AtsRow>
        <AtsTitleText
          text={"Sync Google Calendar to adjust hours on specific dates."}
          fs={14}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Stack>
      <AtsRow>
        {loading ? (
          <Skeleton variant="rounded" width={110} height={32} />
        ) : isConnected ? (
          <AtsRoundedButton
            text="Connected"
            buttonVariant="greenContained"
            buttonWidth={110}
            buttonHeight={32}
            // onClick={connectGoolge}
          />
        ) : (
          <AtsRoundedButton
            text="Connect"
            startIcon={<Add />}
            buttonVariant="outline"
            buttonWidth={110}
            buttonHeight={32}
            onClick={connectGoolge}
          />
        )}
      </AtsRow>
    </>
  );
};

export default ConnectGoogleCalendar;
