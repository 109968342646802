/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowForwardIos } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import UserProfileInfo from "../../../../../../../../../components/common/UserProfileInfo";
import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../../theme";
import { useState } from "react";
import { MakeAnOfferServices } from "../../../../../../../../../Api/makeAnOfferServices";
import { getSubmissionState } from "../../../../../../../../../redux/slices/submissionSlice";
import { useAppSelector } from "../../../../../../../../../redux/store";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import { JobTypeEnum } from "../../../../../../../../../enums/jobsEnums";
import { Box } from "@mui/material";

const PriceSummary = () => {
  const { setIsCompleteRequest, formattedCandidateDetails } = useMakeAnOffer();
  const { submissionDetails } = useAppSelector(getSubmissionState);
  const [loading, setLoading] = useState<boolean>(false);

  const sendOffer = async () => {
    setLoading(true);
    try {
      await MakeAnOfferServices.createOffer({
        submission_id: Number(submissionDetails.nid),
      });
      setIsCompleteRequest(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack
        sx={{
          px: 3,
          py: 2,
          bgcolor: BG_COLORS.GRAY,
          borderRadius: 2,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText fs={18} fw={600} text="Price Summary" />
        </Stack>
        <UserProfileInfo />
        <Stack gap={"12px"} sx={{ pt: 2, pb: 2, borderBottom: BORDERS.GRAY }}>
          <RenderTextWrap
            text="Employment type"
            value={
              isNotNullOrUndefined(formattedCandidateDetails?.hourPerWeek)
                ? parseFloat(
                    formattedCandidateDetails?.hourPerWeek.split(" ")[0]
                  ) >= 30
                  ? "Full-time"
                  : "Part-time"
                : ""
            }
          />
          <RenderTextWrap
            text="Hours per week"
            value={formattedCandidateDetails.hourPerWeek}
          />
          <RenderTextWrap
            text={
              formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE
                ? "Start date"
                : "Duration"
            }
            value={formattedCandidateDetails.duration}
          />
          {formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE && (
            <RenderTextWrap
              text={"Annual rate"}
              value={formattedCandidateDetails.annualrate}
            />
          )}
        </Stack>
        <Stack sx={{ pt: "12px", pb: "12px", borderBottom: BORDERS.GRAY }}>
          {formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE ? (
            <Stack gap={"5px"}>
              <AtsRowBetween>
                <>
                  <AtsTitleText fs={14} fw={600} text="Direct Hire Fee" />
                  <AtsTitleText
                    fs={14}
                    fw={600}
                    text={`${formattedCandidateDetails.directHireFeee}`}
                  />
                </>
              </AtsRowBetween>
              <Box
                sx={{
                  width: "70%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 10,
                    color: COLORS.BLACK,
                    textDecoration: "underline",
                  }}
                >
                  20% of the annual salary will be payable to Esteemed.
                </Typography>
              </Box>
            </Stack>
          ) : (
            <AtsRowBetween>
              <>
                <AtsTitleText fs={14} fw={600} text="Hourly rate" />
                <AtsTitleText
                  fs={14}
                  fw={600}
                  text={`${formattedCandidateDetails.rate}/hr`}
                />
              </>
            </AtsRowBetween>
          )}
        </Stack>
        <Stack gap={3.5} py={2}>
          <AtsTitleText
            fs={10}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
            text="By selecting the button below, I agree to the Colleagues Seller Terms, Colleagues Employer Agreement, and Refund Policy, and that Esteemed can charge my payment method. I agree to pay the total amount shown if my hiring request is accepted."
          />
          <AtsRoundedButton
            text="Send offer"
            fw={400}
            isInitial
            endIcon={<ArrowForwardIos />}
            onClick={sendOffer}
            disabled={loading}
            loading={loading}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PriceSummary;

const RenderTextWrap = ({ text, value }: { text: string; value: string }) => {
  return (
    <AtsRowBetween>
      <>
        <AtsTitleText
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
          text={text}
        />
        <AtsTitleText fs={12} fw={400} text={value} />
      </>
    </AtsRowBetween>
  );
};
