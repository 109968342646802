import React from "react";
import AtsPageContainer from "../../components/AtsPageContainer";
import { Box, Grid, Stack } from "@mui/material";
import AtsTitleText from "../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../theme";
import JobsSwitch from "../Dashboard/PostedJobs/JobsSwitch";
import { useInsights } from "../../providers/InsightsProvider";
import { InsightTypeTabs } from "../../enums/insightsEnums";
import { BrifecaseTick, UserCirlceAdd } from "iconsax-react";
import TotalNumberCard from "./components/TotalNumberCard";
import JobRequisitionsByLocations from "./modules/JobRequisitionsByLocations";
import UserHire from "./icons/UserHire.svg";
import Suitcase from "./icons/Suitcase.svg";
import UserDiary from "./icons/UserDiary.svg";
import Pileline from "./icons/Pileline.svg";
import CompanyOverview from "./modules/CompanyOverview";
import KeyInsights from "./modules/KeyInsights";
import GenderDiversity from "./modules/GenderDiversity";

const Insights = () => {
  const { selectedInsightsTab, handleChangeTab } = useInsights();

  const insightsTabs = Object.keys(InsightTypeTabs).map((tab) => ({
    title: tab,
    type: tab,
  }));

  const dataCount = [
    {
      number: 34,
      title: "Total submissions",
      icon: UserDiary,
    },
    {
      number: 18,
      title: "Total active jobs",
      icon: Suitcase,
    },
    {
      number: 1224,
      title: "Total hires",
      icon: UserHire,
    },
    {
      number: 552,
      title: "Total in pipeline",
      icon: Pileline,
    },
  ];
  return (
    <AtsPageContainer isEqualPading>
      <>
        <Stack sx={{ mb: 1.5 }}>
          <AtsTitleText
            text="Insights"
            fs={24}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <Stack
            sx={{ pt: "22px", pb: "19px" }}
            direction={"row"}
            alignItems={"center"}
          >
            <JobsSwitch
              jobs={insightsTabs}
              active={selectedInsightsTab}
              onChange={(value) => {
                handleChangeTab(value as InsightTypeTabs);
              }}
            />
          </Stack>
        </Stack>
        <Box>
          <Stack gap={"20px"}>
            <Grid container spacing={"30px"}>
              {dataCount.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <TotalNumberCard
                    icon={item.icon}
                    number={item.number}
                    title={item.title}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={"30px"}>
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <JobRequisitionsByLocations />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <Box
                  sx={{
                    p: "25px",
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    bgcolor: "white",
                  }}
                >
                  <Stack>
                    <CompanyOverview />
                  </Stack>
                  <Stack>
                    <KeyInsights />
                  </Stack>
                  <Stack>
                    <GenderDiversity />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </>
    </AtsPageContainer>
  );
};

export default Insights;
