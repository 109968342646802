import axios from "axios";
import { NidIdParams, PaginatedResponse } from "../types/commonType";
import {
  CandidateIdParams,
  CreateInterviewResponse,
  CreateScoreCardOfCandidateParams,
  CreateUpdateInterviewParams,
  InterviewData,
  InterviewIdTitleResponse,
  InterviewParams,
  InterviewResult,
  ScoreCardOfInterviewParams,
  ScoreCardOfInterviewResponse,
  ScoreCardRatingResponse,
  ScoreCardTypeResponse,
} from "../types/interviewTypes";
import { GetCandidateInterviewListByJobResponse } from "../types/calendarTypes";

const Endpoint = {
  BASE: "ats/api/v1/",
  APPOINTMENT: "appointments/get-by-logged-in-user",
  CREATE_INTERVIEW: "interview/create?_format=json",
  UPDATE_INTERVIEW: "interview/update?_format=json",
  INTERVIEW_STATUS: "interview-status?_format=json",
  INTERVIEW_TYPES: "interview-types?_format=json",
  GET_INTERVIEW_DETAILS: "interview/get?_format=json",
  SCRORE_CARD_TYPE: "score-card-types?_format=json",
  GET_SCRORE_CARD_RATING: "score-card-rating?_format=json",
  CREATE_SCRORE_CARD: "interview-score-card/create",
  UPDATE_SCRORE_CARD: "interview-score-card/update",
  GET_SCRORE_CARD_OF_INTERVIEW: "interview-score-card/get",
  GET_ALL_INTERVIEWS: "candidate-interviews-by-candidate-id",
};

const APPOINTMENT_URL = Endpoint.BASE + Endpoint.APPOINTMENT;
const CREATE_INTERVIEW_URL = Endpoint.BASE + Endpoint.CREATE_INTERVIEW;
const UPDATE_INTERVIEW_URL = Endpoint.BASE + Endpoint.UPDATE_INTERVIEW;
const INTERVIEW_STATUS_URL = Endpoint.BASE + Endpoint.INTERVIEW_STATUS;
const INTERVIEW_TYPES_URL = Endpoint.BASE + Endpoint.INTERVIEW_TYPES;
const GET_INTERVIEW_DETAILS_URL =
  Endpoint.BASE + Endpoint.GET_INTERVIEW_DETAILS;
const SCRORE_CARD_TYPE_URL = Endpoint.BASE + Endpoint.SCRORE_CARD_TYPE;
const GET_SCRORE_CARD_RATING_URL =
  Endpoint.BASE + Endpoint.GET_SCRORE_CARD_RATING;
const CREATE_SCRORE_CARD_URL = Endpoint.BASE + Endpoint.CREATE_SCRORE_CARD;
const UPDATE_SCRORE_CARD_URL = Endpoint.BASE + Endpoint.UPDATE_SCRORE_CARD;
const GET_SCRORE_CARD_OF_INTERVIEW_URL =
  Endpoint.BASE + Endpoint.GET_SCRORE_CARD_OF_INTERVIEW;
const GET_ALL_INTERVIEWS_URL = Endpoint.BASE + Endpoint.GET_ALL_INTERVIEWS;

const headers = {
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

export class InterviewsService {
  static async getCurrentUserInterviews(
    request: Partial<InterviewParams>
  ): Promise<PaginatedResponse<InterviewResult>> {
    const endpoint = APPOINTMENT_URL;
    const response = await axios.get(endpoint, { params: request, headers });
    return response.data;
  }

  static async getAllInterviews(
    params: Partial<CandidateIdParams>
  ): Promise<PaginatedResponse<GetCandidateInterviewListByJobResponse>> {
    const endpoint = GET_ALL_INTERVIEWS_URL;
    const response = await axios.get(endpoint, { params, headers });
    return response.data;
  }

  static async getInterviewsStatus(): Promise<
    PaginatedResponse<InterviewIdTitleResponse>
  > {
    const endpoint = INTERVIEW_STATUS_URL;
    const response = await axios.get(endpoint, { headers });
    return response.data;
  }

  static async getInterviewsTypes(): Promise<
    PaginatedResponse<InterviewIdTitleResponse>
  > {
    const endpoint = INTERVIEW_TYPES_URL;
    const response = await axios.get(endpoint, { headers });
    return response.data;
  }

  static async getInterviewDetails(
    params: Partial<NidIdParams>
  ): Promise<InterviewData> {
    const endpoint = GET_INTERVIEW_DETAILS_URL;
    const response = await axios.get(endpoint, { params, headers });
    return response.data;
  }

  static async createInterview(
    request: Partial<CreateUpdateInterviewParams>
  ): Promise<CreateInterviewResponse> {
    const endpoint = CREATE_INTERVIEW_URL;
    const response = await axios.post(endpoint, request, { headers });
    return response.data;
  }

  static async updateInterview(
    request: Partial<CreateUpdateInterviewParams>
  ): Promise<PaginatedResponse<InterviewResult>> {
    const endpoint = UPDATE_INTERVIEW_URL;
    const response = await axios.post(endpoint, request, { headers });
    return response.data;
  }

  ///////////////////// SCORECARD \\\\\\\\\\\\\\\\\\\\\\\\

  static async getScroreCardOfInterview(
    params: Partial<ScoreCardOfInterviewParams>
  ): Promise<ScoreCardOfInterviewResponse> {
    const endpoint = GET_SCRORE_CARD_OF_INTERVIEW_URL;
    const response = await axios.get(endpoint, { params, headers });
    return response.data;
  }

  static async getScroreCardType(): Promise<
    PaginatedResponse<ScoreCardTypeResponse>
  > {
    const endpoint = SCRORE_CARD_TYPE_URL;
    const response = await axios.get(endpoint, { headers });
    return response.data;
  }

  static async getScroreCardRating(): Promise<
    PaginatedResponse<ScoreCardRatingResponse>
  > {
    const endpoint = GET_SCRORE_CARD_RATING_URL;
    const response = await axios.get(endpoint, { headers });
    return response.data;
  }

  static async createScoreCardForCandidate(
    request: Partial<CreateScoreCardOfCandidateParams>
  ): Promise<PaginatedResponse<ScoreCardRatingResponse>> {
    const endpoint = CREATE_SCRORE_CARD_URL;
    const response = await axios.post(endpoint, request, { headers });
    return response.data;
  }

  static async updateScoreCardForCandidate(
    request: Partial<CreateScoreCardOfCandidateParams>
  ): Promise<PaginatedResponse<ScoreCardRatingResponse>> {
    const endpoint = UPDATE_SCRORE_CARD_URL;
    const response = await axios.patch(endpoint, request, { headers });
    return response.data;
  }

  ///////////////////// SCORECARD \\\\\\\\\\\\\\\\\\\\\\\\
}
