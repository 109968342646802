import { Box } from "@mui/material";
import AtsPageContainer from "../../components/AtsPageContainer";
import { BORDERS } from "../../theme";
import MasterContracts from "./MasterContracts";
import ContractsTabs from "./modules/ContractsTabs";
import {
  ContractsTabsEnums,
  useContracts,
} from "../../providers/ContractsProvider";
import StatementsOfWork from "./StatementsOfWork";

const Contracts = () => {
  const { selectedContractTab } = useContracts();
  return (
    <AtsPageContainer>
      <Box height={"100%"}>
        <ContractsTabs />
        <Box
          sx={{
            height: "calc(100% - 100px)",

            bgcolor: "white",
            border: BORDERS.GRAY,
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              height: "90%",
              overflow: "auto",
              mx: "30px",
              my: "20px",
            }}
          >
            {selectedContractTab === ContractsTabsEnums.StatementsOfWork ? (
              <StatementsOfWork />
            ) : (
              <MasterContracts />
            )}
          </Box>
        </Box>
      </Box>
    </AtsPageContainer>
  );
};

export default Contracts;
