import { Box, Stack } from "@mui/material";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";

const BillingInfo = () => {
  return (
    <>
      <RenderTextWrap text="Name:" value="John Lewis" />
      <RenderTextWrap
        text="Billing address:"
        value="2788 San Tomas Express Way, Santa Clara, CA 95051."
      />
      <RenderTextWrap text="Contact:" value="john.lewis2@gmail.com" />
      <RenderTextWrap text="Alternate Contact:" value="john.lewis2@gmail.com" />
    </>
  );
};

export default BillingInfo;

const RenderTextWrap = ({ text, value }: { text: string; value: string }) => {
  return (
    <Stack direction={"row"} alignItems={"flex-start"} gap={"50px"} pb={"20px"}>
      <>
        <Box
          sx={{
            width: 125,
          }}
        >
          <AtsTitleText
            fs={14}
            fw={500}
            textColor={COLORS.LIGHT_GRAY}
            text={text}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <AtsTitleText fs={14} fw={400} text={value} />
        </Box>
      </>
    </Stack>
  );
};
