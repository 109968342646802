import React from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { Stack } from "@mui/material";
import AtsRow from "../../../components/AtsRow";
import Location from "../icons/Location.svg";

const KeyInsights = () => {
  return (
    <>
      <Stack
        gap={"20px"}
        py={"30px"}
        sx={{
          borderBottom: BORDERS.GRAY,
        }}
      >
        <AtsTitleText text={"Key insights"} fs={18} fw={600} />

        <AtsRow gap={"10px"}>
          <>
            <img src={Location} alt="" />
            <Stack gap={"5px"}>
              <AtsTitleText
                text={"Fast growing locations"}
                fs={14}
                fw={700}
                textColor={COLORS.DARK_GRAY}
              />
              <AtsTitleText
                text={"Greater Seattle Area . Nigeria"}
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
            </Stack>
          </>
        </AtsRow>
      </Stack>
    </>
  );
};

export default KeyInsights;
