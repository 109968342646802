import {
  CircleOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from "@mui/icons-material";
import { Box, Collapse, Stack } from "@mui/material";
import { TickCircle } from "iconsax-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FilterIcon } from "../../../../../../assets/icons/Icons";
import AtsCheckbox from "../../../../../../components/AtsCheckbox";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { TaskStatusEnum } from "../../../../../../enums/commonEnums";
import { useTask } from "../../../../../../providers/TaskProvider";
import {
  BG_COLORS,
  BORDERS,
  BOX_SHADOW,
  COLORS,
} from "../../../../../../theme";
import { IColumnProps } from "../../../../../../types/commonType";
import {
  CreateUpdateTaskRequest,
  TasksListResponse,
} from "../../../../../../types/projectTypes";
import JobsSwitch from "../../../../../Dashboard/PostedJobs/JobsSwitch";

const LEAD_COLUMN: IColumnProps[] = [
  // {
  //   key: "uid",
  //   header: "Invoice no.",
  //   width: "300px",
  //   minWidth: "300px",
  // },
  {
    key: "title",
    header: "Task Name",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "owner_emails",
    header: "Assignees",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "created",
    header: "Created on",
    width: "auto",
    minWidth: "100px",
  },
  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const Tasks = () => {
  const { id } = useParams();
  const {
    tasks,
    loading,
    searchValue,
    setSearchValue,
    addNewTaskFunc,
    updateTaskFunc,
    fetchTaskList,
    setTasks,
    selectedStatus,
    contractors,
    setSelectedStatus,
    getContractorsList,
  } = useTask();

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [dropDownSelectedId, setDropDownSelectedId] = useState<string>("");
  const [openRowId, setOpenRowId] = useState(null);

  useEffect(() => {
    setDropDownSelectedId("");
    setSelectedId("");
  }, [selectedStatus]);

  useEffect(() => {
    getContractorsList();
  }, []);

  const taskStatuses = Object.keys(TaskStatusEnum).map((status) => ({
    title: status,
    type: TaskStatusEnum[status],
  }));

  const handleTaskNameChange = useCallback((value: string, uid: string) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === uid ? { ...task, title: value } : task
      )
    );
  }, []);

  const handleToggle = (id, index) => {
    setHoveredRow(null);
    setIndexNumber(index);
    setOpenRowId((prevOpenRowId) => (prevOpenRowId === id ? null : id));
  };

  const updateTask = async (
    task: TasksListResponse,
    status: TaskStatusEnum,
    selectedId: string | number
  ) => {
    const owner_emails = task.owner_emails.includes(",")
      ? task.owner_emails.split(", ")
      : [task.owner_emails];
    const updateTaskObj = {
      nid: selectedId
        ? typeof selectedId === "string"
          ? Number(selectedId)
          : selectedId
        : task.id,
      owner_emails: owner_emails.join(", "),
      project_id: typeof id === "string" ? Number(id) : id,
      status,
      title: task.title,
    } as CreateUpdateTaskRequest;
    setOpenRowId("");
    setSelectedId("");
    await updateTaskFunc(updateTaskObj);
    fetchTaskList();
  };

  const createTask = async () => {
    const createTaskObj = {
      title: title,
      project_id: typeof id === "string" ? Number(id) : id,
      status: TaskStatusEnum.Active,
    } as CreateUpdateTaskRequest;
    await addNewTaskFunc(createTaskObj);
    fetchTaskList();
    setTitle("");
  };

  const handleEnterPress = useCallback(async (task: TasksListResponse) => {
    await updateTask(
      task,
      task.status === "Active"
        ? TaskStatusEnum.Active
        : TaskStatusEnum.Archived,
      task.id
    );
  }, []);

  const handleClose = () => {
    setDropDownSelectedId("");
    setOpenRowId("");
  };

  const handleChange = async (assigne: string) => {
    const preTask = [...tasks];
    const taskObj = tasks[indexNumber] as TasksListResponse;
    const owner_emails = taskObj.owner_emails.includes(",")
      ? taskObj.owner_emails.split(", ")
      : [taskObj.owner_emails];
    const filterOwnerEmails = owner_emails.filter((email) => email !== "");
    if (filterOwnerEmails.includes(assigne)) {
      const updateAssigne: string[] = filterOwnerEmails.filter(
        (assign) => assign !== assigne
      );
      const updateTaskObj = {
        ...taskObj,
        owner_emails: updateAssigne.join(", "),
      } as TasksListResponse;

      preTask.splice(indexNumber, 1, updateTaskObj);
      setTasks(preTask);
    } else {
      const updateTaskObj = {
        ...taskObj,
        owner_emails: [...filterOwnerEmails, assigne].join(", "),
      } as TasksListResponse;

      preTask.splice(indexNumber, 1, updateTaskObj);
      setTasks(preTask);
    }

    // setActiveDropdown(null);
  };
  const customizeRow = (
    column: IColumnProps,
    task: TasksListResponse,
    index: number
  ) => {
    switch (column.key) {
      case "title":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              {task.status === "Archived" ? (
                <AtsTitleText
                  fs={14}
                  fw={400}
                  textColor={"#000"}
                  text={task.title}
                />
              ) : hoveredRow === task.id ? (
                <Box>
                  <input
                    className="inputstyle"
                    style={{
                      color: COLORS.BLACK,
                      fontWeight: 400,
                      borderRadius: "8px",
                      border: BORDERS.GRAY,
                      fontSize: "14px",
                      height: "38px",
                      padding: "0px 6px",
                      // width: "100%",
                      background: "white",
                    }}
                    type="text"
                    value={task.title}
                    onChange={(e) =>
                      handleTaskNameChange(e.target.value, task.id)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleEnterPress(task);
                      }
                    }}
                  />
                </Box>
              ) : (
                <AtsTitleText
                  fs={14}
                  fw={400}
                  textColor={"#000"}
                  text={task.title}
                />
              )}
            </Stack>
          </td>
        );
      case "owner_emails":
        const owner_emails = task.owner_emails.length
          ? task.owner_emails.includes(",")
            ? task.owner_emails.split(", ")
            : [task.owner_emails]
          : [];
        return (
          <td style={{ ...cellStyles, position: "relative" }} key={column.key}>
            {task.status === "Archived" ? (
              <Stack
                // ref={domNode}
                py={1}
                direction={"row"}
                alignItems={"center"}
                gap={"12px"}
                sx={{
                  height: 11,
                  bgcolor: BG_COLORS.PRIMARY,
                  px: "16px",
                  borderRadius: "38px",
                  width: "fit-content",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {owner_emails.length === 0 ? (
                  <AtsTitleText text={"Anyone"} textColor={COLORS.PRIMARY} />
                ) : (
                  <Stack direction={"row"} alignItems={"center"} gap={"3px"}>
                    {owner_emails.slice(0, 2).map((item, index) => (
                      <AtsTitleText
                        key={index}
                        text={`${item},`}
                        textColor={COLORS.PRIMARY}
                      />
                    ))}
                    {owner_emails.length > 2 && (
                      <AtsTitleText
                        text={`+${owner_emails.length - 2}`}
                        textColor={COLORS.PRIMARY}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            ) : (
              <>
                <Stack
                  // ref={domNode}
                  py={1}
                  direction={"row"}
                  alignItems={"center"}
                  gap={"12px"}
                  sx={{
                    height: 11,
                    bgcolor: BG_COLORS.PRIMARY,
                    px: "16px",
                    borderRadius: "38px",
                    width: "fit-content",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleToggle(task.id, index);
                  }}
                >
                  {owner_emails.length === 0 ? (
                    <AtsTitleText text={"Anyone"} textColor={COLORS.PRIMARY} />
                  ) : (
                    <Stack direction={"row"} alignItems={"center"} gap={"3px"}>
                      {owner_emails.slice(0, 2).map((item, index) => {
                        const findCOntractor = contractors.find(
                          (contractor) => contractor.email === item
                        );
                        const name = `${findCOntractor?.first_name ?? ""} ${
                          findCOntractor?.last_name ?? ""
                        }`;
                        return (
                          <AtsTitleText
                            key={index}
                            text={`${name},`}
                            textColor={COLORS.PRIMARY}
                          />
                        );
                      })}
                      {owner_emails.length > 2 && (
                        <AtsTitleText
                          text={`+${owner_emails.length - 2}`}
                          textColor={COLORS.PRIMARY}
                        />
                      )}
                    </Stack>
                  )}
                  <AtsCustomButton px={0} py={0} hoverColor={BG_COLORS.PRIMARY}>
                    <AtsIcon
                      icon="icon-arrow-down-1"
                      fw={500}
                      height="16px"
                      color={COLORS.PRIMARY}
                    />
                  </AtsCustomButton>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 50,
                      left: 0,
                      zIndex: 9999,
                      bgcolor: "white",
                      borderRadius: "10px",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Collapse
                      in={openRowId === task.id}
                      sx={{
                        boxShadow: BOX_SHADOW.GRAY,
                        borderRadius: "10px",
                      }}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          width: 352,
                          height: 278,
                          borderRadius: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            px: "20px",
                            py: "20px",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            height={36}
                            sx={{
                              px: "16px",
                              border: BORDERS.GRAY,
                              borderRadius: "38px",
                            }}
                          >
                            <input
                              type="text"
                              className="customInput"
                              style={{
                                width: "100%",
                              }}
                            />
                            <AtsIcon
                              icon="icon-search-normal-1"
                              height="16px"
                            />
                          </Stack>
                        </Box>
                        <Box
                          sx={{
                            px: "20px",
                            py: "15px",
                            borderTop: BORDERS.GRAY,
                            borderBottom: BORDERS.GRAY,
                          }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <AtsTitleText
                              text={"Show"}
                              fs={14}
                              fw={400}
                              textColor="#000"
                            />
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={"20px"}
                            >
                              <AtsTitleText
                                text={"Active"}
                                fs={14}
                                fw={400}
                                textColor="#000"
                              />
                              <AtsIcon
                                icon="icon-arrow-down-1"
                                fw={500}
                                height="16px"
                              />
                            </Stack>
                          </Stack>
                        </Box>
                        <Box
                          sx={{
                            height: "calc(100% - 173px)",
                            overflow: "auto",
                          }}
                        >
                          <Stack
                            gap={"10px"}
                            sx={{
                              height: "80%",
                              px: "20px",
                              my: "10px",
                            }}
                          >
                            {contractors.map((assignes) => {
                              return (
                                <Stack
                                  key={assignes.email}
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={"5px"}
                                >
                                  <AtsCheckbox
                                    checked={tasks[
                                      indexNumber
                                    ].owner_emails.includes(assignes.email)}
                                    onChange={() =>
                                      handleChange(assignes.email)
                                    }
                                  />
                                  <AtsTitleText
                                    text={`${assignes?.first_name ?? ""} ${
                                      assignes?.last_name ?? ""
                                    }`}
                                    fs={14}
                                    fw={400}
                                    textColor="#000"
                                  />
                                </Stack>
                              );
                            })}
                          </Stack>
                        </Box>
                        <AtsRow
                          justifyContent={"end"}
                          sx={{
                            mx: "10px",
                          }}
                        >
                          <AtsRoundedButton
                            text="Add"
                            onClick={() => {
                              updateTask(
                                task,
                                task.status === "Active"
                                  ? TaskStatusEnum.Active
                                  : TaskStatusEnum.Archived,
                                dropDownSelectedId
                              );
                              handleClose();
                            }}
                          />
                        </AtsRow>
                      </Box>
                    </Collapse>
                  </Box>
                </Stack>
              </>
            )}
          </td>
        );
      case "created":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={moment.unix(Number(task.created)).format("MMM DD, YYYY")}
              />
            </Stack>
          </td>
        );
      case selectedStatus === TaskStatusEnum.All && "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                text={task.status}
                fs={12}
                fw={600}
                buttonHeight={25}
                buttonWidth={90}
                buttonVariant={
                  task.status === "Active"
                    ? "greenContained"
                    : "dangerContained"
                }
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Box sx={{ position: "relative", cursor: "pointer" }}>
              <AtsCustomButton
                px={0.6}
                onClick={() => {
                  setSelectedId((prevSelectedId) =>
                    prevSelectedId === task.id ? "" : task.id
                  );
                }}
              >
                <MoreVert />
              </AtsCustomButton>
              <Box
                sx={{
                  position: "absolute",
                  bgcolor: "white",
                  right: "50px",
                  top: "20px",
                  zIndex: 9999,
                  width: "150px",
                }}
              >
                <Collapse
                  sx={{
                    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                    borderRadius: "6px",
                  }}
                  in={task.id === selectedId}
                >
                  <Box
                    sx={{
                      border: BORDERS.GRAY,
                      borderRadius: "6px",
                      boxShadow: " 0 4px 6px 0 rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                      bgcolor: "white",
                    }}
                    onClick={() =>
                      updateTask(
                        task,
                        task.status === "Active"
                          ? TaskStatusEnum.Archived
                          : TaskStatusEnum.Active,
                        selectedId
                      )
                    }
                  >
                    <Stack gap={1}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        {task.status === "Archived" ? (
                          <CircleOutlined
                            sx={{
                              fontSize: 18,
                              color: COLORS.GREEN,
                            }}
                          />
                        ) : (
                          <TickCircle
                            variant="Bold"
                            size={18}
                            color={COLORS.GREEN}
                          />
                        )}

                        <AtsTitleText
                          text={
                            task.status === "Archived"
                              ? "Unarchived"
                              : "Archive task"
                          }
                          fs={12}
                          fw={500}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = tasks.map(
      (value: TasksListResponse, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                height: "68px",
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
                cursor: "pointer",
                backgroundColor: value.id === hoveredRow ? "#f2f6ff" : "white",
              }}
              onMouseEnter={() => setHoveredRow(value.id)}
              onMouseLeave={() => {
                setHoveredRow(null);
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) =>
                customizeRow(val, value, index)
              )}
            </tr>
          </React.Fragment>
        );
      }
    );
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.filter((column) =>
      selectedStatus !== TaskStatusEnum.All ? column.key !== "status" : column
    ).map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  return (
    <>
      <Stack pb={"24px"}>
        <AtsTitleText
          text={"12 Tasks"}
          fs={18}
          fw={600}
          textColor={COLORS.BLACK}
        />
      </Stack>
      <Stack direction={"row"} sx={{ pb: "24px" }}>
        <JobsSwitch
          width="40px"
          jobs={taskStatuses}
          active={selectedStatus}
          onChange={(value) => {
            setSelectedStatus(value as TaskStatusEnum);
          }}
        />
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{ pb: "24px" }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          height={30}
          width={213}
          sx={{
            px: "16px",
            border: BORDERS.GRAY,
            borderRadius: "38px",
          }}
        >
          <input
            type="text"
            className="customInput"
            style={{
              width: "100%",
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <AtsIcon icon="icon-search-normal-1" height="16px" />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"13px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            height={30}
            width={300}
            sx={{
              px: "16px",
              border: BORDERS.GRAY,
              borderRadius: "38px",
            }}
          >
            <input
              type="text"
              className="customInput"
              placeholder="Add new task"
              style={{
                width: "100%",
              }}
              value={title}
              onChange={(e) => {
                const value = e.target.value;
                setTitle(value);
              }}
            />
          </Stack>
          <AtsRoundedButton
            disabled={title.length === 0 || loading}
            loading={loading}
            text="Add"
            buttonHeight={32}
            buttonWidth={84}
            onClick={createTask}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          height: "calc(100% - 155px)",
        }}
      >
        <AtsDataTable
          // isTopBorder
          data={tasks}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default Tasks;
