import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import AtsRow from "../../../components/AtsRow";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import { useContracts } from "../../../providers/ContractsProvider";
import { BG_COLORS, COLORS } from "../../../theme";
import { Magicpen } from "iconsax-react";
import { SignatureStepEnum } from "../../../enums/contractsEnums";

const SignatureOptions = () => {
  const { font, signatureText, setSignatureText, onSelectSignatureStep } =
    useContracts();
  return (
    <>
      <Stack gap={"18px"}>
        <Stack gap={"10px"}>
          <AtsTitleText
            textColor="#000"
            fs={16}
            fw={600}
            text={"Type your signature"}
          />
          <AtsTextInput
            value={signatureText}
            onChange={(e) => {
              const value = e.target.value;
              setSignatureText(value);
            }}
          />
        </Stack>
        <AtsRow
          justifyContent={"center"}
          sx={{
            bgcolor: BG_COLORS.PRIMARY,
            height: 170,
            borderRadius: "10px",
            maxWidth: 734,
            position: "relative",
          }}
        >
          <>
            <Typography
              className={`${font}`}
              sx={{
                color: "#000",
                fontSize: 50,
              }}
            >
              {signatureText}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: signatureText.length ? "pointer" : "default",
                opacity: signatureText.length ? 1 : 0.8,
              }}
              onClick={
                signatureText.length
                  ? () =>
                      onSelectSignatureStep(SignatureStepEnum.SignatureFonts)
                  : () => {}
              }
            >
              <AtsTitleText
                textColor={COLORS.PRIMARY}
                isUnderline
                isLink
                fs={12}
                fw={500}
                text={"Change Style"}
              />
            </Box>
          </>
        </AtsRow>
      </Stack>
      <Stack
        sx={{
          position: "relative",
          mt: "30px",
          mb: "20px",
        }}
      >
        <Divider variant="fullWidth" orientation="horizontal" flexItem />
        <Box
          sx={{
            position: "absolute",
            left: "48%",
            right: "50%",
            bottom: -7,
            width: "31px",
            bgcolor: "white",
            textAlign: "center",
          }}
        >
          <AtsTitleText text={"or"} textColor="#000" fs={16} fw={600} />
        </Box>
      </Stack>
      <Grid container spacing={"30px"}>
        <Grid item xs={12} sm={6}>
          <SignatureOptionsBox
            text="Draw your signature"
            subText="Draw your signature here using your mouse or trackpad."
            icon={<Magicpen size="32" color="#FF8A65" />}
            onClick={() =>
              onSelectSignatureStep(SignatureStepEnum.SignatureDraw)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SignatureOptionsBox
            text="Upload your signature"
            subText="Upload an image of your handwritten signature here."
            icon={<Magicpen size="32" color="#FF8A65" />}
            onClick={() =>
              onSelectSignatureStep(SignatureStepEnum.SignatureUpload)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SignatureOptions;

const SignatureOptionsBox = ({
  text,
  subText,
  icon,
  onClick,
}: {
  text: string;
  subText: string;
  icon: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <AtsRow
      gap={"24px"}
      sx={{
        px: "25px",
        py: "32px",
        border: "1px dashed #6d6d6d",
        borderRadius: "10px",
        cursor: "pointer",
        width: 300,
      }}
      onClick={onClick}
    >
      <>
        <Box>{icon}</Box>
        <Stack gap={"6px"}>
          <AtsTitleText textColor="#000" fs={16} fw={600} text={text} />
          <AtsTitleText textColor="#000" fs={12} fw={400} text={subText} />
        </Stack>
      </>
    </AtsRow>
  );
};
