import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import { COLORS } from "../../../theme";
import ProfileModal from "./ProfileModal";
import AtsTextArea from "../../../components/AtsTextArea";
import AtsRow from "../../../components/AtsRow";
import { Add } from "iconsax-react";

const AboutYou = () => {
  const [open, setOpen] = useState(false);

  const toggelOpenClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <Stack gap={"19px"}>
        <AtsTitleText text={"About you"} fs={18} fw={600} textColor="#000" />
        <Stack
          gap={"11px"}
          sx={{
            border: "1px dashed #4f4f4f",
            borderRadius: "8px",
            px: "19px",
            pt: "20px",
            pb: "25px",
          }}
        >
          <AtsTitleText
            text={"Write something fun and punchy."}
            fs={14}
            fw={400}
            textColor={COLORS.LIGHT_GRAY}
          />

          <Typography
            sx={{
              textDecoration: "underline",
              color: "black",
              fontSize: 14,
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={toggelOpenClose}
          >
            Add Intro
          </Typography>
        </Stack>
      </Stack>
      <ProfileModal
        open={open}
        header={"About you"}
        subText={
          "Tell us a little bit about yourself so your future Hosts or guests can get to know you."
        }
        toggelOpenClose={toggelOpenClose}
        renderConponents={<AtsTextArea rows={10} placeholder="Write here" />}
        onSave={() => {}}
      />
    </>
  );
};

export default AboutYou;
