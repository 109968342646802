import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import AtsTextInput from "./AtsTextInput";
import { CompanyAddressProps } from "../types/companyTypes";
import { Skeleton } from "@mui/material";

interface IProps {
  handleAddressChange: (address: CompanyAddressProps) => void;
  onSearch: (address: string) => void;
  search: string;
}

const AtsAddressInput = ({ handleAddressChange, onSearch, search }: IProps) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });
  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      onSearch(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";
      let country_code = "";
      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
          country_code = component.short_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        }
      }
      const address = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
        country_code,
      } as CompanyAddressProps;
      console.log("search", address, addressComponents);
      handleAddressChange(address);
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  if (!isLoaded) {
    return <Skeleton />;
  }
  return (
    <>
      <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceSelect}>
        <AtsTextInput
          height="49px"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Autocomplete>
    </>
  );
};

export default AtsAddressInput;
