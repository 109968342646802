import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InterviewCalenderIcon } from "../../../../../../../../../../assets/icons/Icons";
import AtsText from "../../../../../../../../../../components/AtsText";
import { useMakeAnOffer } from "../../../../../../../../../../providers/MakeAnOfferProvider";
import { BORDERS, COLORS } from "../../../../../../../../../../theme";
import InterviewDataTable from "../../../InterViewTab/modules/InterviewDataTable";
import NoOffers from "./NoOffers";
import OfferDetails from "./OfferDetails";
import { OfferListHeader } from "./OfferListHeader";
import AtsDataTable from "../../../../../../../../../../components/AtsDataTable";
import { GetOffersResponse } from "../../../../../../../../../../types/makeAnOfferTypes";
import AtsTitleText from "../../../../../../../../../../components/AtsTitleText";
import { getDateFormatMMMDYYYYY } from "../../../../../../../../../../utils/dateUtils";
import { isNotNullOrUndefined } from "../../../../../../../../../../utils/stringUtils";
import AtsRoundedButton from "../../../../../../../../../../components/AtsRoundedButton";
import { formattedAmount } from "../../../../../../../../../../utils/common";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "job_title",
    header: "Job Name",
    width: "200px",
    minWidth: "200px",
  },
  {
    key: "nid",
    header: "Offer ID",
    width: "110px",
    minWidth: "110px",
  },
  {
    key: "extended_on",
    header: "Extended On",
    width: "auto",
    minWidth: "110px",
  },

  {
    key: "rate",
    header: "Rate",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "120px",
  },

  {
    key: "action",
    header: "",
    width: "auto",
    minWidth: "45px",
  },
];

const OffersList = () => {
  const [collapsedRows, setCollapsedRows] = useState<string[]>([]);
  const {
    offers,
    getAllOffersOfCandidate,
    loading,
    offerType,
    setSelectedOffer,
  } = useMakeAnOffer();

  useEffect(() => {
    setCollapsedRows([]);
    getAllOffersOfCandidate();
  }, [offerType]);

  const toggleCollapse = (rowId: any) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => id !== rowId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const cellStyles = {
    padding: "0px 10px",
  };

  const customizeRow = (column: IColumnProps, list: GetOffersResponse) => {
    switch (column.key) {
      case "job_title":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Box>
                <InterviewCalenderIcon />
              </Box>
              <AtsTitleText fs={12} fw={700} text={list.job_title} />
            </Stack>
          </td>
        );
      case "nid":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={`#ES-${list.nid}`} />
            </Stack>
          </td>
        );
      case "extended_on":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                text={getDateFormatMMMDYYYYY(list.extended_on)}
              />
            </Stack>
          </td>
        );
      case "rate":
        const rate =
          list.payment_type === "Annual" ? list.salary / 2080 : list.rate;
        const rateText = `$${formattedAmount(rate)}/ Per hr`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText fs={12} fw={400} text={rateText} />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              {isNotNullOrUndefined(list.status) ? (
                <AtsRoundedButton
                  text={list.status}
                  buttonVariant={
                    list.status === "Offer Extended"
                      ? "greenContained"
                      : list.status === "Under Negotiation"
                      ? "blueContained"
                      : list.status === "Rejected"
                      ? "dangerContained"
                      : list.status === "Expired"
                      ? "disabled"
                      : "purpleContained"
                  }
                  fs={10}
                  fw={600}
                />
              ) : (
                ""
              )}
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = offers.map((value: GetOffersResponse, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              backgroundColor: collapsedRows.includes(value.nid)
                ? "#e2ebff"
                : "white",
              borderBottom: collapsedRows.includes(value.nid)
                ? "none"
                : BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
            <td style={cellStyles}>
              <IconButton
                onClick={() => {
                  toggleCollapse(value.nid);
                  setSelectedOffer(value);
                }}
              >
                <KeyboardArrowDown
                  sx={{
                    transition: "all 500ms ease-in-out",
                    ...(collapsedRows.includes(value.nid) && {
                      rotate: "180deg",
                    }),
                  }}
                />
              </IconButton>
            </td>
          </tr>
          <tr>
            <td
              colSpan={12}
              style={{
                marginBottom: 0,
                padding: 0,
              }}
            >
              {collapsedRows.includes(value.nid) && <OfferDetails />}
            </td>
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "10px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <OfferListHeader headerText={column.header} />

            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <Box
      sx={{
        mb: 3,
        width: "100%",
        height: "90%",
      }}
    >
      {offers.length ? (
        <AtsDataTable
          data={offers}
          theadComponent={headerComponent}
          rowComponent={renderRowComponent}
          loading={loading}
        />
      ) : (
        <NoOffers />
      )}
    </Box>
  );
};

export default OffersList;
