import { ThemeProvider } from "@mui/material";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../Auth/Login";
import Chatbot from "../components/ChatBoot";
import ATS_Layout from "../layout/ATS_Layout";
import { appTheme } from "../theme";
import { ROUTES, RoutesProps, SUB_ROUTES, USER_ROUTES } from "./ATS_routes";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { ATS_ROUTES } from "./routes";
import { SocketProvider } from "../providers/SocketProvider";
import Registration from "../Auth/Registration";
import CreateAccount from "../Auth/CreateAccount/CreateAccount";
import { CreateAccountProvider } from "../providers/CreateAccountProvider";
import { TimesheetProvider } from "../providers/TimesheetProvider";
import { Toaster } from "react-hot-toast";

const AppRouter: React.FC = () => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <ATS_Layout>
      <Suspense fallback={<h1>loading....</h1>}>
        <Routes>
          {[...ROUTES, ...SUB_ROUTES, ...USER_ROUTES].map(
            (route: RoutesProps) =>
              route.component && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                >
                  {route.children &&
                    route.children.map(
                      (childRoute) =>
                        childRoute.component && (
                          <Route
                            key={childRoute.path}
                            path={childRoute.path}
                            element={childRoute.component}
                          />
                        )
                    )}
                </Route>
              )
          )}
        </Routes>
      </Suspense>
    </ATS_Layout>
  );
};

const AuthRouter: React.FC = () => {
  return (
    <Suspense fallback={<h1>loading....</h1>}>
      <Routes>
        <Route path={ATS_ROUTES.LOGIN} element={<Login />} />
      </Routes>
    </Suspense>
  );
};

const RegistrasationRouter: React.FC = () => {
  return (
    <Suspense fallback={<h1>loading....</h1>}>
      <Routes>
        <Route path={ATS_ROUTES.ACCEPT_INVITE} element={<Registration />} />
        <Route
          path={ATS_ROUTES.CREATE_ACCOUNT}
          element={
            <CreateAccountProvider>
              <CreateAccount />
            </CreateAccountProvider>
          }
        />
      </Routes>
    </Suspense>
  );
};

const Router: React.FC = () => {
  const pathname = window.location.pathname;
  return (
    <BrowserRouter>
      <ToastContainer />
      <Toaster position="top-center" reverseOrder={false} />
      <ThemeProvider theme={appTheme}>
        {pathname === ATS_ROUTES.ACCEPT_INVITE ||
        pathname === ATS_ROUTES.CREATE_ACCOUNT ? (
          <RegistrasationRouter />
        ) : (
          <>
            <PublicRoutes>
              <AuthRouter />
            </PublicRoutes>
            <PrivateRoutes>
              <SocketProvider>
                <CreateAccountProvider>
                  <TimesheetProvider>
                    <AppRouter />
                  </TimesheetProvider>
                </CreateAccountProvider>
              </SocketProvider>
              <Chatbot />
            </PrivateRoutes>
          </>
        )}
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Router;
