import { Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../components/AtsTitleText";
import StatementWorkFilter from "./modules/StatementWorkFilter";
import StatementOfWorkCard from "./modules/StatementOfWorkCard";
import { useContracts } from "../../providers/ContractsProvider";

const StatementsOfWork = () => {
  const { sowData } = useContracts();
  return (
    <>
      <Stack px={"9px"}>
        <AtsTitleText
          text={"16 Active SOWs"}
          textColor="#000"
          fs={18}
          fw={600}
        />
      </Stack>
      <Stack gap={"15px"} mt={"20px"}>
        <Stack px={"9px"}>
          <StatementWorkFilter />
        </Stack>
        <Stack mt={"30px"} gap={"30px"}>
          {sowData.map((item, index) => (
            <StatementOfWorkCard key={index} data={item} />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default StatementsOfWork;
