import { EvidencesProps } from "../providers/TimesheetProvider";
import { InviteMemberProps } from "../redux/slices/inviteMembersSlice";
import {
  CompanyInfoResponse,
  UpdateCompanyInfoRequest,
} from "../types/companyTypes";
import { CreateUpdateInterviewParams } from "../types/interviewTypes";
import { CreateUpdateOnboardingRequest } from "../types/onboardingTypes";
import { UserUpdateRequest } from "../types/userManagementTypes";
import { emailValidation } from "./common";
import { isNotNullOrUndefined } from "./stringUtils";

export interface AttendeeError {
  subject?: string;
  interviewers?: string;
  description?: string;
}

export interface ErrorMsg {
  error: AttendeeError;
  isValid: boolean;
}

export const attendeeDetailsValidation = (
  data: Partial<CreateUpdateInterviewParams>
): ErrorMsg => {
  const errorMsg: ErrorMsg = {
    error: {},
    isValid: true,
  };

  const validationRules: Record<string, [boolean, string]> = {
    subject: [!data.subject, "Please enter meeting name"],
    interviewers: [
      !data.interviewers.length,
      "Please select interviewer email",
    ],
    description: [!data.description, "Please enter description"],
  };

  for (const [field, [isInvalid, message]] of Object.entries(validationRules)) {
    if (isInvalid) {
      errorMsg.isValid = false;
      errorMsg.error[field] = message;
    }
  }

  return errorMsg;
};
export const evidanceValidation = (data: EvidencesProps): ErrorMsg => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.note) {
    errorMsg.isValid = false;
    errorMsg.error["note"] = "Please enter note";
  }
  if (!data.evidences.length) {
    errorMsg.isValid = false;
    errorMsg.error["evidences"] = "Please upload evidance";
  }

  return errorMsg;
};
export const inviteMemberValidation = (data: InviteMemberProps) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.role_id) {
    errorMsg.isValid = false;
    errorMsg.error["role_id"] = "Please select role";
  }
  if (!data.emails.length) {
    errorMsg.isValid = false;
    errorMsg.error["emails"] = "Please enter email address";
  }
  if (!data.emails.length) {
    errorMsg.isValid = false;
    errorMsg.error["emails"] = "Please enter email address";
  }
  return errorMsg;
};
export const companyDetailsValidation = (
  data: Partial<CompanyInfoResponse>,
  address: string
) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.title.length) {
    errorMsg.isValid = false;
    errorMsg.error["title"] = "Please enter company name";
  }

  if (!address.length) {
    errorMsg.isValid = false;
    errorMsg.error["address"] = "Please enter address";
  }

  if (!isNotNullOrUndefined(data.billing_contact)) {
    errorMsg.isValid = false;
    errorMsg.error["billing_contact"] = "Billing contact is required";
  } else {
    if (!data.billing_contact?.first_name?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`billing_contact.first_name`] =
        "Please enter billing contact name";
    }
    if (!data.billing_contact?.email?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`billing_contact.email`] =
        "Please enter billing contact email";
    }
    if (data.billing_contact?.email?.length) {
      if (!emailValidation(data.billing_contact?.email)) {
        errorMsg.isValid = false;
        errorMsg.error[`billing_contact.email`] =
          "Please enter valid billing contact email";
      }
    }
    if (!data.billing_contact?.phone?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`billing_contact.phone`] =
        "Please enter billing contact phone number";
    }
  }

  if (!isNotNullOrUndefined(data.primary_contact)) {
    errorMsg.isValid = false;
    errorMsg.error["primary_contact"] = "Primary contact is required";
  } else {
    if (!data.primary_contact?.first_name?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`primary_contact.first_name`] =
        "Please enter primary contact name";
    }
    if (!data.primary_contact?.email?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`primary_contact.email`] =
        "Please enter primary contact email";
    }
    if (data.primary_contact.email?.length) {
      if (!emailValidation(data.primary_contact.email)) {
        errorMsg.isValid = false;
        errorMsg.error[`primary_contact.email`] =
          "Please enter valid primary contact email";
      }
    }
    if (!data.primary_contact?.phone?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`primary_contact.phone`] =
        "Please enter primary contact phone number";
    }
  }

  return errorMsg;
};

export const updateUserValidation = (data: UserUpdateRequest) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.first_name) {
    errorMsg.isValid = false;
    errorMsg.error["first_name"] = "Please enter first name";
  }
  if (!data.last_name) {
    errorMsg.isValid = false;
    errorMsg.error["last_name"] = "Please enter last name";
  }
  if (!data.phone_number) {
    errorMsg.isValid = false;
    errorMsg.error["phone_number"] = "Please enter phone number";
  }
  if (!data.job_title) {
    errorMsg.isValid = false;
    errorMsg.error["job_title"] = "Please select job title";
  }
  if (!data.linkedin_profile_url) {
    errorMsg.isValid = false;
    errorMsg.error["linkedin_profile_url"] = "Please enter linkdin profile url";
  } else {
    const regex =
      /^https:\/\/(www\.)?linkedin\.com(\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\/?)?$/;
    if (!regex.test(data.linkedin_profile_url)) {
      errorMsg.isValid = false;
      errorMsg.error["linkedin_profile_url"] =
        "Invalid LinkedIn profile URL. please check the format.";
    }
  }

  return errorMsg;
};
export const companyUpdateValidation = (
  data: UpdateCompanyInfoRequest,
  company_address: string
) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!company_address.length) {
    errorMsg.isValid = false;
    errorMsg.error["company_address"] = "Please enter address";
  }
  if (!data.title) {
    errorMsg.isValid = false;
    errorMsg.error["title"] = "Please enter title";
  }
  if (!data.description) {
    errorMsg.isValid = false;
    errorMsg.error["description"] = "Please enter description";
  }
  if (!data.phone) {
    errorMsg.isValid = false;
    errorMsg.error["phone"] = "Please enter phone number";
  }
  if (!data.company_size_id) {
    errorMsg.isValid = false;
    errorMsg.error["company_size_id"] = "Please select company size";
  }
  if (!data.linkedin) {
    errorMsg.isValid = false;
    errorMsg.error["linkedin"] = "Please enter linkdin profile url";
  } else {
    const regex =
      /^https:\/\/(www\.)?linkedin\.com(\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\/?)?$/;
    if (!regex.test(data.linkedin)) {
      errorMsg.isValid = false;
      errorMsg.error["linkedin"] =
        "Invalid LinkedIn profile URL. please check the format.";
    }
  }
  if (!data.website) {
    errorMsg.isValid = false;
    errorMsg.error["website"] = "Please enter website url";
  } else {
    const regex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

    if (!regex.test(data.website)) {
      errorMsg.isValid = false;
      errorMsg.error["website"] =
        "Invalid company URL. please check the format.";
    }
  }

  // const addressFields = [
  //   "country",
  //   "country_code",
  //   "locality",
  //   "address_line1",
  //   "administrative_area",
  //   "postal_code",
  // ];
  // addressFields.forEach((field) => {
  //   if (!data.company_address || !data.company_address[field]) {
  //     errorMsg.isValid = false;
  //     errorMsg.error[
  //       `company_address.${field}`
  //     ] = `Please enter ${field.replace("_", " ")}`;
  //   }
  // });

  return errorMsg;
};

export const setupEquipmentValidation = (
  data: CreateUpdateOnboardingRequest
) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.it_support_contact_name.length) {
    errorMsg.isValid = false;
    errorMsg.error["it_support_contact_name"] = "Please select name";
  }
  if (!data.it_support_contact_email.length) {
    errorMsg.isValid = false;
    errorMsg.error["it_support_contact_email"] = "Please enter email address";
  }
  if (!data.it_support_contact_phone.length) {
    errorMsg.isValid = false;
    errorMsg.error["it_support_contact_phone"] = "Please enter phone number";
  }
  if (data.it_support_contact_email.length) {
    if (!emailValidation(data.it_support_contact_email)) {
      errorMsg.isValid = false;
      errorMsg.error[`it_support_contact_email`] =
        "Please enter valid email address";
    }
  }
  return errorMsg;
};
