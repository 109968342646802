import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Container, Stack } from "@mui/material";
import {
  CalenderActiveIcon,
  TimerIcon,
} from "../../../../../../../../../assets/icons/Icons";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsText from "../../../../../../../../../components/AtsText";
import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../../theme";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { setIsOpenMakeAnOfferDialog } from "../../../../../../../../../redux/slices/makeAnOfferSlice";
import { useAppDispatch } from "../../../../../../../../../redux/store";

const OfferSent = () => {
  const { font14, font16 } = FontUtil();
  const dispatch = useAppDispatch();
  const {
    setIsCompleteRequest,
    setIsOfferDetails,
    setSelectedOfferTab,
    formattedCandidateDetails,
  } = useMakeAnOffer();
  return (
    <>
      <Stack
        sx={{
          mt: 2,
        }}
      >
        <Container maxWidth="sm">
          <Stack mt={7}>
            <Stack gap={"10px"} alignItems={"center"}>
              <Box>
                <img
                  width={45}
                  src={require("../../../../../../../../../assets/images/check.gif")}
                  alt="check"
                />
              </Box>
              <AtsText
                text={"Success! Your Offer is sent.."}
                sx={{ ...font16, fontWeight: 500, textAlign: "center" }}
              />
            </Stack>
            <Stack pt={2} gap={1}>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                sx={{ width: "100%" }}
              >
                <Stack
                  sx={{
                    borderRadius: 2,
                    bgcolor: BG_COLORS.GREEN,
                    px: 3,
                    py: 1,
                  }}
                >
                  <AtsText
                    text={`You sent an offer to ${formattedCandidateDetails.name}`}
                    sx={{
                      ...font16,
                      fontWeight: 600,
                      color: COLORS.GREEN,
                    }}
                  />
                </Stack>
              </Stack>
              <Stack
                sx={{
                  border: BORDERS.GRAY,
                  borderRadius: 2,
                  px: 3,
                  py: 4,
                }}
              >
                <Stack gap={2}>
                  <AtsText
                    text={"Offer Details"}
                    sx={{
                      ...font14,
                      fontWeight: 600,
                      color: COLORS.LIGHT_GRAY,
                    }}
                  />
                  <Stack direction={"row"}>
                    <Stack gap={2} sx={{ flex: 1 }}>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsText
                          text={formattedCandidateDetails.hourPerWeek}
                          sx={{
                            ...font14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <CalenderActiveIcon />
                        <AtsText
                          text={formattedCandidateDetails.duration}
                          sx={{
                            ...font14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ alignItems: "center" }}
                        gap={1.5}
                      >
                        <TimerIcon />
                        <AtsText
                          text={formattedCandidateDetails.rate}
                          sx={{
                            ...font14,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      gap={2}
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        borderLeft: BORDERS.GRAY,
                      }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{ justifyContent: "center" }}
                      >
                        <img
                          src={formattedCandidateDetails.image}
                          alt="Nvidia Logo"
                          style={{
                            height: "auto",
                            marginRight: "10px",
                            width: "66px",
                            borderRadius: "100%",
                          }}
                        />
                      </Stack>
                      <AtsText
                        text={formattedCandidateDetails.name}
                        sx={{
                          ...font14,
                          fontWeight: 700,
                          color: COLORS.BLACK,
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack gap={1} pt={2}>
                    <AtsText
                      text={"Track Offer"}
                      sx={{
                        ...font14,
                        fontWeight: 600,
                        color: COLORS.LIGHT_GRAY,
                      }}
                    />
                    <Stack direction={"row"} gap={1}>
                      <AtsText
                        text={
                          "Your offer has been sent. They’ll review your order and respond in a maximum of 24 hours."
                        }
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: COLORS.BLACK,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                sx={{ width: "100%" }}
                pt={2}
              >
                <AtsRoundedButton
                  text="View your Offer Details"
                  endIcon={<ArrowForwardIos />}
                  buttonWidth={"70%"}
                  onClick={() => {
                    setIsOfferDetails(true);
                    setIsCompleteRequest(false);
                    dispatch(setIsOpenMakeAnOfferDialog(false));
                    setSelectedOfferTab(2);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default OfferSent;
