import React from "react";
import AtsDataTable from "../../../components/AtsDataTable";
import { Box, Stack } from "@mui/material";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsTitleText from "../../../components/AtsTitleText";
import { FilterIcon, InvoiceListIcon } from "../../../assets/icons/Icons";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IColumnProps } from "../../../types/commonType";
import { BORDERS, COLORS } from "../../../theme";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import { DocumentText } from "iconsax-react";
import moment from "moment";
import AtsRow from "../../../components/AtsRow";
import { formattedAmount } from "../../../utils/common";
import { useInvoices } from "../../../providers/InvoicesProvider";
import { getStatusOfInvoice } from "../../Projects/modules/ProjectDetailsModules/Invoices/modules/InvoicesList";
import { InvoiceListResponse } from "../../../types/invoiceTypes";

const dummyData = [
  {
    nid: "#0000002",
    date: "1728470526",
    amount: "3245",
    status: "Not paid",
  },
  {
    nid: "#0000002",
    date: "1728470526",
    amount: "3245",
    status: "Not paid",
  },
];

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "invoice_no",
    header: "Invoice no.",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "date",
    header: "Invoice Date",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "amount",
    header: "Amount",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];
const cellStyles = {
  padding: "20px 10px",
};

const BillingInvoicesList = () => {
  const {
    invoiceId,
    setInvoiceId,
    setIsInvoiceView,
    selectedInvoiceTypeTab,
    setSelectedInvoiceTypeTab,
    setSelectedInvoice,
    loading,
    invoices,
    selectedBillingTab,
  } = useInvoices();

  const invoiceDetailsInfo = (invoice: InvoiceListResponse) => {
    setSelectedInvoice(invoice);
    setIsInvoiceView(true);
  };
  const customizeRow = (column: IColumnProps, invoice: InvoiceListResponse) => {
    switch (column.key) {
      case "invoice_no":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack py={1} direction={"row"} alignItems={"center"} gap={"12px"}>
              <Stack>
                <InvoiceListIcon />
              </Stack>
              <Stack gap={1}>
                <AtsTitleText
                  lineHeight={"0px"}
                  fs={14}
                  fw={500}
                  textColor={"#000"}
                  text={`#${invoice.invoice_no}`}
                />
              </Stack>
            </Stack>
          </td>
        );
      case "date":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={invoice.date}
              />
            </Stack>
          </td>
        );
      case "amount":
        const amount = `$ ${invoice.amount}`;
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText fs={12} fw={400} textColor={"#000"} text={amount} />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                buttonHeight={30}
                fs={12}
                fw={600}
                text={invoice.status}
                buttonVariant={getStatusOfInvoice(invoice.status)}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton
              px={0.6}
              onClick={() => invoiceDetailsInfo(invoice)}
            >
              <AtsIcon icon="icon-eye" height="16px" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = invoices.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              minHeight: "44px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "auto",
      }}
    >
      <AtsDataTable
        isNotStickyHeader
        data={invoices}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={loading}
      />
    </Box>
  );
};

export default BillingInvoicesList;
