import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  JobDetailByIdResponse,
  JobDetailsResponse,
} from "../../types/jobsTypes";

interface CompanyDetailsProps {
  img: string;
  name: string;
  size: string;
  website: string;
  tagline: string;
}

export interface JobAddressProps {
  country: string;
  administrative_area: string;
  locality: string;
  country_code: string;
  postal_code: string;
  address_line1: string;
}
export interface PostOpportunityProps {
  nid?: string;
  job_category_id: string;
  job_title: string;
  job_decription: string;
  job_type_id: string;
  job_often: string[];
  job_contract_type_id: string;
  job_period_of_time_id: string;
  job_priority_id: string;
  job_work_mode_id: string;
  job_address: JobAddressProps;
  job_what_you_ll_do: string;
  job_require_skills: string[];
  job_industry: string;
  job_hours_per_week_id: string;
  job_days_per_week_id: string;
  job_hours_per_day: string;
  job_payment_type_id: string;
  job_pay_rate_high: string;
  job_pay_rate_low: string;
  job_salary_high: string;
  job_start_date: string;
  job_end_date: string;
  job_salary_low: string;
  job_benefits_id: string[];
  job_project_id?: string;
  edu_requirements: string;
  job_years_experience: string;
  job_check: string[];
}

interface PostOpportunityState {
  activeStep: number;
  activeStep2: number;
  activeStep3: number;
  isDisable: boolean;
  isPosted: boolean;
  isEdit: boolean;
  budgetError: string;
  checkedAIGenreted: boolean;
  isQuestionRoute: boolean;
  selectedConfirm: string[];
  postOpportunity: PostOpportunityProps;
  jobDetailsById: Partial<JobDetailsResponse>;
}

export const initialJobDetails: Partial<JobDetailsResponse> = {
  nid: "",
  title: "",
  job_status: "",
  job_priority: "",
  job_address_address_line1: "",
  job_address_administrative_area: "",
  job_address_country: "",
  job_address_locality: "",
  job_address_postal_code: "",
  job_category: "",
  work_mode: "",
  published_description: "",
  required_skills: [],
  job_type: "",
  benefits: [],
  salary_high: "",
  salary_low: "",
  pay_rate_high: "",
  pay_rate_low: "",
  what_you_will_do: "",
  job_company_id: "",
  hours_per_day: "",
  hours_per_week: "",
  days_per_week: "",
};

export const initialPostOpportunity = {
  nid: "",
  job_category_id: "",
  job_title: "",
  job_decription: "",
  job_type_id: "",
  job_often: [],
  job_contract_type_id: "",
  job_period_of_time_id: "",
  job_priority_id: "",
  job_work_mode_id: "",
  job_address: {
    country: "",
    administrative_area: "",
    locality: "",
    postal_code: "",
    address_line1: "",
    country_code: "",
  },
  job_what_you_ll_do: "",
  job_require_skills: [],
  job_industry: "",
  job_hours_per_week_id: "",
  job_days_per_week_id: "",
  job_start_date: "",
  job_end_date: "",
  job_hours_per_day: "0",
  job_payment_type_id: "",
  job_pay_rate_high: "50",
  job_pay_rate_low: "1",
  job_salary_high: "100000",
  job_salary_low: "100",
  job_benefits_id: [],
  edu_requirements: "",
  job_years_experience: "",
  job_project_id: "",
  job_check: [],
};

const initialState: PostOpportunityState = {
  activeStep: 1,
  activeStep2: 0,
  activeStep3: 0,
  isDisable: false,
  isPosted: false,
  isEdit: false,
  checkedAIGenreted: false,
  isQuestionRoute: false,
  postOpportunity: initialPostOpportunity,
  jobDetailsById: initialJobDetails,
  selectedConfirm: [],
  budgetError: "",
};

const postOpportunitySlice = createSlice({
  name: "postOpportunity",
  initialState,
  reducers: {
    setActiveStep: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.activeStep = payload;
    },
    setActiveStep2: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.activeStep2 = payload;
    },
    setActiveStep3: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.activeStep3 = payload;
    },
    setPostOpportunity: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.postOpportunity = payload;
    },
    setIsDisable: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.isDisable = payload;
    },
    setIsPosted: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.isPosted = payload;
    },
    setIsQuestionRoute: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.isQuestionRoute = payload;
    },
    setJobDetailsById: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.jobDetailsById = payload;
    },
    setSelectedConfirm: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.selectedConfirm = payload;
    },
    setIsEdit: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.isEdit = payload;
    },
    setCheckedAIGenreted: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.checkedAIGenreted = payload;
    },
    setBudgetError: (state: PostOpportunityState, action) => {
      const { payload } = action;
      state.budgetError = payload;
    },
    setInitialPostOpportunityState: () => initialState,
  },
});

export const {
  setActiveStep,
  setActiveStep2,
  setActiveStep3,
  setPostOpportunity,
  setJobDetailsById,
  setIsDisable,
  setIsPosted,
  setIsQuestionRoute,
  setSelectedConfirm,
  setIsEdit,
  setCheckedAIGenreted,
  setInitialPostOpportunityState,
  setBudgetError,
} = postOpportunitySlice.actions;
export const getPostOpportunityState = (state: RootState) =>
  state.postOpportunity;
export default postOpportunitySlice.reducer;
