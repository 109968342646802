import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import AtsIcon from "../../../../../../../components/AtsIcon";
import { useDebounce } from "../../../../../../../hooks/useDebounce";
import { TasksListResponse } from "../../../../../../../types/projectTypes";
import { ProjectService } from "../../../../../../../Api/projectServices";
import { useParams } from "react-router-dom";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import { IColumnProps } from "../../../../../../../types/commonType";
import moment from "moment";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import { FilterIcon } from "../../../../../../../assets/icons/Icons";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const LEAD_COLUMN: IColumnProps[] = [
  // {
  //   key: "uid",
  //   header: "Invoice no.",
  //   width: "300px",
  //   minWidth: "300px",
  // },
  {
    key: "title",
    header: "Task Name",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "owner_emails",
    header: "Assignees",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "created",
    header: "Created on",
    width: "auto",
    minWidth: "100px",
  },
  {
    key: "status",
    header: "Status",
    width: "auto",
    minWidth: "100px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const AssignedTasksTab = () => {
  const { id } = useParams();
  const [inputValue, setInputValue] = useState<string>("");
  const [tasks, setTasks] = useState<TasksListResponse[]>([]);

  const [page, setPage] = useState<number>(0);
  const [totlaItem, setTotalItem] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearch = useDebounce(inputValue);

  const fetchTaskList = async () => {
    setLoading(true);
    try {
      const taskListBody = {
        ...(inputValue.length && {
          title: inputValue,
        }),
      };
      const response = await ProjectService.taskListByProjectId(
        id,
        taskListBody
      );
      setTasks(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchTaskList();
  }, [debouncedSearch]);

  const customizeRow = (
    column: IColumnProps,
    task: TasksListResponse,
    index: number
  ) => {
    switch (column.key) {
      case "title":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={"#000"}
                text={task.title}
              />
            </Stack>
          </td>
        );
      case "owner_emails":
        const owner_emails = task.owner_emails.length
          ? task.owner_emails.includes(",")
            ? task.owner_emails.split(", ")
            : [task.owner_emails]
          : [];
        return (
          <td style={{ ...cellStyles, position: "relative" }} key={column.key}>
            <Stack
              py={1}
              direction={"row"}
              alignItems={"center"}
              gap={"12px"}
              sx={{
                height: 11,
                bgcolor: BG_COLORS.PRIMARY,
                px: "16px",
                borderRadius: "38px",
                width: "fit-content",
                position: "relative",
                cursor: "pointer",
              }}
            >
              {owner_emails.length === 0 ? (
                <AtsTitleText text={"Anyone"} textColor={COLORS.PRIMARY} />
              ) : (
                <Stack direction={"row"} alignItems={"center"} gap={"3px"}>
                  {owner_emails.slice(0, 2).map((item, index) => {
                    const name = owner_emails.length === 1 ? item : `${item},`;
                    return (
                      <AtsTitleText
                        key={index}
                        text={name}
                        textColor={COLORS.PRIMARY}
                      />
                    );
                  })}
                  {owner_emails.length > 2 && (
                    <AtsTitleText
                      text={`+${owner_emails.length - 2}`}
                      textColor={COLORS.PRIMARY}
                    />
                  )}
                </Stack>
              )}
            </Stack>
          </td>
        );
      case "created":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={moment.unix(Number(task.created)).format("MMM DD, YYYY")}
              />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsRoundedButton
                text={task.status}
                fs={12}
                fw={600}
                buttonHeight={25}
                buttonWidth={90}
                buttonVariant={
                  task.status === "Active"
                    ? "greenContained"
                    : "dangerContained"
                }
              />
            </Stack>
          </td>
        );

      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = tasks.map(
      (value: TasksListResponse, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr
              style={{
                borderBottom: BORDERS.GRAY,
                height: "68px",
                margin: "0px 8px",
                paddingTop: "10px",
                paddingBottom: "10px",
                cursor: "pointer",
                backgroundColor: "white",
              }}
            >
              {LEAD_COLUMN.map((val: IColumnProps) =>
                customizeRow(val, value, index)
              )}
            </tr>
          </React.Fragment>
        );
      }
    );
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <Stack gap={"19px"}>
        <AtsTitleText text={`Assigned Tasks`} fs={18} fw={600} />
        <Stack
          direction={"row"}
          alignItems={"center"}
          minHeight={30}
          width={232}
          sx={{
            px: "16px",
            border: BORDERS.GRAY,
            borderRadius: "38px",
          }}
        >
          <input
            type="text"
            className="customInput"
            placeholder="Search task name"
            style={{
              width: "100%",
            }}
            value={inputValue}
            onChange={(e) => {
              const value = e.target.value;
              setInputValue(value);
            }}
          />
          <AtsIcon icon="icon-search-normal-1" height="16px" />
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 155px)",
          }}
        >
          <AtsDataTable
            // isTopBorder
            data={tasks}
            theadComponent={headerComponent}
            rowComponent={renderRowComponent}
            loading={loading}
          />
        </Box>
      </Stack>
    </>
  );
};

export default AssignedTasksTab;
