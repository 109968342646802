export enum ProjectListStatusEnum {
  IN_PROGRESS = "in_progress",
  DRAFT = "draft",
  COMPLETED = "completed",
  CANCELED = "canceled",
  ARCHIVED = "archived",
}
export enum ProjectContractorsStatusEnum {
  ACTIVE = "active",
  INACTIVE = "in_active",
}
export enum ProjectDetailsTabsEnum {
  COMPANY = "Company",
  POLICY_DOCUMENTS = "Policy Documents",
  HEALTH_SAFETY = "Health and Safety",
  PROJECT_DOCUMENTS = "Project Documents",
  TEAM_MEMBER = "Team Members",
}
export enum ProjectTimesheetTabsEnum {
  Pending = "pending",
  Approved = "approved",
  Disputed = "disputed",
}
export enum DisputedStatusEnum {
  UnderReview = "Under review",
  Disputed = "Disputed",
  Resolved = "Resolved",
}
