import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AtsTitleText from "../../../components/AtsTitleText";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsBackLink from "../../../components/AtsBackLink";
import {
  useCreateAccount,
  UsedPlatformEnums,
  usedPlatforms,
} from "../../../providers/CreateAccountProvider";
import { BORDERS, COLORS } from "../../../theme";
import AtsRow from "../../../components/AtsRow";
import AtsModal from "../../../components/AtsModal";
import { Close } from "@mui/icons-material";
import ProfileModal from "../components/ProfileModal";
import AtsAddressInput from "../../../components/AtsAddressInput";
import AboutYou from "../components/AboutYou";
import Interestes from "../components/Interestes";

const YourProfile = () => {
  const { setIsShowYourProfile, setSelectedPlatform, selectedPlatform } =
    useCreateAccount();
  const [open, setOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggelOpenClose = () => {
    setOpen(!open);
  };

  const getComponent = () => {
    if (selectedPlatform?.state === UsedPlatformEnums.live) {
      return (
        <Stack>
          <AtsAddressInput
            onSearch={() => {}}
            search=""
            handleAddressChange={() => {}}
          />
        </Stack>
      );
    } else if (selectedPlatform?.state === UsedPlatformEnums.school) {
      return (
        <AtsRow
          sx={{
            px: "10px",
            py: "14px",
            borderRadius: "8px",
            border: BORDERS.GRAY,
          }}
        >
          <>
            <AtsTitleText
              text={"Where I went to school:"}
              fs={14}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
            />
            <input type="text" className="customInput" />
          </>
        </AtsRow>
      );
    }
  };
  return (
    <>
      <Stack gap={"20px"}>
        <AtsRowBetween>
          <>
            <AtsTitleText
              text={"Your profile"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsBackLink
              text="Back"
              onClick={() => {
                setIsShowYourProfile(false);
              }}
            />
          </>
        </AtsRowBetween>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: COLORS.DARK_GRAY,
          }}
        >
          The information you share will be used across the platform to help
          other people get to know you.{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Learn more
          </span>
        </Typography>
      </Stack>
      <Stack mt={"30px"}>
        <Grid container columnSpacing={"30px"} rowSpacing={"10px"}>
          {usedPlatforms.map((platform, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <AtsRow
                gap={"12px"}
                sx={{
                  px: "10px",
                  py: "20px",
                  borderBottom: BORDERS.GRAY,
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggelOpenClose();

                  setSelectedPlatform(platform);
                }}
              >
                <>
                  <platform.icon size={20} color={COLORS.LIGHT_GRAY} />
                  <AtsTitleText
                    text={platform.text}
                    fs={14}
                    fw={500}
                    textColor={COLORS.DARK_GRAY}
                  />
                </>
              </AtsRow>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack mt={"50px"} gap={"50px"}>
        <AboutYou />
      </Stack>
      <Stack mt={"50px"} gap={"50px"}>
        <Interestes />
      </Stack>
      <ProfileModal
        open={open}
        header={selectedPlatform?.text ?? ""}
        subText={selectedPlatform?.subText ?? ""}
        toggelOpenClose={toggelOpenClose}
        renderConponents={getComponent()}
        onSave={() => {}}
      />
    </>
  );
};

export default YourProfile;
