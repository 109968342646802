import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { Views } from "react-big-calendar";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsTitleText from "../../../components/AtsTitleText";
import {
  Keys,
  useCalendarAvailability,
} from "../../../providers/CalendarAvailabilityProvider";
import { BORDERS, COLORS } from "../../../theme";
import JobsSwitch from "../../Dashboard/PostedJobs/JobsSwitch";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsCustomButton from "../../../components/AtsCustomButton";
import AtsRow from "../../../components/AtsRow";
import AtsIcon from "../../../components/AtsIcon";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";

const PROJECTS = [
  {
    title: "Weekly",
    type: Views.WEEK,
  },
  {
    title: "Monthly",
    type: Views.MONTH,
  },
];

const CalenderDateFilterSection = () => {
  const navigate = useNavigate();
  const {
    date,
    calendarView,
    isAvailability,
    setDate,
    setCalendarView,
    setIsAvailability,
  } = useCalendarAvailability();

  const onPrevClick = () => {
    if (calendarView === Views.WEEK) {
      setDate(moment(date).subtract(1, "w").toDate());
    } else {
      setDate(moment(date).subtract(1, "M").toDate());
    }
  };

  const onNextClick = () => {
    if (calendarView === Views.WEEK) {
      setDate(moment(date).add(1, "w").toDate());
    } else {
      setDate(moment(date).add(1, "M").toDate());
    }
  };
  const onTodayClick = useCallback(() => {
    setDate(moment().toDate());
  }, []);
  const dateText = useMemo(() => {
    if (calendarView === Views.WEEK) {
      const from = moment(date)?.startOf("week");
      const to = moment(date)?.endOf("week");
      return `${from.format("MMMM DD")} to ${to.format("MMMM DD")}`;
    }
    if (calendarView === Views.MONTH) {
      return moment(date).format("MMMM YYYY");
    }
  }, [calendarView, date]);
  return (
    <>
      <AtsRowBetween>
        <>
          <AtsTitleText
            text="Calendar"
            fs={24}
            fw={600}
            textColor={COLORS.BLACK}
          />
          <AtsCustomButton
            px={0}
            py={0}
            onClick={() => navigate(ATS_ROUTES.AVAILABILITY)}
          >
            <AtsRow gap={"8px"}>
              <>
                <AtsIcon icon="icon-setting-2" height="16px" color="#000" />
                <AtsTitleText
                  text="Availability"
                  fs={14}
                  fw={400}
                  textColor={"#000"}
                />
              </>
            </AtsRow>
          </AtsCustomButton>
        </>
      </AtsRowBetween>
      {!isAvailability && (
        <Stack
          sx={{ pt: "22px", pb: "19px" }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
            <AtsTitleText
              text={dateText}
              fs={20}
              fw={600}
              textColor={COLORS.BLACK}
            />
            <Stack direction={"row"} gap={"10px"}>
              <IconButton
                sx={{
                  border: BORDERS.GRAY,
                  p: 1,
                }}
                onClick={onPrevClick}
              >
                <ArrowBackIosNew sx={{ fontSize: 12, color: COLORS.PRIMARY }} />
              </IconButton>
              <IconButton
                sx={{
                  border: BORDERS.GRAY,
                  p: 1,
                }}
                onClick={onNextClick}
              >
                <ArrowForwardIos sx={{ fontSize: 12, color: COLORS.PRIMARY }} />
              </IconButton>
            </Stack>
            <AtsRoundedButton
              text="Today"
              buttonVariant="whiteContained"
              buttonHeight={32}
              onClick={onTodayClick}
            />
          </Stack>
          <JobsSwitch
            width="101px"
            jobs={PROJECTS}
            active={calendarView}
            onChange={(value) => {
              setCalendarView(value as (typeof Views)[Keys]);
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default CalenderDateFilterSection;
