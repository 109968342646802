import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MakeAnOfferServices } from "../../../../../../../../../Api/makeAnOfferServices";
import AtsAntSwitch from "../../../../../../../../../components/AtsAntSwitch";
import AtsCheckbox from "../../../../../../../../../components/AtsCheckbox";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsTextInput from "../../../../../../../../../components/AtsTextInput";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { JobTypeEnum } from "../../../../../../../../../enums/jobsEnums";
import { useMakeAnOffer } from "../../../../../../../../../providers/MakeAnOfferProvider";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import { TermContentResponse } from "../../../../../../../../../types/makeAnOfferTypes";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { isNotNullOrUndefined } from "../../../../../../../../../utils/stringUtils";
import JobDetailsForMakeAnOffer from "./JobDetailsForMakeAnOffer";

const JobDetailsAndTerms = () => {
  const { font16 } = FontUtil();
  const [phone, setPhone] = useState("");
  const { formattedCandidateDetails } = useMakeAnOffer();
  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<TermContentResponse[]>([]);

  const getContentTerms = async () => {
    setLoading(true);
    try {
      const response = await MakeAnOfferServices.getTermContent();
      setTerms(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getContentTerms();
  }, []);

  return (
    <>
      <Stack
        sx={{
          // maxWidth: isLargeScreen ? "80%" : isDesktopScreen ? "85%" : "100%",
          height: "93%",
          overflow: "auto",
          pr: 2,
        }}
      >
        <Stack
          sx={{
            px: "30px",
            py: "30px",
            mr: "16px",
            border: BORDERS.GRAY,
            borderRadius: "12px !important",
            position: "relative",
            mb: "30px",
          }}
          gap={"30px"}
        >
          <AtsRow flexWrap={"wrap"}>
            <>
              <RenderTextWrap
                text={
                  formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE
                    ? "Annual rate"
                    : "Offer Rate"
                }
                value={
                  formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE
                    ? formattedCandidateDetails.annualrate
                    : `${formattedCandidateDetails.rate}/hr`
                }
              />
              <RenderTextWrap
                text={
                  formattedCandidateDetails.job_type === JobTypeEnum.DIRECT_HIRE
                    ? "Start date"
                    : "Duration"
                }
                value={formattedCandidateDetails.duration}
              />
              <RenderTextWrap
                text="Hours per week"
                value={formattedCandidateDetails.hourPerWeek}
              />
              <RenderTextWrap
                text="Employment type"
                value={
                  isNotNullOrUndefined(formattedCandidateDetails?.hourPerWeek)
                    ? parseFloat(
                        formattedCandidateDetails?.hourPerWeek.split(" ")[0]
                      ) >= 30
                      ? "Full-time"
                      : "Part-time"
                    : ""
                }
              />
            </>
          </AtsRow>
        </Stack>
        <Stack
          sx={{
            px: "30px",
            py: "30px",
            mr: "16px",
            border: BORDERS.GRAY,
            borderRadius: "12px !important",
            position: "relative",
          }}
          gap={"20px"}
        >
          <AtsTitleText text={"Track updates"} fs={16} fw={600} />
          <AtsRow gap={"50px"}>
            <>
              <Stack gap={1}>
                <AtsRow gap={"5px"}>
                  <>
                    <AtsAntSwitch />
                    <AtsTitleText
                      text={"Your phone number"}
                      fs={12}
                      fw={600}
                      textColor={COLORS.BLACK}
                    />
                  </>
                </AtsRow>

                <PhoneInput
                  country={"us"}
                  enableSearch
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />

                <AtsTitleText
                  text={"Confirm your mobile number to receive updates."}
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
              <Stack gap={1} width={350}>
                <AtsRow gap={"5px"}>
                  <>
                    <AtsAntSwitch />
                    <AtsTitleText
                      text={"Your email"}
                      fs={12}
                      fw={600}
                      textColor={COLORS.BLACK}
                    />
                  </>
                </AtsRow>

                <AtsTextInput
                  value={formattedCandidateDetails.email}
                  // onChange={(phone) => setPhone(phone)}
                />
                <AtsTitleText
                  text={"Confirm your email to receive updates."}
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
            </>
          </AtsRow>
          <AtsRow gap={"10px"}>
            <>
              <AtsCheckbox p={0} />
              <AtsTitleText
                text={"Receive offer tracking updates"}
                fs={12}
                fw={500}
                textColor="#000"
              />
            </>
          </AtsRow>
          <Box
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
            }}
          >
            <AtsTitleText text={"Skip"} fs={12} fw={400} textColor="#000" />
          </Box>
        </Stack>
        <Stack
          mt={"30px"}
          gap={"32px"}
          sx={{
            height: "calc(100% - 140px)",

            pr: 2,
            pb: "10px",
          }}
        >
          <Accordion
            sx={{
              boxShadow: "none",
              border: BORDERS.GRAY,
              borderRadius: "12px !important",
              "&.Mui-expanded": {
                margin: "0px !important",
              },
              "::before": {
                height: "0px !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                ...font16,
                color: COLORS.BLACK,
                fontWeight: 600,
                px: "30px",
              }}
            >
              Job details
            </AccordionSummary>
            <AccordionDetails
              sx={{
                px: "30px",
              }}
            >
              <JobDetailsForMakeAnOffer />
            </AccordionDetails>
          </Accordion>
          {loading ? (
            <Skeleton variant="rectangular" height={30} width={"100%"} />
          ) : (
            terms.length > 0 &&
            terms.map((term) => (
              <Accordion
                key={term?.title}
                sx={{
                  boxShadow: "none",
                  border: BORDERS.GRAY,
                  borderRadius: "12px !important",
                  "&.Mui-expanded": {
                    margin: "0px !important",
                  },
                  "::before": {
                    height: "0px !important",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    ...font16,
                    color: COLORS.BLACK,
                    fontWeight: 600,
                    px: "30px",
                  }}
                >
                  {term?.title}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    px: "30px",
                  }}
                >
                  <div
                    className="jobDetails"
                    dangerouslySetInnerHTML={{ __html: term?.body }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default JobDetailsAndTerms;

const RenderTextWrap = ({ text, value }: { text: string; value: string }) => {
  return (
    <Stack
      gap={"8px"}
      sx={{
        flexGrow: 1,
      }}
    >
      <AtsTitleText
        text={text}
        fs={12}
        fw={500}
        textColor={COLORS.LIGHT_GRAY}
      />
      <AtsTitleText
        text={value}
        fs={14}
        fw={500}
        textColor={COLORS.DARK_GRAY}
      />
    </Stack>
  );
};
