import { Box, Collapse, Stack } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import { useState } from "react";
import AtsRoundedSearchInput from "../../../components/AtsRoundedSearchInput";
import AtsRowBetween from "../../../components/AtsRowBetween";
import AtsTitleText from "../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../theme";
import { useClickOutSide } from "../../../utils/common";

const StatementWorkFilter = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showProject, setShowProject] = useState<boolean>(false);

  const domNode = useClickOutSide(() => {
    setShow(false);
  });
  const domNodeProject = useClickOutSide(() => {
    setShowProject(false);
  });
  return (
    <>
      <AtsRowBetween>
        <>
          <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
            <AtsRoundedSearchInput
              width={312}
              placeholder="Search by contractor name, contractor no."
            />
            <Box
              ref={domNodeProject}
              sx={{
                position: "relative",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                sx={{
                  cursor: "pointer",
                  px: "14px",
                  py: "6px",
                  border: BORDERS.GRAY,
                  borderRadius: "38px",
                }}
                onClick={() => setShowProject(!showProject)}
              >
                <Stack direction={"row"} gap={"6px"} alignItems={"center"}>
                  <AtsTitleText
                    text={"Select Project"}
                    textColor={COLORS.DARK_GRAY}
                    fs={14}
                    fw={500}
                  />
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: COLORS.PRIMARY,
                    }}
                  >
                    <AtsTitleText
                      text={"2"}
                      textColor={"white"}
                      fs={10}
                      fw={600}
                    />
                  </Box>
                </Stack>
                <Stack>
                  <ArrowDown2 size={16} />
                </Stack>
              </Stack>
              <Box
                sx={{
                  position: "absolute",
                  bgcolor: "white",
                  right: "0px",
                  // top: "50px",
                  zIndex: 9999,
                  width: "-webkit-fill-available",
                }}
              >
                <Collapse
                  in={showProject}
                  sx={{
                    border: BORDERS.GRAY,

                    borderRadius: "5px",
                  }}
                >
                  <Stack
                    gap={"7px"}
                    sx={{
                      p: "10px",
                    }}
                  >
                    {["2", "3"].map((value, index) => (
                      <Stack
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <AtsTitleText
                          key={index}
                          text={value}
                          fs={12}
                          fw={500}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Collapse>
              </Box>
            </Box>
          </Stack>
          <Box
            ref={domNode}
            sx={{
              position: "relative",
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                cursor: "pointer",
                px: "14px",
                py: "6px",
              }}
              onClick={() => setShow(!show)}
            >
              <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                <AtsTitleText
                  text={"Sort by:"}
                  textColor={COLORS.DARK_GRAY}
                  fs={14}
                  fw={500}
                />
                <AtsTitleText
                  text={"Newest"}
                  textColor={COLORS.DARK_GRAY}
                  fs={14}
                  fw={400}
                />
              </Stack>
              <Stack>
                <ArrowDown2 size={16} />
              </Stack>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bgcolor: "white",
                right: "0px",
                // top: "50px",
                zIndex: 9999,
                width: "-webkit-fill-available",
              }}
            >
              <Collapse
                in={show}
                sx={{
                  border: BORDERS.GRAY,

                  borderRadius: "5px",
                }}
              >
                <Stack
                  gap={"7px"}
                  sx={{
                    p: "10px",
                  }}
                >
                  {["Newest", "Oldest"].map((value, index) => (
                    <Stack
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <AtsTitleText key={index} text={value} fs={12} fw={500} />
                    </Stack>
                  ))}
                </Stack>
              </Collapse>
            </Box>
          </Box>
        </>
      </AtsRowBetween>
    </>
  );
};

export default StatementWorkFilter;
