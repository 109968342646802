import React, { useCallback, useState } from "react";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { Box, Stack, Typography } from "@mui/material";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import { BORDERS, COLORS } from "../../../../../../../../../theme";
import AtsRow from "../../../../../../../../../components/AtsRow";
import { AnimatePresence, motion } from "framer-motion";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { ContractorTimesheetProps } from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import { useTimesheet } from "../../../../../../../../../providers/TimesheetProvider";
import { TimelogEntriesResponse } from "../../../../../../../../../types/timesheetTypes";
import { formattedAmount } from "../../../../../../../../../utils/common";
import AtsTextInput from "../../../../../../../../../components/AtsTextInput";

const slideVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: (direction) => ({
    x: direction > 0 ? -1000 : 1000,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  }),
};

interface IProps {
  error: string;
}

const DisputedEntriesCard = ({ error }: IProps) => {
  const { font14, font12 } = FontUtil();
  const [selectedTimesheet, setSelectedTimesheet] = useState<number>(0);
  const [direction, setDirection] = React.useState(0);
  const {
    selectedTimelogOfContractorDetails,
    setSelectedTimelogOfContractorDetails,
  } = useTimesheet();

  const date = (date) => {
    const formatted = new Date(Number(date) * 1000);
    const formattedDate = formatted.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const getTimeFormat = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const getTime = (timelog: TimelogEntriesResponse) => {
    const start = getTimeFormat(new Date(Number(timelog.start_time) * 1000));
    const end = getTimeFormat(new Date(Number(timelog.end_time) * 1000));
    return `${start} to ${end}`;
  };

  const disputeData = useCallback(() => {
    if (!selectedTimelogOfContractorDetails[selectedTimesheet]) return [];
    const tim_log = selectedTimelogOfContractorDetails[
      selectedTimesheet
    ]?.time_logs?.map((log) => {
      return {
        date: date(log.date),
        task: log?.title,
        description: log?.work_description,
        time: getTime(log),
      };
    });
    const data = [
      {
        title: "Date",
        value: date(selectedTimelogOfContractorDetails[selectedTimesheet].date),
      },
      {
        title: "Task",
        value: selectedTimelogOfContractorDetails[selectedTimesheet].title,
      },
      {
        title: "Description of work",
        value:
          selectedTimelogOfContractorDetails[selectedTimesheet]
            .work_description,
      },
      {
        title: "Start time - end time",
        value: getTime(selectedTimelogOfContractorDetails[selectedTimesheet]),
      },
    ];

    return tim_log;
  }, [selectedTimelogOfContractorDetails, selectedTimesheet]);

  const prevItem = () => {
    if (selectedTimesheet !== 0) {
      setSelectedTimesheet(selectedTimesheet - 1);
    }
  };
  const lastIndex = selectedTimelogOfContractorDetails.length - 1;
  const nextItem = () => {
    if (lastIndex !== selectedTimesheet) {
      setSelectedTimesheet(selectedTimesheet + 1);
    }
  };

  const handleNext = () => {
    setDirection(1);
    nextItem();
  };

  const handlePrev = () => {
    setDirection(-1);
    prevItem();
  };

  const handleChange = (
    key: keyof TimelogEntriesResponse,
    value: string,
    index: number
  ) => {
    const preSelectedSheet = [...selectedTimelogOfContractorDetails];
    const changeableObj = { ...selectedTimelogOfContractorDetails[index] };
    const updateObj = { ...changeableObj, [key]: value };
    preSelectedSheet.splice(index, 1, updateObj);
    setSelectedTimelogOfContractorDetails(preSelectedSheet);
  };

  return (
    <>
      <Box
        sx={{
          mx: "30px",

          position: "relative",
        }}
      >
        {selectedTimelogOfContractorDetails.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: -30,
              cursor: "pointer",
              ...(selectedTimesheet === 0 && {
                opacity: "0.5",
                cursor: "default",
              }),
            }}
          >
            <Box px={0} py={0} onClick={handlePrev}>
              <AtsIcon icon="icon-arrow-left-2" height="25px" fs={25} />
            </Box>
          </Box>
        )}
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={selectedTimesheet}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            <Box
              sx={{
                px: "20px",
                py: "16px",
                bgcolor: "#f5f6f8",
                borderRadius: "6px",
              }}
            >
              <Stack
                textAlign={"center"}
                sx={{
                  pb: "13px",
                }}
              >
                <Typography
                  sx={{
                    ...font12,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    {" "}
                    {selectedTimesheet + 1}{" "}
                  </span>
                  of{" "}
                  <span style={{ fontWeight: 600 }}>
                    {selectedTimelogOfContractorDetails.length}
                  </span>{" "}
                  dispute entries
                </Typography>
              </Stack>

              <Stack
                className="scrollHide"
                sx={{
                  height: 127,
                  overflow: "auto",
                }}
              >
                {disputeData().map((data, index) => (
                  <Stack
                    key={index}
                    gap={"14px"}
                    sx={{
                      pt: "14px",
                      pb: "13px",
                      borderTop: BORDERS.GRAY,
                      borderBottom: BORDERS.GRAY,
                    }}
                  >
                    <TextWrapper title={"Date"} value={data.date} />
                    <TextWrapper title={"Task"} value={data.task} />
                    <TextWrapper
                      title={"Description of work"}
                      value={data.description}
                    />
                    <TextWrapper
                      title={"Start time - end time"}
                      value={data.time}
                    />
                  </Stack>
                ))}
              </Stack>
              <AtsRowBetween
                sx={{
                  pt: "14px",
                  pb: "13px",
                  borderBottom: BORDERS.GRAY,
                }}
              >
                <>
                  <AtsTitleText
                    text={"Time"}
                    fs={12}
                    fw={600}
                    textColor={"#000"}
                  />
                  <AtsTitleText
                    text={`${formattedAmount(
                      selectedTimelogOfContractorDetails[selectedTimesheet]
                        ?.working_hours
                    )} hrs`}
                    fs={12}
                    fw={600}
                    textColor={"#000"}
                  />
                </>
              </AtsRowBetween>
              <AtsRowBetween
                sx={{
                  pt: "14px",
                }}
              >
                <>
                  <Typography
                    sx={{
                      ...font14,
                      fontWeight: 600,
                      color: COLORS.BLACK,
                    }}
                  >
                    Enter the time you want to dispute in this entry
                    <span style={{ color: "#e63030" }}>*</span>
                  </Typography>
                  <AtsRow gap={"6px"}>
                    <>
                      <AtsRowBetween
                        gap={"6px"}
                        sx={{
                          border: BORDERS.GRAY,
                          borderRadius: "6px",
                          height: 38,
                          width: 54,
                          px: "10px",
                          bgcolor: "white",
                        }}
                      >
                        <>
                          <Box width={"50%"}>
                            <input
                              type="number"
                              className="customInput"
                              value={
                                selectedTimelogOfContractorDetails[
                                  selectedTimesheet
                                ].disputed_hrs
                              }
                              style={{
                                width: "100%",
                                backgroundColor: "transparent",
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                handleChange(
                                  "disputed_hrs",
                                  value,
                                  selectedTimesheet
                                );
                              }}
                            />
                          </Box>
                          <AtsTitleText
                            text={"hrs"}
                            fs={14}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                          />
                        </>
                      </AtsRowBetween>
                      <AtsRowBetween
                        gap={"6px"}
                        sx={{
                          border: BORDERS.GRAY,
                          borderRadius: "6px",
                          height: 38,
                          width: 54,
                          px: "10px",
                          bgcolor: "white",
                        }}
                      >
                        <>
                          <Box width={"50%"}>
                            <input
                              type="number"
                              className="customInput"
                              value={
                                selectedTimelogOfContractorDetails[
                                  selectedTimesheet
                                ].disputed_mins
                              }
                              style={{
                                width: "100%",
                                backgroundColor: "transparent",
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                handleChange(
                                  "disputed_mins",
                                  value,
                                  selectedTimesheet
                                );
                              }}
                            />
                          </Box>
                          <AtsTitleText
                            text={"mins"}
                            fs={14}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                          />
                        </>
                      </AtsRowBetween>
                    </>
                  </AtsRow>
                </>
              </AtsRowBetween>
              <Stack gap={"4px"} pt={"10px"}>
                <AtsTitleText
                  text={"Description"}
                  fs={12}
                  fw={500}
                  isMandatory
                />
                <AtsTextInput
                  value={
                    selectedTimelogOfContractorDetails[selectedTimesheet]
                      .dispute_description
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange(
                      "dispute_description",
                      value,
                      selectedTimesheet
                    );
                  }}
                />
              </Stack>
            </Box>
          </motion.div>
        </AnimatePresence>

        {selectedTimelogOfContractorDetails.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: -30,
              cursor: "pointer",
              ...(lastIndex === selectedTimesheet && {
                opacity: "0.5",
                cursor: "default",
              }),
            }}
          >
            <Box px={0} py={0} onClick={handleNext}>
              <AtsIcon icon="icon-arrow-right-3" height="25px" fs={25} />
            </Box>
          </Box>
        )}
        {error.length > 0 && (
          <AtsRow
            sx={{
              position: "absolute",
              bottom: 5,
              right: 25,
              justifyContent: "end",
            }}
          >
            <AtsTitleText text={error} fs={12} fw={500} textColor={"#e63030"} />
          </AtsRow>
        )}
      </Box>
    </>
  );
};

export default DisputedEntriesCard;

const TextWrapper = ({ title, value }) => {
  return (
    <AtsRow>
      <>
        <Stack width={"50%"}>
          <AtsTitleText
            text={title}
            fs={12}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
        <Stack width={"50%"} textAlign={"end"}>
          <AtsTitleText
            text={value}
            fs={12}
            fw={400}
            textColor={COLORS.DARK_GRAY}
          />
        </Stack>
      </>
    </AtsRow>
  );
};
