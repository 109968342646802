import { Box } from "@mui/material";
import AtsIcon from "../../../components/AtsIcon";
import AtsRoundedButton from "../../../components/AtsRoundedButton";
import AtsRowBetween from "../../../components/AtsRowBetween";
import { useCreateAccount } from "../../../providers/CreateAccountProvider";
import { COLORS } from "../../../theme";
import AccountDetails from "../components/AccountDetails";
import CompanyDetails from "../components/CompanyDetails";
import Submit from "../components/Submit";
import AtsRow from "../../../components/AtsRow";
import { InfoIcon } from "../../../pages/Jobs/assets/Icons";
import { useAppSelector } from "../../../redux/store";
import { getAuthState } from "../../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { ATS_ROUTES } from "../../../router/routes";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

const CreateAccountWrapper = () => {
  const {
    activeStep,
    setActiveStep,
    updateUserDetails,
    updateUserLoading,
    updateCompanyDetails,
    companyUpdateLoading,
  } = useCreateAccount();
  const submit = async () => {
    await updateCompanyDetails();
    // setActiveStep(2);
  };
  return (
    <>
      <Box
        sx={{
          maxWidth: "776px",
          mx: "auto",
          height: "100%",
        }}
      >
        <Box
          className="scrollHide"
          sx={{
            height: "calc(100% - 80px)",
            overflow: "auto",
          }}
        >
          <Box
            my={"30px"}
            sx={{
              px: "10px",
              height: "90%",
            }}
          >
            <AtsRow justifyContent={"end"}>
              <AtsRoundedButton
                text="Help"
                buttonVariant="whiteContained"
                buttonHeight={33}
                buttonWidth={70}
                startIcon={<InfoIcon />}
              />
            </AtsRow>
            {activeStep === 1 ? (
              <CompanyDetails />
            ) : activeStep === 2 ? (
              <Submit />
            ) : (
              <AccountDetails />
            )}
          </Box>
        </Box>
        <AtsRowBetween>
          <>
            {activeStep !== 2 && activeStep === 1 && (
              <AtsRoundedButton
                text="Back"
                startIcon={<ArrowLeft2 size="16" color={COLORS.PRIMARY} />}
                buttonVariant="outline"
                buttonHeight={34}
                buttonWidth={79}
                onClick={() => setActiveStep(0)}
              />
            )}
            {activeStep !== 2 &&
              (activeStep === 1 ? (
                <AtsRoundedButton
                  text="Submit"
                  endIcon={<ArrowRight2 size="16" color={"white"} />}
                  buttonHeight={34}
                  buttonWidth={179}
                  disabled={companyUpdateLoading}
                  loading={companyUpdateLoading}
                  onClick={submit}
                />
              ) : (
                <AtsRow justifyContent={"end"} width={"100%"}>
                  <AtsRoundedButton
                    text=" Save and continue"
                    isInitial
                    endIcon={<ArrowRight2 size="16" color={"white"} />}
                    buttonHeight={34}
                    buttonWidth={updateUserLoading ? 200 : 179}
                    disabled={updateUserLoading}
                    loading={updateUserLoading}
                    onClick={async () => {
                      await updateUserDetails();
                    }}
                  />
                </AtsRow>
              ))}
            {activeStep === 2 && (
              <AtsRow justifyContent={"end"} width={"100%"}>
                <AtsRoundedButton
                  text="Go to dashboard"
                  isInitial
                  endIcon={
                    <AtsIcon
                      icon="icon-arrow-right-3"
                      height="28px"
                      fs={18}
                      color={"white"}
                    />
                  }
                  buttonHeight={34}
                  buttonWidth={179}
                  onClick={() => {
                    window.location.href = ATS_ROUTES.DASHBOARD;
                  }}
                />
              </AtsRow>
            )}
          </>
        </AtsRowBetween>
      </Box>
    </>
  );
};

export default CreateAccountWrapper;
